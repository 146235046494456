import { FocusEventHandler } from "react";

import { LoginType } from ".";
import LoginOption from "./LoginOption";

import Input from "../../components/Input";
import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export interface MemberLoginOptionProps {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function MemberLoginOption(props: MemberLoginOptionProps) {
  const { handleBlur, setFieldValue } = useFormikSchemaContext();
  const { appConfiguration } = props;

  if(!appConfiguration?.allow_member_login) {
    return "";
  }

  const onBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    if(e.target.value) {
      await setFieldValue("loginType", LoginType.Member);
    }

    handleBlur(e);
  };

  return (
    <LoginOption
      appConfiguration={appConfiguration}
      defaultTitle={"Members"}
      loginType={LoginType.Member}
      submitButtonDefaultText="Sign In"
    >
      <Input
        placeholder={getLocalizedText(appConfiguration, "membershipNumberLabel", "Membership Number")}
        name="membershipNumber"
        onBlur={onBlur}
      />
      <Input
        placeholder={getLocalizedText(appConfiguration, "membershipLastNameLabel", "Membership Last Name")}
        name="membershipLastName"
        onBlur={onBlur}
      />
    </LoginOption>
  );
}