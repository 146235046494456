export default function redactObject(
  object: Record<string, unknown> | null | undefined,
  preservedFields: string[] | null | undefined = null
) {
  if(!object) {
    return object;
  }

  for(const key in object) {
    const value = object[key];

    if(!value) {
      continue;
    }

    if(typeof value === "string") {
      if(value.length && !preservedFields?.includes(key)) {
        object[key] = value[0] + "X".repeat(value.length - 1);
      }
    } else if(typeof value === "object" && Object.keys(value).length > 1) {
      object[key] = redactObject(object[key] as Record<string, unknown>, preservedFields);
    }
  }

  return object;
}