import Input from "../../components/Input";
import { AppAddon, AppConfiguration } from "../../fetchers/getAppConfiguration";
import getLocalizedText from "../../formatters/getLocalizedText";
import { PriceFormatter } from "../../formatters/getPriceFormatter";

export interface DonationOptionProps {
  addon?: AppAddon;
  appConfiguration: AppConfiguration | null | undefined;
  format: PriceFormatter;
}

export default function DonationOption({
  addon,
  appConfiguration,
  format,
}: DonationOptionProps) {
  const addon_id = addon?.addon_id || "";
  const description = addon?.description;
  const price = addon?.price || 0;
  const inputID = `donation-option-${addon_id}`;

  return (
    <li className="donationOption">
      <Input
        deselectRadioOnClick={true}
        id={inputID}
        name="donationID"
        type="radio"
        value={addon_id}
      />
      <label
        className="donationOption__label"
        htmlFor={inputID}
      >{price
        ? format(price)
        : (addon_id
          ? getLocalizedText(appConfiguration, "giveACustomAmount", "Give a custom amount")
          : getLocalizedText(appConfiguration, "notAtThisTime", "Not at this time")
        )
      }</label>
      {description && <p className="donationOption__description">{description}</p>}
    </li>
  )
}