import Counter from "../../components/Counter";
import { AppAddon } from "../../fetchers/getAppConfiguration";
import { PriceFormatter } from "../../formatters/getPriceFormatter";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export interface AddOnOptionProps {
  addon: AppAddon;
  format: PriceFormatter;
}

export default function AddOnOption({
  addon,
  format,
}: AddOnOptionProps) {
  const { setFieldValue, values } = useFormikSchemaContext();

  return (
    <li className="addOnOption">
      <h4 className="addOnOption__title">{addon.name}</h4>
      {addon.image && <img
        alt={addon.name}
        className="addOnOption__image"
        src={addon.image}
      />}
      {addon.description && <div
        className="addOnOption__description"
        dangerouslySetInnerHTML={{ __html: addon.description }}
      />}
      <p className="addOnOption__price">{format(addon.price)}</p>
      <Counter
        className="addOnOption__quantity"
        max={addon.quantity || undefined}
        min={0}
        setValue={async (quantity) => {
          const newAddOns = values.addOns.filter(addOn => addOn.inventoryId !== addOn.inventoryId);
            
          newAddOns.push({
            inventoryId: addon.addon_id,
            quantity,
          });

          await setFieldValue("addOns", newAddOns);
        }}
        value={values.addOns.find(addOn => addOn.inventoryId === addon.addon_id)?.quantity || 0}
      />
    </li>
  )
}