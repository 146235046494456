import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface postConciergeOrderMainArgs {
  codeID: number | null | undefined;
  orderID: number | null | undefined;
}

export interface ConciergeOrder {
  id: number;
  concierge_code_id: number;
  order_id: number;
  created_at: string;
  updated_at: string;
}

export type postConciergeOrderArgs = getAuthorizedAPIDataSharedArgs & postConciergeOrderMainArgs;

export default async function getConciergeData({
  apiURL,
  authorizationToken,
  codeID,
  orderID,
}: postConciergeOrderArgs): APIResponse<ConciergeOrder> {
  if(!apiURL || !authorizationToken || !codeID || !orderID) {
    return null;
  }

  const endPoint = "api/concierge/order";

  const data = {
    codeID,
    orderID,
  };

  const method = "POST";

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
    method,
  });
}