import { useMemo } from "react";

import { AppConfiguration } from "../fetchers/getAppConfiguration";

export interface useTemplateIDsArgs {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function useTemplateIDs({ appConfiguration }: useTemplateIDsArgs) {
  const computedTemplateIDs = useMemo(() => {
    let templateIDs: string[] = [];

    if(appConfiguration?.template_ids.length) {
      templateIDs = templateIDs.concat(appConfiguration.template_ids);
    }

    if(appConfiguration?.combo_templates.length) {
      appConfiguration.combo_templates.forEach(comboTemplate => {
        comboTemplate.entries.forEach(entry => {
          templateIDs = templateIDs.concat(entry.summaries?.map(summary => summary.template_id) || []);
        });
      });
    }

    templateIDs = templateIDs.sort().filter((templateID, i) => templateID !== templateIDs[i - 1]);

    return templateIDs;
  }, [
    appConfiguration?.combo_templates,
    appConfiguration?.template_ids
  ]);

  return computedTemplateIDs;
}