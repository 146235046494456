import classNames from "classnames";
import { useState } from "react";

import ConciergeModal from "./ConciergeModal";
import { AppConfiguration } from "../fetchers/getAppConfiguration";
import { UseConciergeData } from "../hooks/useConciergeData";
import LoaderContainer from "./LoaderContainer";
import TextContent from "./TextContent";
import getLocalizedText from "../formatters/getLocalizedText";

export interface conciergeNoticeProps extends UseConciergeData {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function ConciergeNotice(props: conciergeNoticeProps) {
  const {
    appConfiguration,
    conciergeCode,
    conciergeData,
    conciergeDataError,
    isConciergeDataLoading,
    isConciergeDataValidating,
    useConcierge,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  if(!useConcierge) {
    return "";
  }

  return (
    <div className={classNames(
      "conciergeNotice",
      {
        "conciergeNotice--isLoading": isConciergeDataLoading,
        "conciergeNotice--isValidating": isConciergeDataValidating,
        "conciergeNotice--valid": conciergeData?.valid,
        "conciergeNotice--invalid": conciergeData?.valid === false,
      }
    )}>
      <LoaderContainer
        error={conciergeDataError}
        isLoading={isConciergeDataLoading}
        isValidating={isConciergeDataValidating}
        showLoadingIndicator={true}
      >
        {conciergeData?.valid
          ? (
            <>
              <div>
                <TextContent
                  appConfiguration={appConfiguration}
                  contentKey="conciergeCode"
                  defaultValue="Concierge Code"
                  tag="span"
                />
                <span className="conciergeNotice__code">{conciergeData.code}</span>
              </div>
              {(conciergeData?.agentName || conciergeData?.groupName) && (
                <>
                  <div>
                    <TextContent
                      appConfiguration={appConfiguration}
                      contentKey="conciergeAttribution"
                      defaultValue="This order will be attributed to"
                      tag="span"
                    />
                    <span className="conciergeNotice__attribution">
                      {conciergeData?.agentName ? <span className="conciergeNotice__agent">{conciergeData.agentName}</span> : ""}
                      {conciergeData?.groupName ? <span className="conciergeNotice__group">{conciergeData.groupName}</span> : ""}
                    </span>
                  </div>
                </>
              )}
            </>
          )
          : (
            <>
              <p className="conciergeNotice__error">
                <TextContent
                  appConfiguration={appConfiguration}
                  contentKey="conciergeError"
                  defaultValue="Invalid concierge code"
                  tag="span"
                />
                <span className="conciergeNotice__code">{conciergeCode || getLocalizedText(appConfiguration, 'none', '(None)')}</span>
              </p>
            </>
          )
        }
      </LoaderContainer>
      <button
        className="conciergeNotice__modalButton"
        onClick={() => setIsOpen(true)}
        type="button"
      >{conciergeCode
        ? getLocalizedText(appConfiguration, 'editConciergeCode', 'Edit Concierge Code')
        : getLocalizedText(appConfiguration, 'enterConciergeCode', 'Enter Concierge Code')
      }</button>
      <ConciergeModal {...props} disableCloseOnOutsideClick={false} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}