import useSWRImmutable from "swr/immutable";

import getAppConfiguration, { getAppConfigurationArgs } from "../fetchers/getAppConfiguration";

export interface useAppConfigurationArgs extends getAppConfigurationArgs {

}

export type UseAppConfiguration = ReturnType<typeof useAppConfiguration>;

export default function useAppConfiguration(props: useAppConfigurationArgs) {
  const { data, error, isLoading, isValidating } = useSWRImmutable({
    ...props,
    cacheKey: "appConfiguration",
  }, getAppConfiguration);

  return {
    appConfiguration: data,
    appConfigurationError: error,
    isAppConfigurationLoading: isLoading,
    isAppConfigurationValidating: isValidating,
  }
}