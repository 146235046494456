import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface AVSCountry {
  name: string;
  alpha_2_code: string | null;
  alpha_3_code: string | null;
  abbreviation: string | null;
  numeric_code: string | null;
  es: string | null;
  currency: string | null;
  capital: string | null;
}

export interface getAVSCountryDataMainArgs {

}

export type getAVSCountryDataArgs = getAuthorizedAPIDataSharedArgs & getAVSCountryDataMainArgs;

export type AVSCountryData = AVSCountry[];

export default async function getAVSCountryData({
  apiURL,
  authorizationToken,
}: getAuthorizedAPIDataSharedArgs): APIResponse<AVSCountryData> {
  const endPoint = "api/avs/countries";

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    endPoint,
  });
}