import { Formik } from "formik";
import { useEffect, useState } from "react";

import Form from "./components/Form";
import LoaderContainer from "./components/LoaderContainer";
import useAppConfiguration from "./hooks/useAppConfiguration";
import useAuthorizationToken from "./hooks/useAuthorizationToken";
import useAVSCountryData from "./hooks/useAVSCountryData";
import useAVSStateData from "./hooks/useAVSStateData";
import useDailyTicketData from "./hooks/useDailyTicketData";
import useCartData from "./hooks/useCartData";
import useCORSCheckout, { PostCORSCheckoutArgs } from "./hooks/useCORSCheckout";
import useMemberData from "./hooks/useMemberData";
import useMembershipRestrictionData from "./hooks/useMembershipRestrictions";
import useSchema from "./hooks/useSchema";
import useTicketData from "./hooks/useTicketData";
import useVoucherData from "./hooks/useVoucherData";
import steps, { getStep, goToNextStep, Steps } from "./steps"

import { SharedStepProps, Step } from "./steps/Step";
import useTemplateIDs from "./hooks/useTemplateIDs";

import "./App.scss";
import "./NEAQ.scss";
import useMemberLevelData from "./hooks/useMemberLevelData";
import { AppConfiguration } from "./fetchers/getAppConfiguration";
import { Voucher } from "./fetchers/getVoucherData";
import { Cart } from "./fetchers/getCartData";
import { Order } from "./fetchers/postCORSCheckout";

export interface AppHooks {
  onCheckout?: (order: Order | null, checkout: PostCORSCheckoutArgs) => void;
  onReady?: (appConfiguration: AppConfiguration) => void;
  onResidentZipCode?: (zipCode: string, valid: boolean) => void;
  onSelectTicketDate?: (date: string) => void;
  onSelectTicketTime?: (time: string, date: string) => void;
  onSelectTicketType?: (ticketTypeName: string) => void;
  onStep?: (fromStep: Step | null, toStep: Step) => void;
  onUpdateCart?: (cart: Cart | null) => void;
  onVoucher?: (voucherCode: string, valid: boolean, voucherInfo: Voucher | null) => void;
}

export interface AppProps {
  apiURL: string;
  appConfigurationID: number;
  appHooks?: AppHooks;
  clientID: number;
  clientSecret: string;
  showMembershipSteps?: boolean;
  showTicketSteps?: boolean;
}

export default function App({
  apiURL,
  appConfigurationID,
  appHooks = {},
  clientID,
  clientSecret,
  showMembershipSteps = false,
  showTicketSteps = true
}: AppProps) {
  const hookedAuthorizationTokenData = useAuthorizationToken({
    apiURL,
    clientID,
    clientSecret,
  });

  const authorizationToken = hookedAuthorizationTokenData.authorizationToken;

  const hookedAppConfigurationData = useAppConfiguration({
    apiURL,
    authorizationToken,
    appConfigurationID,
  });

  const {
    appConfiguration,
    appConfigurationError,
    isAppConfigurationLoading,
    isAppConfigurationValidating,
  } = hookedAppConfigurationData;

  const templateIDs = useTemplateIDs({ appConfiguration });

  const days = 7;
  let startDate: Date | null = null;
  let endDate: Date | null = null;
  
  if(appConfiguration) {
    if(appConfiguration.start_date) {
      startDate = new Date(appConfiguration.start_date);
    } else {
      startDate = new Date();
    }

    if(appConfiguration.end_date) {
      endDate = new Date(appConfiguration.end_date);
    }
  }

  const hookedAVSCountryData = useAVSCountryData({
    apiURL,
    authorizationToken,
  });

  const hookedAVSStateData = useAVSStateData({
    apiURL,
    authorizationToken,
  });

  const hookedMemberData = useMemberData({
    apiURL,
    authorizationToken,
  });

  const memberData = hookedMemberData.memberData;

  const hookedTicketData = useTicketData({
    apiURL,
    appConfiguration,
    authorizationToken,
    membershipID: memberData?.primaryCardHolder?.membershipId?.toString() || null,
    templateIDs,
  });

  const {
    setTicketDate,
  } = hookedTicketData;

  const hookedDailyTicketData = useDailyTicketData({
    apiURL,
    authorizationToken,
    days,
    endDate,
    membershipID: memberData?.primaryCardHolder?.membershipId?.toString() || null,
    startDate,
    templateIDs: appConfiguration?.summary_template ? [appConfiguration.summary_template] : templateIDs,
  });

  const hookedMembershipRestrictonData = useMembershipRestrictionData({
    apiURL,
    authorizationToken,
    membershipCategoryID: memberData?.primaryCardHolder?.membershipCategory || "",
    templateIDs: appConfiguration?.template_ids || [],
  });

  const hookedMemberLevelData = useMemberLevelData({
    apiURL,
    authorizationToken,
  });

  const hookedCartData = useCartData({
    apiURL,
    authorizationToken,
    onUpdateCart: appHooks?.onUpdateCart,
  });

  const hookedCORSCheckout = useCORSCheckout({
    apiURL,
    authorizationToken,
    corsURL: appConfiguration?.acme_api_url || null,
    publicKey: appConfiguration?.cors_key || null,
    tenantID: appConfiguration?.acme_tenant_id || null,
  });

  const hookedVoucherData = useVoucherData({
    apiURL,
    authorizationToken,
    onVoucher: appHooks?.onVoucher
  });

  const hookedSchema = useSchema();

  useEffect(() => {
    if(appConfiguration?.start_date) {
      setTicketDate(appConfiguration.start_date);
    } else if(hookedDailyTicketData.dailyTicketData?.length) {
      setTicketDate(hookedDailyTicketData.dailyTicketData[0].ticket_date);
    }
  }, [
    appConfiguration?.start_date,
    hookedDailyTicketData.dailyTicketData,
    setTicketDate
  ]);

  const [formReady, setFormReady] = useState(false);

  useEffect(() => {
    if(appConfiguration) {
      setFormReady(true);

      if(appHooks?.onReady) {
        appHooks.onReady(appConfiguration);
      }
    }
  }, [appConfiguration, appHooks, formReady]);

  const sharedStepProps: SharedStepProps = {
    ...appHooks,
    ...hookedAppConfigurationData,
    ...hookedAuthorizationTokenData,
    ...hookedAVSCountryData,
    ...hookedAVSStateData,
    ...hookedCartData,
    ...hookedCORSCheckout,
    ...hookedDailyTicketData,
    ...hookedMemberData,
    ...hookedMemberLevelData,
    ...hookedMembershipRestrictonData,
    ...hookedTicketData,
    ...hookedVoucherData,
    ...hookedSchema,
  };

  const { schema, meta } = hookedSchema;

  return (
    <div className="acmeTicketing">
      <LoaderContainer
        appConfiguration={appConfiguration}
        error={appConfigurationError}
        errorMessage="Could not load app configuration. Please refresh the page and try again."
        isLoading={isAppConfigurationLoading}
        isValidating={isAppConfigurationValidating || !formReady}
        render={() => (
          <Formik
            initialValues={schema.cast((() => {
              const stepsEnabled = steps.reduce((acc, step) => {
                acc[step.stepNumber] = true;

                return acc;
              }, {} as Record<Steps, boolean>);

              if(
                !appConfiguration!.allow_member_login
                && !appConfiguration!.allow_resident_login
                && !appConfiguration!.show_additional_login_option
                && !appConfiguration!.allow_vouchers
              ) {
                stepsEnabled[Steps.Login] = false;
              }

              const donationTypes = ["donation", "fixedDonation"];

              if(!appConfiguration?.addons.find(addon => donationTypes.includes(addon.type))) {
                stepsEnabled[Steps.Donation] = false;
              }

              if(!appConfiguration?.addons.find(addon => !donationTypes.includes(addon.type))) {
                stepsEnabled[Steps.Addons] = false;
              }

              if(showMembershipSteps) {
                steps.forEach(step => {
                  if(!step.isMembershipStep) {
                    stepsEnabled[step.stepNumber] = false;
                  }
                });
              }

              if(showTicketSteps) {
                steps.forEach(step => {
                  if(!step.isTicketStep) {
                    stepsEnabled[step.stepNumber] = false;
                  }
                });
              }

              let membershipLevelSlug = "";
              let membershipTarget = "";

              if(showMembershipSteps) {
                const params = new URLSearchParams(window.location.search);

                membershipLevelSlug = params.get("level") || "";
                //membershipTarget = params.get("recipient") || "self";
                membershipTarget = "self";
              }
              
              const stepNumber = steps.find(step => stepsEnabled[step.stepNumber])!.stepNumber;
              
              return {
                membershipLevelSlug,
                membershipTarget,
                stepsEnabled,
                stepNumber,
              };
            })())}
            onSubmit={async (values, helpers) => {
              const step = getStep(values.stepNumber);
              
              if(step) {
                if(step.onSubmit) {
                  await step.onSubmit({
                    ...sharedStepProps,
                    helpers,
                    meta,
                    values,
                  });
                } else {
                  await goToNextStep(
                    values.stepNumber,
                    values.stepsEnabled,
                    helpers.setFieldValue,
                    helpers.setFieldTouched,
                    meta,
                    appHooks.onStep
                  );
                }
              }
            }}
            validationSchema={schema}
          >
            <Form {...sharedStepProps} />
          </Formik>
        )}
      />
    </div>
  )
}