import { useEffect } from "react";

import { UseFormikSchemaContext } from "../../hooks/useFormikSchemaContext";
import { UseVoucherData } from "../../hooks/useVoucherData";

export interface useVoucherCodesArgs extends 
  Pick<UseVoucherData, "voucherData">, 
  Pick<UseFormikSchemaContext, "setFieldValue" | "values">
{

}

export type UseVoucherCodes = ReturnType<typeof useVoucherCodes>;

export default function useVoucherCodes({
  setFieldValue,
  values,
  voucherData
}: useVoucherCodesArgs) {
  useEffect(() => {
    let newVoucherCodes = values.voucherCodes ? [...values.voucherCodes] : values.voucherCodes;
    const originalVoucherCodesSignature = newVoucherCodes?.join(",") || "";

    if(!newVoucherCodes) {
      newVoucherCodes = [];
    }

    if(!newVoucherCodes.length) {
      newVoucherCodes.push("")
    } else {
      if(voucherData[newVoucherCodes[newVoucherCodes.length - 1] || ""] && voucherData[newVoucherCodes[newVoucherCodes.length - 1] || ""]?.id) {
        newVoucherCodes.push("");
      }
    }
    
    const modifiedVoucherCodesSignature = newVoucherCodes.join(",");

    if(originalVoucherCodesSignature !== modifiedVoucherCodesSignature) {
      setFieldValue("voucherCodes", newVoucherCodes);
    }
  }, [
    setFieldValue,
    values.voucherCodes,
    voucherData
  ]);
}