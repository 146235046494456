import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface ConciergeData {
  agentName: string | null;
  code: string;
  codeID: number | null;
  groupName: string | null;
  label: string | null;
  note: string | null;
  valid: boolean;
}

export interface getConciergeDataArgs extends getAuthorizedAPIDataSharedArgs {
  code: string | null | undefined;
}

export default async function getConciergeData({
  apiURL,
  authorizationToken,
  code,
}: getConciergeDataArgs): APIResponse<ConciergeData> {
  if(!code) {
    return null;
  }

  const endPoint = "api/concierge";

  const data = {
    code,
  };

  const method = "POST";

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
    method,
  });
}