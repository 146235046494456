import classNames from "classnames";
import { useId }from "react";

export interface CounterProps {
  className?: string;
  label?: string;
  max?: number;
  min?: number;
  setValue: (value: number) => void;
  value?: number;
}

export default function Counter({
  className,
  label,
  max,
  min,
  setValue,
  value = 0,
}: CounterProps) {
  const id = `counter-${useId()}`;
  const increaseEnabled = typeof(max) === "undefined" || value < max;
  const decreaseEnabled = typeof(min) === "undefined" || value > min;

  return (
    <div 
      className={classNames({
        "counter": true,
        "counter--decreaseEnabled": decreaseEnabled,
        "counter--hasValue": value,
        "counter--increaseEnabled": increaseEnabled,
        [className + ""]: className,
      })}
    >
      {label && (
        <label htmlFor={id}>{label}</label>
      )}
      <button
        aria-controls={id}
        className={classNames({
          "counter__button": true,
          "counter__button--decrease": true,
          "counter__button--disabled": !decreaseEnabled,
        })}
        disabled={!decreaseEnabled}
        onClick={(() => {
          if(decreaseEnabled) {
            setValue(value - 1);
          }
        })}
        title={label ? `Decrease ${label}` : "Decrease"}
        type="button"
      >-</button>
      <input
        className={classNames({
          "counter__input": true,
          "counter__input--decreaseEnabled": decreaseEnabled,
          "counter__input--increaseEnabled": increaseEnabled,
          "counter__input--hasValue": value,
          "counter__input--noValue": !value,
        })}
        id={id}
        max={max}
        min={min}
        onChange={(e) => {
          const newValue = parseInt(e.target.value);

          if(
            !isNaN(newValue)
            && (typeof(max) === "undefined" || newValue <= max)
            && (typeof(min) === "undefined" || newValue >= min)
          ) {
            setValue(newValue);
          }
        }}
        step="1"
        title={label}
        type="number"
        value={value}
      />
      <button
        aria-controls={id}
        className={classNames({
          "counter__button": true,
          "counter__button--increase": true,
          "counter__button--disabled": !increaseEnabled,
        })}
        disabled={!increaseEnabled}
        onClick={(() => {
          if(increaseEnabled) {
            setValue(value + 1);
          }
        })}
        title={label ? `Increase ${label}` : "Increase"}
        type="button"
      >+</button>
    </div>
  );
}