import SelectionButton from "./SelectionButton";

import { StepComponentProps } from "../Step";

import Carousel from "../../components/Carousel";
import LoaderContainer from "../../components/LoaderContainer";
import TextContent from "../../components/TextContent";
import getLocalizedDate, { getSystemTimeZone } from "../../formatters/getLocalizedDate";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getPriceFormatter from "../../formatters/getPriceFormatter";
import ScheduleAheadButton from "../Header/ScheduleAheadButton";

export default function DateSelection(props: StepComponentProps) {
  const {
    appConfiguration,
    appConfigurationError,
    dailyTicketData,
    dailyTicketDataError,
    dateCarouselQueuedIndex,
    isAppConfigurationLoading,
    isAppConfigurationValidating,
    isDailyTicketDataLoading,
    isDailyTicketDataValidating,
    onSelectTicketDate,
    readFrom,
    setDateCarouselQueuedIndex,
    setTicketDate,
    setTicketEvents,
    ticketDate,
  } = props;

  const { setFieldValue } = useFormikSchemaContext();

  const locale = appConfiguration?.locale ?? "en-US";
  const timeZone = appConfiguration?.source_time_zone ?? undefined;

  const multiDateFormatter = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
    timeZone,
    weekday: "short",
  });

  const singleDateFormatter = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "long",
    timeZone,
    weekday: "long",
    year: "numeric",
  });

  const format = getPriceFormatter(appConfiguration?.locale, true);
  const systemTimeZone = getSystemTimeZone();

  return (
    <>
      {appConfiguration?.show_date_selection && (
        <TextContent
          appConfiguration={appConfiguration}
          contentKey="beforeTicketDateSelect"
          defaultValue={"Select entry date and time"}
        />
      )}

      <ScheduleAheadButton {...props} />

      <TextContent
        appConfiguration={appConfiguration}
        contentKey="ticketDateSelectHeader"
        defaultValue="Date of Visit"
        tag="h3"
      />
      <LoaderContainer
        appConfiguration={appConfiguration}
        error={appConfigurationError || dailyTicketDataError}
        errorMessage="Could not load ticket data. Please refresh the page."
        isLoading={isAppConfigurationLoading || (isDailyTicketDataLoading && !dailyTicketData)}
        isValidating={isAppConfigurationValidating || (isDailyTicketDataValidating && !dailyTicketData)}
      >{appConfiguration?.show_date_selection ? (
        dailyTicketData?.length ? (
          <Carousel
            id="dateSelection"
            onScrollToEnd={async () => {
              if(!isDailyTicketDataLoading) {
                await readFrom(new Date(dailyTicketData[dailyTicketData.length - 1].ticket_date))
              }
            }}
            queuedIndex={dateCarouselQueuedIndex}
            setQueuedIndex={setDateCarouselQueuedIndex}
          >{dailyTicketData?.map((day) => (
            <SelectionButton
              key={day.ticket_date}
              format={format}
              header={multiDateFormatter.format(getLocalizedDate(day.ticket_date, appConfiguration.source_time_zone))}
              dayName={getLocalizedDate(day.ticket_date, systemTimeZone).getDay()}
              month={getLocalizedDate(day.ticket_date, systemTimeZone).getMonth()}
              date={getLocalizedDate(day.ticket_date, systemTimeZone).getDate()}
              onClick={async () => {
                if(ticketDate !== day.ticket_date) {
                  setTicketDate(day.ticket_date);
                  setTicketEvents([]);

                  if(onSelectTicketDate) {
                    onSelectTicketDate(day.ticket_date);
                  }
                  
                  await setFieldValue("tickets", []);
                  await setFieldValue("comboTickets", []);
                }
              }}
              price={day.min_price}
              seatsAvailable={day.total_seats_available}
              seatsCapacity={day.total_seats_capacity}
              selected={day.ticket_date === ticketDate}
              summaryCategory={appConfiguration?.summary_category}
            />
          ))}</Carousel>
        )
        : (
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="noTicketDatesMessage"
            defaultValue="There are no tickets for this date range. Try selecting another date with Schedule Ahead."
          />
        )
      ) : (
        ticketDate && <p>{singleDateFormatter.format(getLocalizedDate(ticketDate, appConfiguration?.source_time_zone))}</p>
      )}</LoaderContainer>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="afterTicketDateSelect"
      />
    </>
  );
}