import classNames from "classnames";
import { PropsWithChildren } from "react";

export type CarouselButtonProps = PropsWithChildren<{
  controlsID: string;
  disabled: boolean;
  onClick: () => void;
  tag: "previous" | "next";
}>

export default function CarouselNavButton({
  children,
  controlsID,
  disabled,
  onClick,
  tag,
}: CarouselButtonProps) {
  return (
    <button
      aria-controls={controlsID}
      className={classNames({
        "carousel__button": true,
        "carousel__button--disabled": disabled,
        [`carousel__button--${tag}`]: true,
      })}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >{children}</button>
  )
}