import { Step } from "./Step";
import Steps from "./Steps";

import AddOns from "./AddOns";
import Billing from "./Billing";
import Donation from "./Donation";
import Done from "./Done";
import Login from "./Login";
import MembershipInformation from "./MembershipInformation";
import MembershipReview from "./MembershipReview";
import Review from "./Review";
import TicketConfirmation from "./TicketConfirmation";
import Tickets from "./Tickets";
import { ExpandedSchemaObjectDescription } from "../schema";

const steps = [
  Login,
  MembershipReview,
  MembershipInformation,
  Tickets,
  TicketConfirmation,
  Donation,
  AddOns,
  Billing,
  Review,
  Done,
];

type StepArg = Steps | Step;
type OnStep = (fromStep: Step | null, toStep: Step) => void;

const getStepIndex = (step: StepArg) => (typeof step === "object" ? step.stepNumber : step) ?? 0;

export function compareSteps(a: StepArg, b: StepArg) {
  return getStepIndex(a) - getStepIndex(b);
}

export function getStep(targetStep: StepArg) {
  if(typeof(targetStep) === "number") {
    return steps.find((step) => step.stepNumber === targetStep)!;
  } else {
    return targetStep;
  }
}

export function getFirstActiveStep(stepsEnabled: Record<Steps, boolean>) {
  let stepIndex = -1;

  while(++stepIndex < steps.length) {
    if(stepsEnabled[steps[stepIndex].stepNumber]) {
      return steps[stepIndex];
    }
  }

  return null;
}

export function getNextStep(
  currentStep: StepArg,
  stepsEnabled: Record<Steps, boolean>,
) {
  let stepIndex = getStepIndex(currentStep);
    
  if(!~stepIndex) {
    console.error("Invalid step: ", currentStep);
    return null;
  }

  while(++stepIndex < steps.length) {
    if(stepsEnabled[steps[stepIndex].stepNumber]) {
      return steps[stepIndex];
    }
  }

  return null;
}

export function getPreviousStep(
  currentStep: StepArg,
  stepsEnabled: Record<Steps, boolean>
) {
  let stepIndex = getStepIndex(currentStep);
    
  if(!~stepIndex) {
    console.error("Invalid step: ", currentStep);
    return null;
  }

  while(--stepIndex >= 0) {
    if(stepsEnabled[steps[stepIndex].stepNumber]) {
      return steps[stepIndex];
    }
  }

  return null;
}

export async function goToStep(
  currentStep: StepArg | null,
  nextStep: StepArg,
  stepsEnabled: Record<Steps, boolean>,
  setFieldValue: (key: string, value: unknown) => Promise<unknown>,
  setFieldTouched: (key: string, value: boolean) => Promise<unknown>,
  meta?: ExpandedSchemaObjectDescription,
  onStep?: OnStep
) {
  const currentStepObject = currentStep ? getStep(currentStep) : getFirstActiveStep(stepsEnabled);
  const nextStepObject = getStep(nextStep);

  if(
    stepsEnabled[nextStepObject.stepNumber] 
    && (
      !currentStepObject 
      || (currentStepObject.stepNumber !== nextStepObject.stepNumber)
    )
  ) {
    await setFieldValue("stepNumber", nextStepObject.stepNumber);

    if(meta) {
      for(const fieldName in meta.fields) {
        const field = meta.fields[fieldName];

        if(field.meta?.stepNumber && stepIsOnOrAfter(field.meta?.stepNumber, nextStepObject.stepNumber)) {
          await setFieldTouched(fieldName, false);
        }
      }
    }

    if(onStep) {
      try {
        onStep(currentStepObject, nextStepObject);
      } catch(error) {
        console.error("onStep", error, currentStep, nextStep);
      }
    }
  } else {
    if(stepsEnabled[nextStepObject.stepNumber]) {
      console.error("Already on step: ", nextStepObject.defaultLabel || Steps[nextStepObject.stepNumber]);
    } else {
      console.error("Attempt to navigate to disabled step: ", nextStepObject.defaultLabel || Steps[nextStepObject.stepNumber]);
    }
  }
}

export async function goToNextStep(
  currentStep: StepArg,
  stepsEnabled: Record<Steps, boolean>,
  setFieldValue: (key: string, value: unknown) => Promise<unknown>,
  setFieldTouched: (key: string, value: boolean) => Promise<unknown>,
  meta?: ExpandedSchemaObjectDescription,
  onStep?: OnStep
) {
  const newStep = getNextStep(currentStep, stepsEnabled);
  
  if(newStep) {
    await goToStep(currentStep, newStep, stepsEnabled, setFieldValue, setFieldTouched, meta, onStep);
  } else {
    console.log("Reached end of steps.");
  }
}

export async function goToPreviousStep(
  currentStep: StepArg,
  stepsEnabled: Record<Steps, boolean>,
  setFieldValue: (key: string, value: unknown) => Promise<unknown>,
  setFieldTouched: (key: string, value: boolean) => Promise<unknown>,
  meta?: ExpandedSchemaObjectDescription,
  onStep?: OnStep
) {
  const newStep = getPreviousStep(currentStep, stepsEnabled);
    
  if(newStep) {
    await goToStep(currentStep, newStep, stepsEnabled, setFieldValue, setFieldTouched, meta, onStep);
  } else {
    console.log("Reached beginning of steps.");
  }
}

export function stepIsAfter(a: StepArg, b: StepArg) {
  return compareSteps(a, b) > 0;
}

export function stepIsOnOrAfter(a: StepArg, b: StepArg) {
  return compareSteps(a, b) >= 0;
}

export { default as Steps } from "./Steps";

export default steps;