import classNames from "classnames";
import { PropsWithChildren } from "react";

import { PriceFormatter } from "../../formatters/getPriceFormatter";

export type SelectionButtonProps = PropsWithChildren<{
  capacityLevels?: number;
  className?: string;
  disabled?: boolean;
  format?: PriceFormatter;
  header?: string;
  onClick: () => void;
  price?: number;
  seatsAvailable?: number;
  seatsCapacity?: number;
  selected?: boolean;
  showSummary?: boolean;
  subHeader?: string;
  summaryCategory?: string | null;
}>;

export default function SelectionButton({
  capacityLevels = 5,
  children,
  className,
  disabled,
  format,
  header,
  onClick,
  price,
  seatsAvailable,
  seatsCapacity,
  selected,
  showSummary = true,
  subHeader,
  summaryCategory,
}: SelectionButtonProps) {
  const closed = !seatsCapacity;
  const soldOut = !!seatsCapacity && !seatsAvailable;
  const seatsTaken = (seatsCapacity || 0) - (seatsAvailable || 0);
  const capacityLevel = Math.ceil((seatsTaken / (seatsCapacity || 0)) * capacityLevels);

  return (
    <button
      className={classNames({
        "selectionButton": true,
        "selectionButton--selected": selected,
        "selectionButton--closed": closed,
        "selectionButton--free": !price,
        "selectionButton--hasCategory": summaryCategory,
        "selectionButton--soldOut": soldOut,
        [className + ""]: className,
      })}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {children || (
        <>
          <h3 className="selectionButton__header">{header}</h3>
          {subHeader && <h4 className="selectionButton__subHeader">{subHeader}</h4>}
          {showSummary && <p className="selectionButton__price">
            {price && summaryCategory && <span className="selectionButton__category">{summaryCategory}s from </span>}
            <span className="selectionButton__amount">{format ? format(price) : price}</span>
          </p>}
          {!closed && !soldOut && (
            <div className="selectionButton__capacity">
              <div className="selectionButton__capacity__description">{`${seatsAvailable} of ${seatsCapacity} available`}</div>
              <div className="selectionButton__capacity__indicator">
                {[...Array(capacityLevels).keys()].map(level => (
                  <span
                    key={level}
                    className={classNames({
                      "selectionButton__capacity__indicator__tick": true,
                      "selectionButton__capacity__indicator__tick--empty": level >= capacityLevel,
                      "selectionButton__capacity__indicator__tick--filled": level < capacityLevel,
                    })}
                    role="presentation"
                    title={level < capacityLevel ? "Filled" : "Empty"}
                  >&nbsp;</span>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </button>
  );
}