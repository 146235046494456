const prefixLength = 4;
const maxFieldLength = 12;

export default function redactObject(
  object: Record<string, unknown> | null | undefined,
  preservedFields: string[] | null | undefined = null,
  excludedFields: string[] | null | undefined = null,
) {
  if(!object) {
    return object;
  }

  for(const key in object) {
    const value = object[key];

    if(!value) {
      continue;
    }

    if(excludedFields?.includes(key)) {
      delete object[key];
    } else if(typeof value === "string") {
      let stringValue: string = value;

      if(!preservedFields?.includes(key)) {
        if(stringValue.length > maxFieldLength) {
          stringValue = stringValue.substring(0, maxFieldLength) + "...";
        }
      
        if(stringValue.length > prefixLength) {
          stringValue = stringValue.substring(0, prefixLength) + "X".repeat(stringValue.length - prefixLength);
        }
      }

      object[key] = stringValue;
    } else if(typeof value === "object" && Object.keys(value).length > 1) {
      object[key] = redactObject(object[key] as Record<string, unknown>, preservedFields);
    }
  }

  return object;
}