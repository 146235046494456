import { useCallback } from "react";

import useSWRImmutable from "swr/immutable";

import getMemberLevelData, { getMemberLevelDataArgs } from "../fetchers/getMemberLevelData";

export type UseMemberLevelData = ReturnType<typeof useMemberLevelData>;

export default function useMemberLevelData(args: getMemberLevelDataArgs) {
  const {
    data,
    error,
    isLoading,
    isValidating,
  } = useSWRImmutable(args, getMemberLevelData);

  const getMemberLevel = useCallback((slug: string) => data?.find(level => level
    .name
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-") === slug),
    [data]
  );

  return {
    getMemberLevel,
    memberLevelDataError: error,
    memberLevelData: data,
    memberLevelDataIsLoading: isLoading,
    memberLevelDataIsValidating: isValidating,
  };
}