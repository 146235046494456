
import classNames from "classnames";
import { useEffect, useId, useRef, useState } from "react";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getLocalizedText from "../../formatters/getLocalizedText";

export interface RecaptchaV2Props {
  appConfiguration: AppConfiguration | null | undefined;
}

const scriptClassName = "recaptchaV2__script";

export default function RecaptchaV2({
  appConfiguration
}: RecaptchaV2Props) {
  const { setFieldError, setFieldValue } = useFormikSchemaContext();
  const id = useId().replace(/[^A-Za-z]+/g, "");
  const ref = useRef<HTMLDivElement | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const onLoadName = `onRecaptchaV2Load${id}`;
  const scriptID = `recaptchaV2Script${id}`;

  useEffect(() => {
    (async () => {
      if(appConfiguration?.recaptcha_site_key_v2) {
        await setFieldValue("recaptchaV2Token", "");
        await setFieldValue("recaptchaV2Valid", false);
      }
    })();
  }, [
    appConfiguration?.recaptcha_site_key_v2,
    setFieldValue,
  ]);

  useEffect(() => {
    // @ts-expect-error Free-form window prop.
    window[onLoadName] = () => {
      setIsLoaded(true);
    };

    return () => {
      // @ts-expect-error Free-form window prop.
      delete window[onLoadName];
    };
  }, [onLoadName]);

  useEffect(() => {
    if(isLoaded && !isRendered && appConfiguration?.recaptcha_site_key_v2) {
      window.grecaptcha.render(ref.current!, {
        sitekey: appConfiguration.recaptcha_site_key_v2!,
        callback: async (response) => {
          if(response) {
            await setFieldValue("recaptchaV2Token", response);
            await setFieldValue("recaptchaV2Valid", true);
          } else {
            await setFieldValue("recaptchaV2Token", "");
            await setFieldValue("recaptchaV2Valid", false);
          }
        },
        "expired-callback": async () => {
          await setFieldValue("recaptchaV2Token", "");
          await setFieldValue("recaptchaV2Valid", false);
        },
        "error-callback": async () => {
          await setFieldValue("recaptchaV2Token", "");
          await setFieldValue("recaptchaV2Valid", false);
          await setFieldError("recaptchaV2Valid", getLocalizedText(
            appConfiguration,
            "recaptchaError",
            "Error validating RECAPTCHA. Please reload the page and try again."
          ));
        }
      });
      
      setIsRendered(true);
    }
  }, [
    appConfiguration,
    appConfiguration?.recaptcha_site_key_v2,
    isLoaded,
    isRendered,
    setFieldError,
    setFieldValue
  ]);

  useEffect(() => {
    const scriptInitialized = !!document.querySelector(`#${scriptID}`);

    if(!scriptInitialized) {
      const script = document.createElement("script");

      script.setAttribute("async", "");
      script.setAttribute("className", scriptClassName);
      script.setAttribute("defer", "");
      script.setAttribute("id", scriptID);
      script.setAttribute("src", `https://www.google.com/recaptcha/api.js?onload=${onLoadName}&render=explicit`);
    
      document.body.appendChild(script);
    }

    return () => {
      const oldScript = document.querySelector(`#${scriptID}`);

      if(oldScript) {
        oldScript.remove();
      }
    };
  }, [onLoadName, scriptID]);

  return (
    <div
      className={classNames({
        "recaptchaV2": true,
        "recaptchaV2--loaded": isLoaded,
        "recaptchaV2--rendered": isRendered,
      })}
      ref={ref}
    ></div>
  )
}