import useSWRImmutable from "swr/immutable";

import getAuthorizationToken, { getAuthorizationTokenArgs } from "../fetchers/getAuthorizationToken";

export interface useAuthorizationTokenArgs extends getAuthorizationTokenArgs {

}

export type UseAuthorizationToken = ReturnType<typeof useAuthorizationToken>;

export default function useAuthorizationToken(props: useAuthorizationTokenArgs) {
  const { data, error, isLoading, isValidating } = useSWRImmutable({
    ...props,
    cacheKey: "authorizationToken",
  }, getAuthorizationToken);
  
  return {
    authorizationToken: data,
    authorizationTokenError: error,
    isAuthorizationTokenLoading: isLoading,
    isAuthorizationTokenValidating: isValidating,
  };
}