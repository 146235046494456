import AddOnOption from "./AddOnOption";

import { AppAddon } from "../../fetchers/getAppConfiguration";
import { PriceFormatter } from "../../formatters/getPriceFormatter";

export interface AddOnsListProps {
  addons: AppAddon[];
  format: PriceFormatter;
}

export default function AddOnsList({
  addons,
  format,
}: AddOnsListProps) {
  return (
    <ul className="addOnsList">
      {addons.map((addon) => <AddOnOption
        addon={addon}
        key={addon.addon_id}
        format={format}
      />)}
    </ul>
  );
}