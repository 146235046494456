import { useMemo } from "react";

import { ExpandedSchemaObjectDescription, getSchema } from "../schema";

export type UseSchema = ReturnType<typeof useSchema>;

export default function useSchema() {
  const schema = useMemo(() => getSchema(), []);
  const meta = useMemo(() => schema.describe() as ExpandedSchemaObjectDescription, [schema]);

  return {
    meta,
    schema,
  }
}