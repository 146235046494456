import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";
import { Voucher } from "./getVoucherData";

import formatSQLDate from "../formatters/formatSQLDate";

export interface redeemVoucherMainArgs {
  orderNumber: string;
  visitDate: string | Date;
  voucherCode: string;
}

export type redeemVoucherArgs = getAuthorizedAPIDataSharedArgs & redeemVoucherMainArgs;

export default async function redeemVoucher({
  apiURL,
  authorizationToken,
  orderNumber,
  visitDate,
  voucherCode,
}: redeemVoucherArgs): APIResponse<Voucher> {
  if(!orderNumber || !voucherCode || !visitDate) {
    return null;
  }

  const endPoint = "api/vouchers/redeem";

  const data = {
    order_number: orderNumber,
    visit_date: formatSQLDate(visitDate),
    voucher_code: voucherCode,
  };

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
    method: "POST",
  });
}