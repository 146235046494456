import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

import redactObject from "../formatters/redactObject";

export interface postClientCheckoutLogMainArgs {
  requestPayload: Record<string, unknown> | null;
  responsePayload: Record<string, unknown> | null;
  responseStatus: number | null;
}

export type postClientCheckoutLogArgs = getAuthorizedAPIDataSharedArgs & postClientCheckoutLogMainArgs;

export interface postClientCheckoutLogResponse {
  entry: string;
};

export type PostClientCheckoutLog = typeof postClientCheckoutLog;

export default async function postClientCheckoutLog({
  requestPayload,
  responsePayload,
  responseStatus,
  ...args
}: postClientCheckoutLogArgs) {
  const endPoint = "api/client-logs/checkout";
  const method = "POST";

  const preservedFields = [
    "accountCategoryId",
    "accountContactId",
    "acmeSessionId",
    "checkinDate",
    "comboTemplateId",
    "comboTemplateName",
    "couponId",
    "discountId",
    "discountCategoryId",
    "error",
    "errorCodes",
    "errorDescription",
    "eventId",
    "eventName",
    "id",
    "itemId",
    "message",
    "orderExceptionCodeId",
    "orderExceptionCodeName",
    "orderNumber",
    "organizationId",
    "path",
    "parentItemId",
    "paymentId",
    "posUUID",
    "processorErrorMessage",
    "shoppingCartId",
    "saleChannel",
    "status",
    "tempVisitorId",
    "tenantId",
    "ticketingTypeId",
    "ticketingTypeName",
    "ticketStatus",
    "timestamp",
    "type",
    "updatedOn",
    "uuid",
    "visitorId",
  ];

  const data = {
    requestPayload: redactObject(JSON.parse(JSON.stringify(requestPayload)), preservedFields),
    responsePayload: redactObject(JSON.parse(JSON.stringify(responsePayload)), preservedFields),
    responseStatus,
  };
  
  try {
    return getAuthorizedAPIData({
      ...args,
      data,
      endPoint,
      method,
    }) as APIResponse<postClientCheckoutLogResponse>;
  } catch (e) {
    console.error(e);

    return null;
  }
}