import classNames from "classnames";

import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

import SelectionButton from "./SelectionButton";
import { StepComponentProps } from "../Step";

import TextContent from "../../components/TextContent";
import getPriceFormatter from "../../formatters/getPriceFormatter";

export default function CategorySelection(props: StepComponentProps) {
  const {
    appConfiguration,
    onSelectTicketType,
    setTicketCategoryID,
    setTicketEvents,
    summaryCategory,
    isTicketDataLoading,
    isTicketDataValidating,
    ticketCategories,
    ticketCategoryID,
    ticketData,
    voucherData,
  } = props;

  const { setFieldValue, values } = useFormikSchemaContext();

  if(!appConfiguration?.ticket_categories.length) {
    return "";
  }

  const format = getPriceFormatter(appConfiguration.locale || "en-US", false);

  return (
    <div className="ticketCategorySelection">
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="ticketCategorySelectionTitle"
        defaultValue="Select Your Ticket Type"
        tag="h3"
      />
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="beforeTicketCategorySelection"
      />
      <ul className="ticketCategorySelection__list">
        {ticketCategories.map(({
          id,
          label,
          template_ids,
        }) => {
          let ticketCategoryTemplateIDs: string[] = [];
          let minPrice: number | undefined = undefined;
          let hasTickets = false;

          template_ids.forEach((templateID) => {
            const comboTemplate = appConfiguration.combo_templates.find(c => c.template === templateID);

            if(comboTemplate) {
              comboTemplate.prices.forEach(price => {
                if(price.applies_to === summaryCategory) {
                  const parsedPrice = typeof(price.price) === "string" ? parseFloat(price.price) : price.price;

                  if(minPrice === undefined || parsedPrice < minPrice) {
                    minPrice = parsedPrice;
                  }
                }
              });

              if(comboTemplate.entries.length) {
                hasTickets = true;

                for(const entry of comboTemplate.entries) {
                  const entryTemplateIDs = entry.summaries?.map(({ template_id }) => template_id) || [];

                  if(!ticketData?.find(ticket => entryTemplateIDs.includes(ticket.template_id))) {
                    hasTickets = false;
                    break;
                  }
                }
              }
            } else {
              ticketCategoryTemplateIDs.push(templateID);
            }
          });

          ticketCategoryTemplateIDs = ticketCategoryTemplateIDs
            .sort()
            .filter((templateID, index) => templateID !== ticketCategoryTemplateIDs[index - 1]);

          ticketData?.forEach(ticket => {
            if(ticketCategoryTemplateIDs.includes(ticket.template_id)) {
              const price = ticket.prices.find(({ applies_to }) => applies_to === summaryCategory);

              if(price) {
                if(minPrice === undefined || price.price < minPrice) {
                  minPrice = price.price;
                }
              }

              hasTickets = true;
            }
          });

          const templateIDSignature = ticketCategoryTemplateIDs.join(",");
          const voucherCode = values.voucherCodes.find(voucherCode => voucherCode && (voucherData[voucherCode]?.template_ids.sort().join(",") === templateIDSignature))
          const soldOut = !hasTickets && !isTicketDataLoading && !isTicketDataValidating;

          return (
            <li
              className="ticketCategorySelection__list__item"
              key={id}
            >
              <SelectionButton
                className={classNames({
                  "ticketCategorySelection__list__item__button": true,
                  "ticketCategorySelection__list__item__button--hasVoucher": voucherCode,
                  "ticketCategorySelection__list__item__button--hasTickets": hasTickets,
                  "ticketCategorySelection__list__item__button--soldOut": soldOut,
                })}
                onClick={hasTickets
                  ? (async () => {
                    setTicketCategoryID(id);

                    if(onSelectTicketType) {
                      onSelectTicketType(label || id.toString());
                    }

                    if (id !== ticketCategoryID) {
                      setTicketEvents([]);
                      await setFieldValue("tickets", []);
                      await setFieldValue("comboTickets", []);
                    }
                  })
                  : () => {}
                }
                selected={id === ticketCategoryID}
              >
                <span className="ticketCategorySelection__list__item__label">{label}</span>
                {minPrice && hasTickets && <span className="ticketCategorySelection__list__item__minPrice">{
                  `Adults from ${format(minPrice)}`
                }</span>}
                {voucherCode && <TextContent
                  appConfiguration={appConfiguration}
                  contentKey="eligibleForVoucher"
                  defaultValue="Eligible for voucher"
                  tag="span"
                />}
                {soldOut && <TextContent
                  appConfiguration={appConfiguration}
                  contentKey="noTicketsAvailable"
                  defaultValue="No tickets available for the selected date."
                  tag="span"
                />}
              </SelectionButton>
            </li>
          )
        })}
      </ul>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="afterTicketCategorySelection"
      />
    </div>
  )
}