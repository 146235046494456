import useSWRImmutable from "swr/immutable";

import { getAuthorizedAPIDataSharedArgs } from "../fetchers/getAuthorizedAPIData";
import getAVSCountryData from "../fetchers/getAVSCountryData";

export interface useAVSCountryDataArgs extends getAuthorizedAPIDataSharedArgs {

}

export type UseAVSCountryData = ReturnType<typeof useAVSCountryData>;

export default function useAVSCountryData(args: useAVSCountryDataArgs) {
  const {
    data: countryData,
    error: countryDataError,
    isLoading: isCountryDataLoading,
    isValidating: isCountryDataValidating,
  } = useSWRImmutable({
    ...args,
    cacheKey: "avsCountryData",
  }, getAVSCountryData);

  return {
    countryData,
    countryDataError: countryDataError as unknown,
    isCountryDataLoading,
    isCountryDataValidating,
  };
}