import classNames from "classnames";

import SubmitButton from "../components/SubmitButton";
import TextContent from "../components/TextContent";

import { getNextStep, Steps } from ".";
import { StepComponentProps } from "./Step";
import LoadingIndicator from "../components/LoadingIndicator";
import getCartTotal from "../formatters/getCartTotal";
import useFormikSchemaContext from "../hooks/useFormikSchemaContext";
import getPriceFormatter from "../formatters/getPriceFormatter";
import getLocalizedText from "../formatters/getLocalizedText";
import MemberJoinButton from "./Header/MemberJoinButton";

export default function Footer(props: StepComponentProps) {
  const {
    afterFooter,
    afterSubmitButton,
    appConfiguration,
    beforeFooter,
    beforeSubmitButton,
    cartData,
    cartDataError,
    isCartDataLoading,
    isCartDataValidating,
    onSubmitButtonClick,
    showMemberJoinButton,
  } = props;

  let { submitButtonLabel } = props;

  const { values } = useFormikSchemaContext();
  
  const {
    hasDiscount,
    requiredTotal,
    total,
  } = getCartTotal(cartData);
  
  const format = getPriceFormatter(appConfiguration?.locale, true);

  if(!submitButtonLabel) {
    const nextStep = getNextStep(values.stepNumber, values.stepsEnabled);
    const stepLabel = nextStep
      ? getLocalizedText(appConfiguration, nextStep.labelKey, nextStep.defaultLabel)
      : getLocalizedText(appConfiguration, "checkout", "Checkout")

    submitButtonLabel = getLocalizedText(appConfiguration, "continueToStep", "Continue to [stepLabel]").replace("[stepLabel]", stepLabel);
  }
  
  return (
    <>
      <footer className={classNames({
        "footer": true,
        [`footer--${Steps[values.stepNumber]}`]: true,
        "footer--hasDiscount": hasDiscount,
        "footer--hasError": cartDataError,
        "footer--isLoading": isCartDataLoading,
        "footer--isValidating": isCartDataValidating,
      })}>
        {beforeFooter}
        <div className="footer__total">
          <TextContent
            appConfiguration={appConfiguration}
            className="footer__total__label"
            contentKey="yourTotal"
            defaultValue="Your total"
            tag="span"
          />
          {cartDataError
            ? (
              <TextContent
                appConfiguration={appConfiguration}
                contentKey="cartDataError"
                defaultValue="There has been a problem retrieving purchase data. Please refresh the page and try again."
              />
            )
            : (
              <span className={classNames({
                "footer__total__amount": true,
                "footer__total__amount--isLoading": isCartDataLoading || isCartDataValidating,
              })}>
                {hasDiscount && <span className="footer__total__amount__original">{format(total)}</span>}
                <span className="footer__total__amount__display">{format(requiredTotal)}</span>
                <LoadingIndicator
                  isLoading={isCartDataLoading || isCartDataValidating}
                  label={getLocalizedText(appConfiguration, "calculatingTotal", "Calculating total...")}
                />
              </span>
            )
          }
        </div>
        <div className="footer__submitButtons">
          {beforeSubmitButton}
          <SubmitButton
            disabled={!!cartDataError}
            loadingLabel={getLocalizedText(appConfiguration, "submitWaitLabel", "Please wait while data is processing...")}
            onClick={onSubmitButtonClick}
            type={onSubmitButtonClick ? "button" : "submit"}
          >{submitButtonLabel}</SubmitButton>
          {afterSubmitButton}
        </div>
        <div className="footer__extraButtons">
          {showMemberJoinButton && <MemberJoinButton {...props} />}
        </div>
        {afterFooter}
      </footer>
    </>
  );
}