import classNames from "classnames";

import useFormikSchemaContext from "../hooks/useFormikSchemaContext";
import { Schema } from "../schema";

export interface ErrorsProps {
  className?: string;
  fields: (keyof Schema)[];
  showUntouched?: boolean;
}

export default function Errors({
  className,
  fields,
  showUntouched = false,
}: ErrorsProps) {
  const { errors, touched } = useFormikSchemaContext();

  const messages: string[] = [];

  const recurseError = (error: string | string[] | object | undefined) => {
    if(!error) {
      return;
    }

    if(typeof error === "string") {
      if(error) {
        messages.push(error);
      }
    } else if("length" in error) {
      error.forEach(recurseError);
    } else {
      Object.values(error).forEach(recurseError);
    }
  };

  fields.forEach(field => {
    if(showUntouched || touched[field]) {
      recurseError(errors[field]);
    }
  });

  if(!messages.length) {
    return "";
  }

  return (
    <div className={classNames({
      "errors": true,
      [className + '']: className,
    })}>
      <ul className="errors__list">
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
}