import DateSelection from "./DateSelection";
import CategorySelection from "./CategorySelection";
import TicketSelection from "./TicketSelection";
import EventSelection from "./EventSelection";

import StepComponent, { Step, StepComponentProps } from "../Step";
import Steps from "../Steps";

const Component = (props: StepComponentProps) => {
  return (
    <StepComponent
      {...props}
      defaultHeaderLabel="Ticket Selection"
      headerLabelKey="ticketSelection"
      showSteps={false}
      showMemberJoinButton={true}
    >
      <DateSelection {...props} />
      <CategorySelection {...props} />
      <EventSelection {...props} />
      <TicketSelection {...props} />
    </StepComponent>
  )
}

const Tickets: Step = {
  Component,
  defaultLabel: "Tickets",
  isMembershipStep: false,
  isTicketStep: true,
  labelKey: "tickets",
  liveCart: true,
  stepNumber: Steps.Tickets,
}

export default Tickets;