import { useEffect } from "react";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export interface RecaptchaV3Props {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function RecaptchaV3({
  appConfiguration,
}: RecaptchaV3Props) {
  const { setFieldValue, values } = useFormikSchemaContext();

  useEffect(() => {
    (async () => {
      if(appConfiguration?.recaptcha_site_key_v3) {
        await setFieldValue("recaptchaV3Token", "");
        //await setFieldValue("recaptchaV3Valid", false);
      }
    })();
  }, [
    appConfiguration?.recaptcha_site_key_v3,
    setFieldValue
  ]);

  useEffect(() => {
    if(appConfiguration?.recaptcha_site_key_v3) {
      const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${appConfiguration.recaptcha_site_key_v3}`;
      const scriptInitialized = !!document.querySelector(`script[src="${scriptSrc}"]`);

      if(!scriptInitialized) {
        const script = document.createElement("script");
        
        script.setAttribute("src", scriptSrc);
        document.body.appendChild(script);
      }
    }
  }, [
    appConfiguration?.recaptcha_site_key_v3,
    values.recaptchaV3Token,
    values.recaptchaV3Valid
  ]);

  return "";
}