import { FocusEventHandler } from "react";

import { LoginType } from ".";
import LoginOption from "./LoginOption";

import Input from "../../components/Input";
import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export interface ResidentLoginOptionProps {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function ResidentLoginOption(props: ResidentLoginOptionProps) {
  const { setFieldValue, values } = useFormikSchemaContext();
  const { appConfiguration } = props;

  if(!appConfiguration?.allow_resident_login) {
    return "";
  }

  const onBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    if(e.target.value && values.loginType !== LoginType.Resident) {
      await setFieldValue("loginType", LoginType.Resident);
    }
  };

  return (
    <LoginOption
      appConfiguration={appConfiguration}
      defaultTitle={"Residents"}
      isResident={true}
      loginType={LoginType.Resident}
    >
      <Input
        placeholder={getLocalizedText(appConfiguration, "residentZIPCodePlaceholder", "Enter your ZIP code")}
        name="residentZIPCode"
        onBlur={onBlur}
      />
    </LoginOption>
  );
}