import RecaptchaV2 from "./RecaptchaV2";
import RecaptchaV3 from "./RecaptchaV3";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";

export interface RecaptchaProps {
  appConfiguration: AppConfiguration | null | undefined;
}

const RecaptchaComponent = ({
  appConfiguration
}: RecaptchaProps) => {
  return (
    <>
      <RecaptchaV2 appConfiguration={appConfiguration} />
      <RecaptchaV3 appConfiguration={appConfiguration} />
    </>
  )
};

export default RecaptchaComponent;
