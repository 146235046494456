import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";
import { Ticket } from "./getTicketData";

export interface updateSoldArgs extends getAuthorizedAPIDataSharedArgs {
  sold: number;
  ticketID: string;
}

export default async function updateCart({
  apiURL,
  authorizationToken,
  sold,
  ticketID,
}: updateSoldArgs): APIResponse<Ticket> {
  const endPoint = `api/tickets/${ticketID}/sold`;
  const method = "POST";

  const data = {
    id: ticketID,
    sold,
  };
  
  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
    method,
  });
}