import getAuthorizedAPIData, { APIResponse, ERROR_NOT_FOUND, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface Voucher {
  id: number,
  item: string;
  template_ids: string[],
  barcode: string;
  override_price: boolean;
  override_price_amount: string | number;
  expiry_date: string;
  mip: string | null;
  description: string | null;
  acme_ticket_category: string | null;
  acme_discount_code: string | null;
  acme_order_number: string | null;
  redemption_date: string | null;
  visit_date: string | null;
  is_expired: boolean;
  is_redeemed: boolean;
  is_valid: boolean;
}

export interface getVoucherDataMainArgs {
  voucherCode: string;
}

export type getVoucherDataArgs = getAuthorizedAPIDataSharedArgs & getVoucherDataMainArgs;

export default async function getVoucherData({
  apiURL,
  authorizationToken,
  voucherCode,
}: getVoucherDataArgs): APIResponse<Voucher> {
  if(!voucherCode) {
    return null;
  }

  const endPoint = "api/vouchers/check";

  const data = {
    voucher_code: voucherCode,
  };

  try {
    const responseData = await getAuthorizedAPIData<Voucher>({
      apiURL,
      authorizationToken,
      data,
      endPoint,
      method: "POST",
    });

    return responseData;
  } catch(error) {
    if((error instanceof Error) && error.message === ERROR_NOT_FOUND) {
      const invalidVoucher: Voucher = {
        id: 0,
        template_ids: [],
        barcode: voucherCode,
        item: "",
        override_price: false,
        override_price_amount: 0,
        expiry_date: "",
        mip: "",
        description: "Invalid Voucher",
        acme_discount_code: null,
        acme_ticket_category: null,
        acme_order_number: null,
        redemption_date: null,
        visit_date: null,
        is_expired: false,
        is_redeemed: false,
        is_valid: false,
      };

      return invalidVoucher;
    } else {
      throw new Error(typeof error === "string" ? error : undefined);
    }
  }
}