import classNames from "classnames";
import { PropsWithChildren } from "react";
import TextContent from "../../components/TextContent";
import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import getPriceFormatter from "../../formatters/getPriceFormatter";

export type SectionProps = PropsWithChildren<{
  appConfiguration: AppConfiguration | null | undefined;
  defaultHeaderLabel: string;
  price?: string;
  slug: string;
}>;

export default function Section({
  appConfiguration,
  children,
  defaultHeaderLabel,
  price,
  slug,
}: SectionProps) {

  return (
    <div className={classNames({
      "membershipReview__section": true,
      [`membershipReview__section--${slug}`]: slug,
    })}>
      <div className="membershipReview__section__header">
        <TextContent
          appConfiguration={appConfiguration}
          contentKey={`membershipReviewSection${slug}`}
          defaultValue={defaultHeaderLabel}
          tag="h3"
        />
        {price && <span className="membershipReview__section__header__price">
          {getPriceFormatter(appConfiguration?.locale, true)(price)}  
        </span>}
      </div>
      <div className="membershipReview__section__content">{children}</div>
    </div>
  );
}