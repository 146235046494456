import zipcodes from "zipcodes";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";

export function mapLocationToZipCodes(location: string) {
  if(!location) {
    return [];
  }

  location = location.trim();

  if(/^[0-9-]+$/.test(location)) {
    return [zipcodes.lookup(location)].filter(zipCodes => !!zipCodes);
  } else if(/^[0-9.-]+\s*,\s*[0-9.-]+$/.test(location)) {
    const [lat, long] = location.split(/^[0-9.-]+\s*,\s*[0-9.-]+$/).map(parseFloat);

    return [zipcodes.lookupByCoords(lat, long)].filter(zipCode => !!zipCode);
  } else if(/^.*,\s*[A-Z]{2}$/.test(location)) {
    const [city, state] = location.split(/,\s*[A-Z]{2}$/);

    return zipcodes.lookupByName(city, state);
  } else {
    return zipcodes.lookupByState(location);
  }
}

export default function checkResidentLocation(appConfiguration: AppConfiguration | null | undefined, location: string) {
  if(!appConfiguration) {
    return false;
  }

  const residentLocations = appConfiguration.resident_locations;

  if(!residentLocations?.length) {
    return false;
  }

  const residentZipCodes = residentLocations
    .flatMap(location => mapLocationToZipCodes(location))
    .map(zipCode => zipCode?.zip);

  const locationZipCode = mapLocationToZipCodes(location)[0]?.zip || "";

  return residentZipCodes.includes(locationZipCode);
}