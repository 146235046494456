export const ERROR_GENERAL = "Could not load data from API.";
export const ERROR_NOT_FOUND = "API data not found.";

export interface getAuthorizedAPIDataSharedArgs {
  apiURL: string;
  authorizationToken?: string;
  cacheKey?: string;
  signal?: AbortSignal;
}

export interface getAuthorizedAPIDataArgs extends getAuthorizedAPIDataSharedArgs {
  data?: Record<string, unknown>;
  endPoint: string;
  method?: "DELETE" | "GET" | "POST" | "PUT";
};

export type APIResponse<U> = Promise<U | null>;

export default async function getAuthorizedAPIData<U>({
  apiURL,
  authorizationToken,
  data,
  endPoint,
  method = "GET",
  signal,
}: getAuthorizedAPIDataArgs): APIResponse<U> {
  if(!authorizationToken) {
    return null;
  }

  if(data) {
    for(const key in data) {
      if(data[key] === undefined || data[key] === null) {
        delete data[key];
      }
    }
  }

  const cleanData: Record<string, string> | undefined = data ? data as Record<string, string> : undefined;

  if(method === "GET" && data) {
    const query = new URLSearchParams(cleanData).toString();

    if(query) {
      if(!endPoint.includes("?")) {
        endPoint += "?";
      }

      endPoint += query;
    }
  }

  const response = await fetch(`${apiURL}/${endPoint}`, {
    method,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authorizationToken}`,
    },
    body: method !== "GET" && data ? JSON.stringify(cleanData) : undefined,
    signal,
  });

  if (!response.ok) {
    switch(response.status) {
      case 404: throw new Error(ERROR_NOT_FOUND);
      default: throw new Error(ERROR_GENERAL);
    }
  }

  let responseData = await response.json();

  if(responseData.data) {
    responseData = responseData.data;
  }
  
  return responseData;
}