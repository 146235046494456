import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface Address {
  streetAddress1?: string;
  phoneNumber?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

export type CardType = "primary" | "secondary";
export type CardStatus = "new" | "active" | "dropped" | "lapsed" | "noMembership" | "replaced";
export type Standing = "active" | "lapsed";

export interface CardHolder {
  auxiliary: boolean;
  acmeBarcode: string;
  acmeCustomerNumber: string;
  acmeMembershipNumber: string;
  barcode?: string;
  cardExpirationDate: string;
  cardStanding: Standing;
  cardType: CardType;
  cardStartDate: string;
  category?: string;
  categoryId: string;
  city?: string;
  constituentImportId?: string;
  country?: string;
  customerId: number;
  email?: string;
  externalMembershipId: string;
  firstName?: string;
  id: number;
  lastName?: string;
  membershipCategory: string;
  membershipExpirationDate: string;
  membershipId: number;
  membershipJoinedDate: string;
  membershipStanding: Standing;
  membershipStartDate: string;
  membershipTypeId: number;
  middleName: string;
  name?: string;
  offeringId: string;
  phoneNumber?: string;
  primaryCard: boolean;
  program?: string;
  state?: string;
  streetAddress1?: string;
  visitorId: number;
  zipCode?: string;
}

export interface MemberData {
  address: Address;
  primaryCardHolder: CardHolder | null;
  secondaryCardHolders: CardHolder[];
}

export interface getMemberDataMainArgs {
  lastName: string | null;
  memberId: string | null;
}

export type getMemberDataArgs = getAuthorizedAPIDataSharedArgs & getMemberDataMainArgs;

export default async function getMemberData({
  apiURL,
  authorizationToken,
  lastName,
  memberId,
}: getMemberDataArgs): APIResponse<MemberData> {
  if(!lastName || !memberId) {
    return null;
  }

  const endPoint = "api/membership/check";

  const data = {
    lastName,
    memberId,
  };

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
    method: "POST",
  });
}