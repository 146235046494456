import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface TicketPrice {
  applies_to: string;
  applies_to_id: string;
  discounted_price: number;
  id: number;
  price: number;
}

export interface Ticket {
  display_order: number;
  end_time: string;
  event_id: string;
  id: number;
  name: string;
  organization_id: number;
  prices: TicketPrice[];
  seats_available: number;
  seats_capacity: number;
  seats_sold: number;
  start_time: string;
  template_id: string;
}

export interface getTicketDataArgs extends getAuthorizedAPIDataSharedArgs {
  date: string | null;
  membershipID?: string | null;
  templateIDs?: string[] | null;
}

export type TicketData = Ticket[];

export default async function getTicketData({
  apiURL,
  authorizationToken,
  date,
  membershipID,
  templateIDs,
}: getTicketDataArgs): APIResponse<TicketData> {
  if(!date || !templateIDs?.length) {
    return null;
  }

  const endPoint = "api/tickets";

  const data = {
    date,
    membership_id: membershipID,
    template_ids: templateIDs.join(","),
  };

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
  });
}