import { StepComponentProps } from "../steps/Step";

import TextContent from "./TextContent";
import useFormikSchemaContext from "../hooks/useFormikSchemaContext";
import getPriceFormatter from "../formatters/getPriceFormatter";

export interface AddOnsSummaryProps extends StepComponentProps {
  showContent?: boolean;
  showHeader?: boolean;
  showIfBlank?: boolean;
}

export default function AddOnsSummary({
  cartData,
  showContent = true,
  showHeader = true,
  showIfBlank = true,
  ...props
}: AddOnsSummaryProps) {
  const { values } = useFormikSchemaContext();
  const { appConfiguration } = props;
  const donationTypes = ["donation", "fixedDonation"];
  const addons = props?.appConfiguration?.addons.filter(({ type }) => !donationTypes.includes(type))

  const addOnSummaries = addons
    ?.map(addon => {
      const cartItem = cartData?.items?.find(cartItem => cartItem.inventoryId === addon.addon_id);
      const valueItem = values.addOns.find(a => a.inventoryId === addon.addon_id);
      let price = cartItem?.unitPrice || addon.price;
      const quantity = cartItem?.quantity || valueItem?.quantity || 0;

      if(typeof(price) === "string") {
        price = parseFloat(price);
      }

      let subtotal = cartItem?.amount || (price * quantity);

      if(typeof(subtotal) === "string") {
        subtotal = parseFloat(subtotal);
      }

      return {
        inventoryId: addon.addon_id,
        label: addon.name,
        price,
        quantity,
        subtotal,
      }
    })
    .filter(addon => addon.quantity);

  if(!addOnSummaries?.length && !showIfBlank) {
    return "";
  }

  const format = getPriceFormatter(appConfiguration?.locale, true);

  return (
    <div className="addOnsSummary">
      {showHeader && <TextContent
        appConfiguration={appConfiguration}
        contentKey="addOnsSummaryHeader"
        defaultValue="Add-Ons"
        tag="h3"
      />}
      <TextContent contentKey="beforeAddOnSummary" />
      {addOnSummaries?.length
        ? (
          <ul className="addOnsSummary__list">
            {addOnSummaries.map(({
              inventoryId,
              label,
              price,
              quantity,
              subtotal,
            }) => (
              <li
                className="addOnSummary"
                key={inventoryId}
              >
                <span className="addOnSummary__label">{label}</span>
                <span className="addOnSummary__separator addOnSummary__separator--dash"> &mdash; </span>
                {quantity && price && <>
                  <span className="addOnSummary__quantity">{quantity}</span>
                  <span className="addOnSummary__separator addOnSummary__separator--times"> &times; </span>
                </>}
                <span className="addOnSummary__price">{format(price)}</span>
                <span className="addOnSummary__subtotal">{format(subtotal)}</span>
              </li>
            ))}
          </ul>
        ) : (
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="noAddOnsSelectedMessage"
            defaultValue="No add-ons selected."
          />
        )
      }
      {showContent && <TextContent contentKey="afterAddOnSummary" />}
    </div>
  );
}