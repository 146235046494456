import { StepComponentProps } from "../Step";

import Input from "../../components/Input";
import TextContent from "../../components/TextContent";
import getLocalizedText from "../../formatters/getLocalizedText";

export default function ContactForm({ appConfiguration }: StepComponentProps) {
  return (
    <>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="beforeContactForm"
        defaultValue="Payment not needed. Please enter your contact information to receive confirmation notification."
      />
      <fieldset className="contactSection">
        <TextContent
          appConfiguration={appConfiguration}
          contentKey="contactSectionHeader"
          defaultValue="Contact Information"
          tag="legend"
        />
        <Input
          label={getLocalizedText(appConfiguration, "firstNameLabel", "First Name")}
          name="firstName"
        />
        <Input
          label={getLocalizedText(appConfiguration, "lastNameLabel", "Last Name")}
          name="lastName"
        />
        <Input
          label={getLocalizedText(appConfiguration, "phoneNumberLabel", "Phone Number")}
          name="phoneNumber"
          type="tel"
        />
        <Input
          label={getLocalizedText(appConfiguration, "emailLabel", "Email")}
          name="email"
          type="email"
        />
      </fieldset>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="afterContactForm"
      />
    </>
  );
}