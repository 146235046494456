import useSWRImmutable from "swr/immutable";

import getMembershipRestrictions, { getMembershipRestrictionDataArgs } from "../fetchers/getMembershipRestrictionData";

export interface useMembershipRestrictionsArgs extends getMembershipRestrictionDataArgs {
  
}

export type UseMembershipRestrictions = ReturnType<typeof useMembershipRestrictions>;

export default function useMembershipRestrictions(props: useMembershipRestrictionsArgs) {
  const { data, error, isLoading, isValidating } = useSWRImmutable({
    ...props,
    cacheKey: "membershipRestrictions",
  }, getMembershipRestrictions);

  return {
    membershipRestrictionData: data,
    membershipRestrictionDataError: error,
    isMembershipRestrictionDataLoading: isLoading,
    isMembershipRestrictionDataValidating: isValidating,
  };
}