import { useEffect } from "react";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export interface RecaptchaV3Props {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function RecaptchaV3({
  appConfiguration,
}: RecaptchaV3Props) {
  const { setFieldError, setFieldValue, values } = useFormikSchemaContext();

  useEffect(() => {
    (async () => {
      if(appConfiguration?.recaptcha_site_key_v3) {
        await setFieldValue("recaptchaV3Token", "");
        await setFieldValue("recaptchaV3Valid", false);
      }
    })();
  }, [
    appConfiguration?.recaptcha_site_key_v3,
    setFieldValue
  ]);

  useEffect(() => {
    let grecaptchaTimeout: NodeJS.Timeout | null = null;

    const initializeGrecaptcha = () => {
      if(!appConfiguration?.recaptcha_site_key_v3) {
        return;
      }

      if(window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(appConfiguration.recaptcha_site_key_v3!, {
            action: "checkout"
          }).then(async (response: string) => {
            await setFieldValue("recaptchaV3Token", response);

            if(response) {
              await setFieldValue("recaptchaV3Valid", true);
              await setFieldError("recaptchaV3Valid", undefined);
            } else {
              await setFieldValue("recaptchaV3Valid", false);
              await setFieldError("recaptchaV3Valid", "Invalid RECAPTCHA V3.");
            }
          });
        });
      } else {
        grecaptchaTimeout = setTimeout(initializeGrecaptcha, 500);
      }
    };

    if(appConfiguration?.recaptcha_site_key_v3) {
      const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${appConfiguration.recaptcha_site_key_v3}`;
      const scriptInitialized = !!document.querySelector(`script[src="${scriptSrc}"]`);

      if(!scriptInitialized) {
        const script = document.createElement("script");
        
        script.setAttribute("src", scriptSrc);
        document.body.appendChild(script);
      }

      if(!values.recaptchaV3Token || !values.recaptchaV3Valid) {
        initializeGrecaptcha();
      }
    }

    return () => {
      if(grecaptchaTimeout) {
        clearTimeout(grecaptchaTimeout);
      }
    }
  }, [
    appConfiguration?.recaptcha_site_key_v3,
    setFieldError,
    setFieldValue,
    values.recaptchaV3Token,
    values.recaptchaV3Valid
  ]);

  return "";
}