import { useCallback, useEffect, useRef, useReducer } from "react";

import getDailyTicketData, { DailyTicketData } from "../fetchers/getDailyTicketData";
import formatSQLDate from "../formatters/formatSQLDate";

export interface useDailyTicketDataArgs {
  apiURL: string;
  authorizationToken?: string;
  days?: number;
  endDate?: Date | null;
  membershipID?: string | null | undefined;
  startDate?: Date | null;
  summaryCategory?: string | null;
  templateIDs?: string[] | null;
}

interface useDailyTicketDataState {
  data: DailyTicketData | null;
  done: boolean;
  error: unknown;
  isLoading: boolean;
  isValidating: boolean;
  lastDate: Date | null;
  membershipID: string | null;
  summaryCategory: string | null | undefined;
}

type useDailyTicketDataAction = Partial<useDailyTicketDataState>;

export type UseDailyTicketData = ReturnType<typeof useDailyTicketData>;

const reducer = (state: useDailyTicketDataState, action: useDailyTicketDataAction) => {
  return {
    ...state,
    ...action,
  };
};

const getInitialState = () => {
  return {
    data: null,
    done: false,
    error: null,
    isLoading: false,
    isValidating: false,
    lastDate: null,
    summaryCategory: null,
  } as useDailyTicketDataState;
}

export default function useDailyTicketData({
  apiURL,
  authorizationToken,
  endDate,
  startDate,
  days,
  templateIDs,
}: useDailyTicketDataArgs) {
  const abortControllerRef = useRef<AbortController | null>(null);
  const queuedReads = useRef<string[]>([]);
  const [state, dispatch] = useReducer(reducer, null, getInitialState);

  const setSummaryCategory = useCallback((summaryCategory: string | null | undefined) => {
    if(summaryCategory !== state.summaryCategory) {
      abortControllerRef.current?.abort();
      queuedReads.current = [];

      dispatch({
        ...state,
        data: null,
        isLoading: false,
        isValidating: false,
        lastDate: null,
        summaryCategory
      });
    }
  }, [state]);

  const setMembershipID = useCallback((membershipID: string | null | undefined) => {
    if(membershipID !== state.membershipID) {
      abortControllerRef.current?.abort();
      queuedReads.current = [];

      dispatch({
        ...state,
        data: null,
        isLoading: false,
        isValidating: false,
        lastDate: null,
        membershipID,
      });
    }
  }, [state]);

  const readFrom = useCallback(async (readDate: Date, readDays: number = 0, ignoreDone: boolean = false) => {
    if(!readDays) {
      readDays = days || 7;
    }

    if(
      authorizationToken
      && readDays
      && !state.isLoading
      && !state.isValidating
      && state.summaryCategory
      && startDate
      && templateIDs?.length
      && (ignoreDone || !state.done)
      && !queuedReads.current.includes(formatSQLDate(readDate))
    ) {
      try {
        queuedReads.current.push(formatSQLDate(readDate));

        dispatch({
          error: null,
          isLoading: true,
          isValidating: true,
          lastDate: readDate,
        });

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        const signal = abortControllerRef.current.signal;
  
        const response = await getDailyTicketData({
          apiURL,
          authorizationToken,
          date: readDate,
          days: readDays,
          membershipID: state.membershipID,
          summaryCategory: state.summaryCategory,
          signal,
          templateIDs,
        });

        if(response) {
          let newData: DailyTicketData | null;

          if(state.data?.length) {
            const newTicketDates = response.map(({ ticket_date }) => ticket_date);

            newData = state.data
              .filter(({ ticket_date }) => !newTicketDates.includes(ticket_date))
              .concat(response)
              .sort((a, b) => new Date(a.ticket_date).getTime() - new Date(b.ticket_date).getTime())
          } else {
            newData = response;
          }

          dispatch({
            data: newData,
            done: endDate ? readDate >= endDate : !response.length,
            isLoading: false,
            isValidating: false,
            lastDate: readDate,
          });
        }
      } catch (error) {
        dispatch({
          error: error instanceof Error ? error.message : "Could not load daily ticket data.",
          isLoading: false,
          isValidating: false,
        });
      }
    }
  }, [
    apiURL,
    authorizationToken,
    days,
    endDate,
    startDate,
    state.data,
    state.done,
    state.isLoading,
    state.isValidating,
    state.membershipID,
    state.summaryCategory,
    templateIDs
  ]);

  const readNext = useCallback(async () => {
    let nextReadDate: Date | null = null;

    if(state.lastDate) {
      nextReadDate = new Date();
      nextReadDate.setDate(state.lastDate.getDate() + (days || 7));
    } else if(startDate) {
      nextReadDate = new Date(startDate);
    } else if(!state.data?.length) {
      nextReadDate = new Date();
    }

    if(state.data?.length && nextReadDate) {
      let formattedReadDate = formatSQLDate(nextReadDate);

      while(state.data?.find(day => formatSQLDate(day.ticket_date) === formattedReadDate)) {
        nextReadDate.setDate(nextReadDate.getDate() + 1);
        formattedReadDate = formatSQLDate(nextReadDate);
      }
    }

    if(nextReadDate) {
      return readFrom(nextReadDate, days || 7);
    }
  }, [
    days,
    readFrom,
    startDate,
    state.data,
    state.lastDate,
  ]);

  useEffect(() => {
    (async () => {
      if(!state.data && !state.error && !state.isLoading && !state.isValidating) {
        await readFrom(startDate ?? new Date());
      }
    })();
  }, [
    readFrom,
    startDate,
    state.data,
    state.error,
    state.isLoading,
    state.isValidating,
    state.summaryCategory,
  ]);

  return {
    dailyTicketData: state.data,
    dailyTicketDataError: state.error,
    isDailyTicketDataLoading: state.isLoading,
    isDailyTicketDataValidating: state.isValidating,
    readFrom,
    readNext,
    setMembershipID,
    setSummaryCategory,
    summaryCategory: state.summaryCategory,
  };
}