import { StepComponentProps } from "../Step";

import Input from "../../components/Input";
import TextContent from "../../components/TextContent";
import getLocalizedText from "../../formatters/getLocalizedText";
import { ReactNode } from "react";
import StateInput from "../../components/StateInput";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import CountryInput from "../../components/CountryInput";

import PromoCodeMessage from "./PromoCodeMessage";

export default function PaymentForm(props: StepComponentProps) {
  const {
    appConfiguration,
    countryData,
    countryDataError,
    isCountryDataLoading,
    isCountryDataValidating,
    isStateDataLoading,
    isStateDataValidating,
    stateData,
    stateDataError,
  } = props;

  const { values } = useFormikSchemaContext();

  const stateCountry = values.country
    && countryData
    ?.find(country => country.name === values.country)
    ?.alpha_2_code;

  return (
    <>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="beforePaymentForm"
      />
      <PromoCodeMessage {...props} />
      <fieldset className="cardInfoSection">
        <TextContent
          appConfiguration={appConfiguration}
          contentKey="cardInfoSectionHeader"
          defaultValue="Credit / Debit Card Info"
          tag="legend"
        />
        <Input
          label={getLocalizedText(appConfiguration, "firstNameLabel", "First Name")}
          name="firstName"
        />
        <Input
          label={getLocalizedText(appConfiguration, "lastNameLabel", "Last Name")}
          name="lastName"
        />
        <Input
          label={getLocalizedText(appConfiguration, "cardNumberLabel", "Card Number")}
          name="cardNumber"
          pattern="[0-9\-]+"
        />
        <Input
          label={getLocalizedText(appConfiguration, "securityCodeLabel", "Security Code")}
          name="securityCode"
          pattern="[0-9]{3,6}"
        />
        <Input
          as="select"
          label={getLocalizedText(appConfiguration, "expirationMonthLabel", "Expiration Month")}
          name="expirationMonth"
        >
          <option value=""></option>
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </Input>
        <Input
          as="select"
          label={getLocalizedText(appConfiguration, "expirationYearLabel", "Expiration Year")}
          name="expirationYear"
        >
          <option value=""></option>
          {(() => {
            const yearOptions: ReactNode[] = [];
            const startYear = (new Date()).getFullYear();
            const endYear = startYear + 10;
            
            for(let year = startYear; year <= endYear; year++) {
              yearOptions.push((
                <option
                  key={year}
                  value={year.toString()}
                >{year.toString()}</option>
              ));
            }

            return yearOptions;
          })()}
        </Input>
      </fieldset>
      <fieldset className="billingAddressSection">
        <TextContent
          appConfiguration={appConfiguration}
          contentKey="billingAddressSectionHeader"
          defaultValue="Billing Address"
          tag="legend"
        />
        <Input
          label={getLocalizedText(appConfiguration, "streetAddressLabel", "Street Address")}
          name="streetAddress1"
        />
        <Input
          label={getLocalizedText(appConfiguration, "streetAddress2Label", "Street Address 2 (optional)")}
          name="streetAddress2"
        />
        <Input
          label={getLocalizedText(appConfiguration, "cityLabel", "City")}
          name="city"
        />
        <StateInput
          country={stateCountry || undefined}
          isStateDataLoading={isStateDataLoading}
          isStateDataValidating={isStateDataValidating}
          label={getLocalizedText(appConfiguration, "stateLabel", "State/Province")}
          name="state"
          stateData={stateData}
          stateDataError={stateDataError}
        />
        <Input
          label={getLocalizedText(appConfiguration, "zipLabel", "ZIP/Postal Code")}
          name="zip"
        />
        <CountryInput
          countryData={countryData}
          countryDataError={countryDataError}
          isCountryDataLoading={isCountryDataLoading}
          isCountryDataValidating={isCountryDataValidating}
          label={getLocalizedText(appConfiguration, "countryLabel", "Country")}
          name="country"
        />
        <Input
          label={getLocalizedText(appConfiguration, "phoneNumberLabel", "Phone Number")}
          name="phoneNumber"
          pattern="[0-9\(\)\-\.ext ]+"
          type="tel"
        />
        <Input
          label={getLocalizedText(appConfiguration, "emailLabel", "Email Address")}
          name="email"
          type="email"
        />
      </fieldset>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="afterPaymentForm"
      />
    </>
  );
}