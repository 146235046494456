export default function formatButtonDate(date: Date, locale: string = "en-US") {
  if(!locale) {
    locale = "en-US";
  }

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
    weekday: "short",
    year: "numeric",
  });

  return dateFormatter.format(date);
}