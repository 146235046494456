import { useEffect, useState } from "react";
import Calendar from "react-calendar";

import { StepComponentProps } from "../Step";

import { ButtonModal } from "../../components/Modal";
import getLocalizedDate from "../../formatters/getLocalizedDate";
import getLocalizedText from "../../formatters/getLocalizedText";
import formatButtonDate from "../../formatters/formatButtonDate"
import formatSQLDate from "../../formatters/formatSQLDate";

export default function ScheduleAheadButton({
  appConfiguration,
  dailyTicketData,
  readFrom,
  setDateCarouselQueuedIndex,
  setTicketDate,
  setTicketEvents,
  ticketDate,
}: StepComponentProps) {
  const [queuedDate, setQueuedDate] = useState<Date | null>(null);

  useEffect(() => {
    if(queuedDate && dailyTicketData?.length) {
      const formattedQueuedDate = formatSQLDate(queuedDate);
      const index = dailyTicketData?.findIndex(day => day.ticket_date === formattedQueuedDate);

      if(~index) {
        setDateCarouselQueuedIndex(index);
        setTicketDate(formattedQueuedDate);
        setTicketEvents([]);
        setQueuedDate(null);
      }
    }
  }, [
    dailyTicketData, 
    queuedDate, 
    setDateCarouselQueuedIndex, 
    setTicketDate, 
    setTicketEvents
  ]);

  const setOffsetDate = (offset: number) => {
    const newDate = getLocalizedDate(ticketDate || new Date(), "");

    newDate.setDate(newDate.getDate() + offset);
    setQueuedDate(newDate);
    readFrom(newDate, 7, true);
  };

  return (
    <div className="scheduleAhead">
      <button
        className="scheduleAheadNavButton scheduleAheadNavButton--previous"
        disabled={!ticketDate || getLocalizedDate(ticketDate, "") <= new Date()}
        onClick={() => setOffsetDate(-1)}
        title={getLocalizedText(appConfiguration, "previous", "Previous")}
        type="button"
      >
        ←
      </button>

      <ButtonModal
        className="scheduleAheadButton"
        render={({ close }) => (
          <>
            <Calendar
              onChange={(selectedDate) => {
                if(selectedDate && selectedDate instanceof Date) {
                  close();
                  setQueuedDate(selectedDate);
                  readFrom(selectedDate, 7, true);
                }
              }}
              locale={appConfiguration?.locale}
              minDate={appConfiguration?.start_date ? new Date(appConfiguration.start_date) : new Date()}
              value={ticketDate ? new Date(ticketDate) : undefined}
            />
            <button
              onClick={close}
            >Close</button>
          </>
        )}
      >
        <span className="scheduleAheadButton__date">{formatButtonDate((getLocalizedDate(ticketDate || new Date, "")))}</span>
      </ButtonModal>

      <button
        className="scheduleAheadNavButton scheduleAheadNavButton--previous"
        onClick={() => setOffsetDate(1)}
        title={getLocalizedText(appConfiguration, "next", "Next")}
        type="button"
      >
        →
      </button>
    </div>
  );
}