import Input, { InputProps } from "./Input";
import { AVSStateData } from "../fetchers/getAVSStateData";

export type StateInputProps = Omit<InputProps, "as"> & {
  country?: string;
  stateData: AVSStateData | null | undefined;
  stateDataError?: unknown;
  isStateDataLoading?: boolean;
  isStateDataValidating?: boolean;
};

export default function CountryInput({
  country,
  stateData,
  stateDataError,
  isStateDataLoading,
  isStateDataValidating,
  ...props
}: StateInputProps) {
  const extraClasses: string[] = [];

  if(stateDataError) {
    extraClasses.push("hasError");
  }

  if(isStateDataLoading) {
    extraClasses.push("isLoading");
  }

  if(isStateDataValidating) {
    extraClasses.push("isValidating");
  }

  if(extraClasses.length) {
    if(props.className) {
      props.className += " " + extraClasses.join(" ");
    } else {
      props.className = extraClasses.join(" ");
    }
  }

  return (
    <Input
      {...props}
      as={stateData ? "select" : "input"}
    >
      {stateData && (
        <>
          <option value=""></option>
          {stateData
            .filter(state => !country || state.country === country)
            .map(state => (
              <option
                key={state.name}
                value={state.name}
              >{state.name}</option>
            )
          )}
        </>
      )}
    </Input>
  )
}