import classNames from "classnames";
import { PropsWithChildren } from "react";

import LoadingIndicator from "./LoadingIndicator";
import { AppConfiguration } from "../fetchers/getAppConfiguration";
import getLocalizedText from "../formatters/getLocalizedText";

export type LoaderContainerProps = PropsWithChildren<{
  appConfiguration?: AppConfiguration | null;
  error: unknown;
  isLoading: boolean;
  isValidating: boolean;
  errorMessage?: string;
  loadingMessage?: string;
  render?: () => React.ReactNode;
  renderWhileLoading?: boolean;
  renderWhileValidating?: boolean;
  showLoadingMessage?: boolean;
  showLoadingIndicator?: boolean;
}>;

export default function LoaderContainer({
  appConfiguration,
  children,
  error,
  errorMessage,
  isLoading,
  isValidating,
  loadingMessage,
  render,
  renderWhileLoading = false,
  renderWhileValidating = false,
  showLoadingIndicator = true,
  showLoadingMessage = true,
}: LoaderContainerProps) {
  if(!errorMessage) {
    errorMessage = getLocalizedText(appConfiguration, "defaultLoadingErrorMessage", "There was problem loading the content. Please refresh the page and try again.");
  }

  if(!loadingMessage) {
    loadingMessage = getLocalizedText(appConfiguration, "defaultLoadingMessage", "Loading...");
  }

  const loaded = (!isLoading && !isValidating && !error);

  return (
    <div className={classNames({
      "loaderContainer": true,
      "loaderContainer--loaded": loaded,
      "loaderContainer--loading": isLoading,
      "loaderContainer--validating": isValidating,
      "loaderContainer--error": error,
    })}>
      {error ? <p className="loaderContainer__message">{errorMessage}</p> : ""}
      {(isLoading || isValidating) ? <>
        {showLoadingMessage && <p className="loaderContainer__message">{loadingMessage}</p>}
        {showLoadingIndicator && <LoadingIndicator isLoading={true} label={loadingMessage} />}
      </> : ""}
      {(loaded || (isLoading && renderWhileLoading) || (isValidating && renderWhileValidating))
        ? (render ? render() : children)
        : ""
      }
    </div>
  );
}