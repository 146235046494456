import { StepComponentProps } from "../Step";
import SubmitButton from "../../components/SubmitButton";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getLocalizedText from "../../formatters/getLocalizedText";

export interface SkipButtonProps extends StepComponentProps {

}

export default function SkipButton({
  appConfiguration
}: SkipButtonProps) {
  const { setFieldValue } = useFormikSchemaContext();

  return (
    <SubmitButton
      ignoreInvalid={true}
      loadingLabel={""}
      onClick={async () => {
        await setFieldValue("donationID", "");
        await setFieldValue("donorRecognitionType", "self");
      }}
    >
      {getLocalizedText(appConfiguration, "donationSkipButtonLabel", "Skip")}
    </SubmitButton>
  );
}