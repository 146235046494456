import classNames from "classnames";
import { Children, PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import CarouselNavButton from "./CarouselNavButton";
import getChildrenToShow,{ CarouselBreakpoint } from "./getChildrenToShow";

export type CarouselProps = PropsWithChildren<{
  breakpoints?: CarouselBreakpoint[];
  id: string;
  initialIndex?: number;
  onScrollToEnd?: () => void;
}>;

export const defaultBreakpoints = [{
  max: 480,
  items: 1,
},{
  max: 768,
  items: 3,
},{
  max: 1280,
  items: 5,
},{
  items: 7,
}];

export default function Carousel({
  breakpoints = defaultBreakpoints,
  children,
  id,
  initialIndex = 0,
  onScrollToEnd,
}: CarouselProps) {
  const listRef = useRef<HTMLOListElement>(null);
  const listItemRefs = useRef<HTMLLIElement[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [childrenToShow, setChildrenToShow] = useState(getChildrenToShow(breakpoints));
  const [index, setIndex] = useState(initialIndex);

  const childrenCount = Children.count(children);

  const indexIsAtEnd = useCallback(
    (testIndex: number) => testIndex >= childrenCount - childrenToShow,
    [childrenCount, childrenToShow]
  );
  
  const triggerOnScrollToEnd = useMemo(() => onScrollToEnd
    ? (testIndex: number) => {
      indexIsAtEnd(testIndex) && onScrollToEnd();
    }
    : () => {},
  [indexIsAtEnd, onScrollToEnd]);

  const previousDisabled = index <= 0;
  const nextDisabled = indexIsAtEnd(index);

  const scrollToIndex = useCallback((newIndex: number) => {
    setIndex(newIndex);
    triggerOnScrollToEnd(newIndex);

    if(!wrapperRef.current) {
      return;
    }
  
    wrapperRef.current.scrollTo({
      left: listItemRefs.current[newIndex].offsetLeft - (wrapperRef.current?.offsetLeft ?? 0),
      behavior: "smooth",
    });
  }, [triggerOnScrollToEnd]);

  useLayoutEffect(() => {
    const updateChildrenToShow = () => {
      setChildrenToShow(getChildrenToShow(breakpoints));
    };

    window.addEventListener("resize", updateChildrenToShow);

    return () => {
      window.removeEventListener("resize", updateChildrenToShow);
    }
  }, [breakpoints]);

  useEffect(() => {
    const wrapper = wrapperRef?.current;
    let closestIndex: number;

    if(!wrapper) {
      return;
    }

    let updateIndexInterval: NodeJS.Timeout | null = null;
    let finishScrollTimeout: NodeJS.Timeout | null = null;
    
    const updateIndexToNearest = () => {
      const wrapperScroll = wrapper.scrollLeft;
      let closestDistance = Infinity;

      closestIndex = 0;

      for(let listItemIndex = 0; listItemIndex < childrenCount; listItemIndex++) {
        const distance = Math.abs(listItemRefs.current[listItemIndex].offsetLeft - wrapperScroll);

        if(distance < closestDistance) {
          closestIndex = listItemIndex;
          closestDistance = distance;
        }
      }

      setIndex(closestIndex);
      triggerOnScrollToEnd(closestIndex);
    }

    const finishScrollUpdates = () => {
      if(finishScrollTimeout) {
        clearTimeout(finishScrollTimeout);
      }

      if(updateIndexInterval) {
        clearInterval(updateIndexInterval);
      }

      finishScrollTimeout = null;
      updateIndexInterval = null;

      scrollToIndex(closestIndex);
    };

    const refreshScrollUpdates = () => {
      if(finishScrollTimeout) {
        clearTimeout(finishScrollTimeout);
      }

      finishScrollTimeout = setTimeout(finishScrollUpdates, 500);

      if(!updateIndexInterval) {
        updateIndexInterval = setInterval(updateIndexToNearest, 250);
      }
    }

    window.addEventListener("resize", refreshScrollUpdates);
    wrapper.addEventListener("scroll", refreshScrollUpdates);

    return () => {
      if(finishScrollTimeout) {
        clearTimeout(finishScrollTimeout);
      }

      if(updateIndexInterval) {
        clearInterval(updateIndexInterval);
      }

      window.removeEventListener("resize", refreshScrollUpdates);
      wrapper.removeEventListener("scroll", refreshScrollUpdates);
    }
  }, [childrenCount, scrollToIndex, triggerOnScrollToEnd]);

  useEffect(() => {
    triggerOnScrollToEnd(index);
  }, [index, triggerOnScrollToEnd]);

  return (
    <div
      className={classNames({
        "carousel": true,
      })}
      id={id}
    >
      <nav className="carousel__nav">
        <CarouselNavButton
          controlsID={id}
          disabled={previousDisabled}
          onClick={() => {
            if(!previousDisabled) {
              scrollToIndex(Math.max(0, index - childrenToShow));
            }
          }}
          tag="previous"
        >Previous</CarouselNavButton>
        <CarouselNavButton
          controlsID={id}
          disabled={nextDisabled}
          onClick={() => {
            if(!nextDisabled) {
              scrollToIndex(Math.min(childrenCount - 1, index + childrenToShow));
            }
          }}
          tag="next"
        >Next</CarouselNavButton>
      </nav>
      <div
        className="carousel__wrapper"
        ref={wrapperRef}
      >
        <ol
          className="carousel__list"
          ref={listRef}
          style={{
            width: `${(100 / childrenToShow) * childrenCount}%`
          }}
        >
          {Children.map(children, (child, itemIndex) => (
            <li
              key={itemIndex}
              className={classNames({
                "carousel__list__item": true,
                "carousel__list__item--active": itemIndex >= index && itemIndex < index + childrenToShow,
              })}
              ref={(el) => {
                if(el) {
                  listItemRefs.current[itemIndex] = el;
                } else {
                  delete listItemRefs.current[itemIndex];
                }
              }}
            >{child}</li>
          ))}
        </ol>
      </div>
    </div>
  )
}