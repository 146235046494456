export interface getAuthorizationTokenArgs {
  apiURL: string;
  clientID: number;
  clientSecret: string;
}

export interface getAuthorizationTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export default async function getAuthorizationToken({
  apiURL,
  clientID,
  clientSecret,
}: getAuthorizationTokenArgs) {
  const response = await fetch(`${apiURL}/oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "grant_type": "client_credentials",
      "client_id": clientID,
      "client_secret": clientSecret,
      "scope": "*"
    })
  });

  if (!response.ok) {
    throw new Error("Could not authenticate client.");
  }
  
  const token: getAuthorizationTokenResponse = await response.json();

  return token.access_token;
}