import Input, { InputProps } from "./Input";
import { AVSCountryData } from "../fetchers/getAVSCountryData";

export type CountryInputProps = Omit<InputProps, "as"> & {
  countryData: AVSCountryData | null | undefined;
  countryDataError?: unknown;
  isCountryDataLoading?: boolean;
  isCountryDataValidating?: boolean;
};

export default function CountryInput({
  countryData,
  countryDataError,
  isCountryDataLoading,
  isCountryDataValidating,
  ...props
}: CountryInputProps) {
  const extraClasses: string[] = [];

  if(countryDataError) {
    extraClasses.push("hasError");
  }

  if(isCountryDataLoading) {
    extraClasses.push("isLoading");
  }

  if(isCountryDataValidating) {
    extraClasses.push("isValidating");
  }

  if(extraClasses.length) {
    if(props.className) {
      props.className += " " + extraClasses.join(" ");
    } else {
      props.className = extraClasses.join(" ");
    }
  }
  
  return (
    <Input
      {...props}
      as={countryData ? "select" : "input"}
    >
      {countryData && (
        <>
          <option value=""></option>
          {countryData.map(country => (
            <option
              key={country.name}
              value={country.name}
            >{country.name}</option>
          ))}
        </>
      )}
    </Input>
  )
}