import { StepComponentProps } from "../Step";

import { default as RecaptchaComponent } from "../../components/Recaptcha";

export default function Recaptcha({
  appConfiguration,
}: StepComponentProps) {
  return (
    <RecaptchaComponent appConfiguration={appConfiguration} />
  )
}