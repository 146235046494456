import classNames from "classnames";

export interface LoadingIndicatorProps {
  isLoading: boolean;
  label: string;
}

export default function LoadingIndicator({
  isLoading,
  label,
}: LoadingIndicatorProps) {
  return (
    <div
      aria-hidden={!isLoading}
      aria-label={label}
      className={classNames({
        "loadingIndicator": true,
        "loadingIndicator--inactive": !isLoading,
        "loadingIndicator--loading": isLoading,
      })}
      hidden={!isLoading}
      role="alert"
    >&nbsp;</div>
  )
}