import LoginOption from "./LoginOption";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";

export interface GuestLoginOptionProps {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function AdditionalLoginOption(props: GuestLoginOptionProps) {
  const { appConfiguration } = props;

  if(!appConfiguration?.show_additional_login_option || !appConfiguration.login_sections?.length) {
    return "";
  }

  return appConfiguration
    .login_sections
    .sort((a, b) => (b.priority || 0) - (a.priority || 0))
    .map(section => (
      <LoginOption
        appConfiguration={appConfiguration}
        defaultTitle={section.title || "Additional Information"}
        key={section.id}
        showSubmitButton={false}
      >
        <div dangerouslySetInnerHTML={{ __html: section.content || "" }} />
      </LoginOption>
    ));
}