import useSWRImmutable from "swr/immutable";

import { getAuthorizedAPIDataSharedArgs } from "../fetchers/getAuthorizedAPIData";
import getAVSStateData from "../fetchers/getAVSStateData";

export interface useAVSStateDataArgs extends getAuthorizedAPIDataSharedArgs {

}

export type UseAVSStateData = ReturnType<typeof useAVSStateData>;

export default function useAVSStateData(args: useAVSStateDataArgs) {
  const {
    data: stateData,
    error: stateDataError,
    isLoading: isStateDataLoading,
    isValidating: isStateDataValidating,
  } = useSWRImmutable({ 
    ...args,
    cacheKey: "avsStateData"
  }, getAVSStateData);

  return {
    stateData,
    stateDataError: stateDataError as unknown,
    isStateDataLoading,
    isStateDataValidating,
  };
}