import TextContent from "../../components/TextContent";
import { StepComponentProps } from "../Step";

export default function NoAddOns({ appConfiguration }: StepComponentProps) {
  return (
    <TextContent
      appConfiguration={appConfiguration}
      contentKey="noAddonsMessage"
      defaultValue="No add-ons available. Please proceed to the next step."
      tag="p"
    />
  );
}