import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import { StepComponentProps } from "../Step";

export default function PromoCodeMessage({ appConfiguration }: StepComponentProps) {
  const { values } = useFormikSchemaContext();

  if(
    !appConfiguration?.show_promo_code_message
    || !appConfiguration?.promo_code_message
    || !values.paymentRequired
  ) {
    return '';
  }

  return (
    <div className="promoCodeMessage">{appConfiguration.promo_code_message}</div>
  );
}