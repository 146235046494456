import React from 'react';
import { createRoot } from 'react-dom/client';

import App, { AppProps } from './App';

export default function(props: AppProps, root: HTMLElement) {
  createRoot(root).render(
    <React.StrictMode>
      <App {...props} />
    </React.StrictMode>,
  );
}
