import { StepComponentProps } from "../Step";

import TextContent from "../../components/TextContent";
import ToggleContainer from "../../components/ToggleContainer";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getPriceFormatter from "../../formatters/getPriceFormatter";
import Counter from "../../components/Counter";
import { LoginType } from "../Login";

interface TicketSelectionPrice {
  appliesTo: string;
  appliesToID: string;
  description: string;
  formattedValue: string;
  label: string;
  max?: number;
  value: number;
  voucherCode?: string;
  voucherEligible?: boolean;
}

export default function TicketSelection(props: StepComponentProps) {
  const {
    appConfiguration,
    ticketCategory,
    ticketData,
    ticketEvents,
    ticketSets,
    voucherData,
  } = props;

  const { setFieldValue, values } = useFormikSchemaContext();

  let visible = true;

  // Hide ticket selection if no category selected.
  if(!ticketCategory) {
    visible = false;
  }

  // Hide ticket selection if no events are selected.
  if(!ticketEvents.length) {
    visible = false;
  }

  // Hide ticket selection if any set doesn't have enough events selected.
  if(ticketSets?.length) {
    for(let ticketSetIndex = 0; ticketSetIndex < ticketSets?.length; ticketSetIndex++) {
      const ticketSet = ticketSets[ticketSetIndex];
      const ticketSetEvents = ticketEvents.filter(ticketEvent => ticketEvent.ticketSetIndex === ticketSetIndex);
    
      if(ticketSetEvents.length < ticketSet.quantity) {
        visible = false;
        break;
      }
    }
  } else {
    visible = false;
  }

  const comboTemplate = appConfiguration?.combo_templates.find(comboTemplate => ticketCategory?.template_ids.includes(comboTemplate.template));
  const activeEventIDs = ticketEvents.map(({ eventID }) => eventID);
  const format = getPriceFormatter(appConfiguration?.locale, true);
  
  const categoryTemplateSignature = ticketCategory
    ?.template_ids
    .sort()
    .filter((templateID, templateIDsIndex) => !templateIDsIndex || templateID !== ticketCategory.template_ids[templateIDsIndex - 1])
    .join(",");

  const voucherInfo = values.voucherCodes.map(voucherCode => {
    if(!voucherCode) {
      return false;
    }
  
    const info = voucherData[voucherCode];

    if(!info) {
      return false;
    }

    if(info.template_ids.sort().join(",") === categoryTemplateSignature) {
      return info;
    }

    return false;
  }).filter(info => info !== false);
  
  const displayPrices: TicketSelectionPrice[] = ticketData
    ?.filter(({ event_id }) => activeEventIDs.includes(event_id))
    .reduce((displayPrices, ticket) => {
      ticket.prices.forEach(price => {
        const displayPrice = displayPrices.find(({ appliesToID }) => appliesToID === price.applies_to_id);

        if(displayPrice) {
          displayPrice.value += price.price;
        } else {
          const applicableTicketTypes = appConfiguration
            ?.ticket_types
            .filter(ticketType => ticketType.applies_to_id === price.applies_to_id)
            .filter(ticketType => comboTemplate
              ? ticketType.combo_template_id === comboTemplate.template
              : !ticketType.combo_template_id)
            || [];
          
          let ticketType = applicableTicketTypes.find(ticketType => ticketType.template_id === ticket.template_id);

          if(!ticketType && applicableTicketTypes.length) {
            ticketType = applicableTicketTypes.find(ticketType => !ticketType.template_id);
          }

          if(!ticketType) {
            ticketType = appConfiguration?.ticket_types.find(ticketType => ticketType.applies_to_id === price.applies_to_id && ticketType.template_id === null);
          }

          if(ticketType) {
            if(values.loginType === LoginType.Guest && !ticketType.show_non_members) {
              return;
            }

            if(values.loginType === LoginType.Member && !ticketType.show_members) {
              return;
            }

            if(values.isResident) {
              if(!ticketType.show_residents) {
                return;
              }
            } else {
              if(!ticketType.show_non_residents) {
                return;
              }
            }
          }

          const max = ticket.seats_available - values.tickets
            .filter(t => t.eventTemplateID === ticket.template_id && t.ticketingTypeID !== price.applies_to_id)
            .reduce((sum, t) => sum + t.quantity, 0);
  
          displayPrices.push({
            appliesTo: price.applies_to,
            appliesToID: price.applies_to_id,
            description: ticketType?.description || "",
            label: ticketType?.label || price.applies_to,
            max,
            value: price.price,
            formattedValue: "",
          });
        }
      });
      
      return displayPrices;
    }, [] as TicketSelectionPrice[])
    .map(({ appliesTo, appliesToID, description, label, max, value }) => {
      const comboPrice = comboTemplate?.prices.find(({ applies_to_id }) => applies_to_id === appliesToID);

      if(comboPrice) {
        if(typeof(comboPrice.price) === "string") {
          value = parseFloat(comboPrice.price);
        } else {
          value = comboPrice.price;
        }
      }

      const discount = comboTemplate?.discounts.find(({ applies_to_id }) => applies_to_id === appliesToID);

      if(discount) {
        if(discount.is_percent_based) {
          value -= value * (discount.value * 100);
        } else {
          value -= discount.value;
        }
      }

      const formattedValue = format(value);
      const info = voucherInfo.find(info => info && (!info.override_price || info.acme_ticket_category === appliesTo || info.acme_ticket_category?.includes(appliesTo)));

      return {
        appliesTo,
        appliesToID,
        description,
        label,
        max,
        formattedValue,
        value,
        voucherEligible: !!info,
      }
    })
    
    || [];

  return (
    <ToggleContainer
      visible={visible}
      render={() => (
        <>
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="beforeTicketSelect"
          />
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="ticketSelectHeader"
            defaultValue="Tickets"
            tag="h3"
          />
          <ul className="ticketSelection__list">
            {displayPrices.map(({ appliesToID, description, formattedValue, label, max, voucherEligible }) => (
              <li
                className="ticketSelection__list__item"
                key={appliesToID}
              >
                <span className="ticketSelection__list__item__label">{label}</span>
                {description && <span className="ticketSelection__list__item__description">{description}</span>}
                <span className="ticketSelection__list__item__price">{formattedValue}</span>
                <Counter
                  max={max}
                  min={0}
                  setValue={async (value: number) => {
                    const tickets = values.tickets
                      .map(ticket => {
                        if(ticket.ticketingTypeID === appliesToID) {
                          ticket.quantity = value;
                        }

                        return ticket;
                      });

                    if(!tickets.find(ticket => ticket.ticketingTypeID === appliesToID)) {
                      ticketEvents.forEach(ticketEvent => {
                        tickets.push({
                          comboTemplateID: ticketEvent.comboTemplateID || undefined,
                          isComboTicket: ticketEvents.length > 1,
                          eventID: ticketEvent.eventID,
                          eventTemplateID: ticketEvent.eventTemplateID,
                          quantity: value,
                          ticketingTypeID: appliesToID,
                        });
                      });
                    }

                    await setFieldValue("tickets", tickets);
                  }}
                  value={values.tickets.find(ticket => ticket.ticketingTypeID === appliesToID)?.quantity}
                />
                {voucherEligible && <TextContent
                  appConfiguration={appConfiguration}
                  contentKey="eligibleForVoucher"
                  defaultValue="Eligible for voucher"
                  tag="p"
                />}
              </li>
            ))}
          </ul>
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="afterTicketSelect"
          />
        </>
      )}
    />
  );
}