import classNames from "classnames";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import getLocalizedText from "../../formatters/getLocalizedText";

export interface SectionProps {
  appConfiguration: AppConfiguration | null | undefined;
  defaultLabel: string;
  emphasize?: boolean;
  value: string | string[];
}

export default function Section({
  appConfiguration,
  defaultLabel,
  emphasize,
  value,
}: SectionProps) {
  const compoundValue = typeof(value) !== "string";

  return (
    <>
      <dd className={classNames({
        "ticketConfirmation__section__label": true,
        "ticketConfirmation__section__label--compoundValue": compoundValue,
        "ticketConfirmation__section__label--emphasize": emphasize,
      })}>
        {getLocalizedText(appConfiguration, defaultLabel.replace(/[^A-Za-z0-9-]+/g, ""), defaultLabel)}
      </dd>
        <dt className={classNames({
        "ticketConfirmation__section__value": true,
        "ticketConfirmation__section__value--compoundValue": compoundValue,
        "ticketConfirmation__section__value--emphasize": emphasize,
      })}>
        {compoundValue
          ? (
            <ul className="ticketConfirmation__section__value__list">
              {value.map((v, i) => (
                <li
                  className="ticketConfirmation__section__value__list__item"
                  key={i}
                >{v}</li>
              ))}
            </ul>
          )
          : value
        }
      </dt>
    </>
  );
}