import Section, { SectionProps } from "./Section";

import LoaderContainer from "../LoaderContainer";
import TextContent from "../TextContent";

import getCartTotal from "../../formatters/getCartTotal";
import getPriceFormatter from "../../formatters/getPriceFormatter";
import getLocalizedDate from "../../formatters/getLocalizedDate";
import { StepComponentProps } from "../../steps/Step";
import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export interface TicketConfirmationProps extends StepComponentProps {
  showTotal?: boolean
}

export default function TicketConfirmation({
  appConfiguration,
  cartData,
  cartDataError,
  getMemberLevel,
  getMemberLevelByID,
  isCartDataLoading,
  isCartDataValidating,
  showTotal = true,
  ticketData,
  ticketDate,
}: TicketConfirmationProps) {
  const { values } = useFormikSchemaContext();
  const hasTicket = !!(!cartData?.items || cartData.items.find(item => item.itemType.includes("Event")));

  const dateFormatter = new Intl.DateTimeFormat(appConfiguration?.locale || "en-US", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    timeZone: appConfiguration?.source_time_zone,
  });

  const timeFormatter = new Intl.DateTimeFormat(appConfiguration?.locale || "en-US", {
    hour: "numeric",
    minute: "2-digit",
    timeZone: appConfiguration?.source_time_zone,
  });

  const formatPrice = getPriceFormatter(appConfiguration?.locale, true);

  return (
    <>
      {hasTicket && (
        <>
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="yourVisitHeader"
            defaultValue="Your Visit"
            tag="h3"
          />
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="ticketsHeader"
            defaultValue="Tickets"
            tag="h3"
          />
        </>
      )}
      <LoaderContainer
        error={cartDataError}
        errorMessage={getLocalizedText(
          appConfiguration,
          "ticketConfirmationError",
          "Could not confirm ticket data. Please refresh the page and try again."
        )}
        isLoading={isCartDataLoading}
        isValidating={isCartDataValidating}
        showLoadingIndicator={false}
        showLoadingMessage={false}
        renderWhileLoading={true}
        renderWhileValidating={true}
        render={() => {
          const sections: Omit<SectionProps, "appConfiguration">[] = [];

          const membership = cartData?.items?.find(item => item.itemType.includes("Membership"));

          if(membership) {
            let membershipType = "For me";

            if(values.membershipTarget === "gift") {
              membershipType = "Gift";
            } else if(membershipType === "voucher") {
              membershipType = "Voucher";
            }
            
            sections.push({
              defaultLabel: "Membership Type",
              value: membershipType
            });

            sections.push({
              defaultLabel: "Primary Member Name",
              value: [values.primaryMemberFirstName, values.primaryMemberLastName].filter(v => v).join(" "),
            });
            
            if(values.secondaryMemberFirstName) {
              sections.push({
                defaultLabel: "Secondary Member Name",
                value: [values.secondaryMemberFirstName, values.secondaryMemberLastName].filter(v => v).join(" "),
              });
            }

            const activeMemberLevel = getMemberLevel(values.membershipLevelSlug);

            if(activeMemberLevel) {
              const addonMemberLevel = getMemberLevelByID(values.membershipAddonLevelID);
              let membershipLevelValue = activeMemberLevel.name;

              if(addonMemberLevel) {
                membershipLevelValue += ` + ${addonMemberLevel.name}`;
              }

              sections.push({
                defaultLabel: "Membership Level",
                value: membershipLevelValue,
              });
            }
          }

          if(ticketDate) {
            sections.push({
              defaultLabel: "Visit Date",
              value: dateFormatter.format(getLocalizedDate(ticketDate, appConfiguration?.source_time_zone)),
            });
          }

          const visitSectionValues: string[] = [];
          const quantitySectionValues: string[] = [];

          cartData?.comboItems?.forEach(comboItem => {
            comboItem.events.forEach(comboEvent => {
              visitSectionValues.push(
                comboEvent.eventName 
                + " @ " 
                + timeFormatter.format(getLocalizedDate(comboEvent.eventStartTime, appConfiguration?.source_time_zone))
              );
            });

            const comboItemTicketSummaries: Record<string, {
              quantity: number;
              price: number;
            }> = {};

            comboItem.tickets.forEach(comboTicket => {
              const ticketType = appConfiguration?.ticket_types.find(ticketType => ticketType.applies_to_id === comboTicket.ticketTypeId);
              const name = ticketType?.label || comboTicket.name;

              if(!comboItemTicketSummaries[name]) {
                comboItemTicketSummaries[name] = {
                  quantity: 0,
                  price: 0,
                };
              }

              comboItemTicketSummaries[name].quantity = comboTicket.quantity;
              comboItemTicketSummaries[name].price += comboItem.comboTemplateType === "discount" ? comboTicket.discountedPrice : comboTicket.price;
            });

            for(const name in comboItemTicketSummaries) {
              const summary = comboItemTicketSummaries[name];

              quantitySectionValues.push(
                name
                + " x "
                + summary.quantity.toString()
                + " @ "
                + formatPrice(summary.price)
              );
            }
          });

          let donationTotal = 0;

          cartData?.items?.forEach(item => {
            if(["Event", "ComboEvent"].includes(item.itemType)) {
              const eventName = item.eventName || "Admission";
              const eventTicket = ticketData?.find(t => t.event_id === item.eventId);

              const visitSectionValue = eventName + (eventTicket
                ? " @ "  + timeFormatter.format(getLocalizedDate(eventTicket.start_time, appConfiguration?.source_time_zone))
                : ""
              );

              if(!visitSectionValues.includes(visitSectionValue)) {
                visitSectionValues.push(visitSectionValue);
              }

              const ticketType = appConfiguration?.ticket_types.find(ticketType => ticketType.applies_to_id === item.ticketingTypeId);
              const name = ticketType?.label || item.ticketingTypeName;
              
              let quantityEntry = `${name} x ${item.quantity} @ ${formatPrice(item.unitPrice)}`;

              if(item.couponCodes?.length) {
                quantityEntry += " (" + getLocalizedText(appConfiguration, "voucherApplied", "Voucher applied") + ")";
              }

              quantitySectionValues.push(quantityEntry);
            } else if(["Inventory", "ComboInventory"].includes(item.itemType)) {
              if(item.donationDetail) {
                let price = item.unitPrice || 0;

                if(typeof price === "string") {
                  price = parseFloat(price);
                }

                donationTotal += price;
              } else {
                let value: string = "";

                if(item.inventoryName) {
                  value = item.inventoryName;
                } else {
                  const addon = appConfiguration?.addons.find(addon => addon.addon_id === item.inventoryId);

                  if(addon) {
                    value = addon.name;
                  }
                }

                if(!value) {
                  value = getLocalizedText(appConfiguration, "addon", "Add-on");
                  value += ` x ${item.quantity}`;
                }

                value += ` @ ${formatPrice(item.unitPrice)}`;

                quantitySectionValues.push(value);
              }
            }
          });

          if(visitSectionValues.length) {
            sections.push({
              defaultLabel: "Visit Type & Entry Time",
              value: visitSectionValues,
            });
          }

          if(quantitySectionValues.length) {
            sections.push({
              defaultLabel: "Quantity",
              value: quantitySectionValues,
            });
          }

          if(donationTotal) {
            sections.push({
              defaultLabel: getLocalizedText(appConfiguration, "donation", "Donation"),
              value: formatPrice(donationTotal),
            });
          }

          if(showTotal) {
            const { requiredTotal } = getCartTotal(cartData);

            sections.push({
              defaultLabel: "Total",
              value: formatPrice(requiredTotal),
            });
          }

          return (
            <dl className="ticketConfirmation__sections">
              {sections.map((section, sectionIndex) => <Section 
                key={sectionIndex}
                appConfiguration={appConfiguration}
                {...section}
              />)}
            </dl>
          );
        }}
      />
    </>
  );
}