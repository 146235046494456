import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export type CartEventItemType = "Event" | "ComboEvent";
export type CartInventoryItemType = "Inventory" | "ComboInventory";

export type CartMembershipItemType = "MembershipPurchase"
  | "MembershipRenewal"
  | "MembershipUpgrade"
  | "MembershipDowngrade"
  | "MembershipCancel"
  | "MembershipPostTermRenewal"
  | "MembershipRejoinUpgrade"
  | "MembershipPostTermRenewalDowngrade"
  | "MembershipRejoinDowngrade"
  | "MembershipRenewalDowngrade";

export type CartItemType = CartEventItemType | CartInventoryItemType | CartMembershipItemType;

export type CartMembershipCardType = "primary" | "secondary";
export type CartMembershipCardStatus = "New" | "Active" | "Dropped" | "Lapsed" | "Replaced";
export type CartMembershipCardPrintStatus = "printed" | "notPrinted" | "doNotPrint" | "lost";
export type CartAddressType = "primary" | "billing";

export interface CartAddress {
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  suburb?: string;
  type?: CartAddress;
}

export type ShortCartAddress = Omit<CartAddress, "type">;

export type CartMembershipCard = ShortCartAddress & {
  id?: number;
  visitorId?: number;
  membershipId?: number;
  externalCustomerId?: string;
  externalContactId?: string;
  constituentId?: string;
  acmeCustomerNumber?: string;
  cardImportId?: string;
  cardType: CartMembershipCardType;
  name: string;
  barcode?: string;
  acmeBarcode?: string;
  email: string;
  prefix?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNotes?: string;
  orgName?: string;
  orgId?: number;
  deceased?: boolean;
  primaryCard?: boolean;
  solicitationCodes?: string[];
  details?: Record<string, unknown>;
  cardholderAttributes?: Record<string, unknown>;
  startDate?: string;
  expirationDate?: string;
  cardStatus?: CartMembershipCardStatus;
  printCount?: number;
  printStatus?: CartMembershipCardPrintStatus;
  customerId?: number;
  orgImportId?: string;
  excludeCardOnMembershipCreation?: boolean;
  ignoreUpdates?: boolean;
}

export interface CartMembershipCustomerAttribute {
  category: string;
  description: string;
  notes: string;
  date: string;
}

export interface CartMembershipCustomerError {
  error: string;
  membershipImportId: number;
  cardImportId: number;
  customerImportId: number;
  benefitImportId: number;
}

export interface CartMembershipCustomer {
  id: number;
  customerSource: string;
  customerId: string;
  externalCustomerId: string;
  externalCustomerImportId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  phoneNotes: string;
  prefix: string;
  suffix: string;
  orgName: string;
  deceased: boolean;
  solicitationCodes: string[];
  address: CartAddress;
  attributes: CartMembershipCustomerAttribute[];
  errors?: CartMembershipCustomerError[];
}

export interface CartMembershipGifterInfo {
  id?: number;
  address?: {
    streetAddress1: string;
    streetAddress2?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    type?: "primary" | "billing";
    isPrimary?: boolean;
  }[];
  customerSource: string;
  customerId?: string;
  deceased?: boolean;
  externalCustomerId?: string;
  externalCustomerImportId?: string;
  externalId?: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  orgName?: string;
  phoneNumber?: string;
  phoneNotes?: SVGStringList;
  prefix?: string;
  version: number,
  obfuscated: boolean,
  createdOn?: Date | string,
  createdBy?: number;
  updatedOn?: Date | string,
  updateBy?: number;
}

export interface CartMembershipInfo {
  auxiliary?: boolean;
  membershipCategoryId: string;
  membershipOfferingId: string;
  pricePointId: string;
  membershipId?: string;
  membershipCards: CartMembershipCard[];
  waiveBenefits?: boolean;
  isGift?: boolean;
  gifterInfo?: CartMembershipGifterInfo;
  notifyGiftRecipient?: boolean;
  giftMessage?: string;
  isAutoRenew?: string;
}

export type CartDonationDonorRecognition = "self" | "anonymous" | string;
export type CartDonationDonorRecognitionType = "self" | "organization" | "anonymous";

export interface CartDonationCustomFieldValue {
  name: string;
  value: string;
}

export interface CartDonationDetail {
  donorRecognition: CartDonationDonorRecognition;
  donorRecognitionTag?: string;
  donorRecognitionType: CartDonationDonorRecognitionType;
  dedicationLastName?: string;
  dedicationFirstName?: string;
  donationCampaignName?: string;
  donationAppealName?: string;
  donationFundName?: string;
  beneficiaryType?: string;
  beneficiaryLastName?: string;
  beneficiaryFirstName?: string;
  beneficiaryEmail?: string;
  beneficiaryPhoneNumber?: string;
  beneficiaryStreetAddress1?: string;
  beneficiaryStreetAddress2?: string;
  beneficiaryCity?: string;
  beneficiaryState?: string;
  beneficiaryZip?: string;
  beneficiaryCountry?: string;
  notes?: string;
  customFieldValues?: CartDonationCustomFieldValue[];
}

export interface CartItem {
  itemId: string;
  eventId?: string;
  ticketingTypeId?: string;
  eventName?: string;
  ticketingTypeName?: string;
  quantity: number;
  unitPrice?: number | string;
  retailUnitPrice?: number | string;
  amount: number | string;
  itemType: CartItemType;
  inventoryId?: string | number;
  admissionType?: string;
  inventoryName?: string;
  membershipInfo: CartMembershipInfo;
  ignoreEntitlesments: boolean;
  isRetail?: boolean;
  discountId?: string;
  donationDetail?: CartDonationDetail;
  couponCodes?: string[];
}

export type CartComboItemType = "fixedPrice" | "discount";

export interface CartComboItemEvent {
  eventTemplateId: string;
  eventId: string;
  eventName: string;
  eventStartTime: string;
}

export interface CartComboItemTicket {
  eventTemplateId: string;
  ticketTypeId: string;
  name: string;
  description: string;
  price: number;
  discountedPrice: number;
  quantity: number;
  subtotal: number;
  discountedSubtotal: number;
}

export type CartComboItemPriceSplit = "each" | "total";

export interface CartComboItemAddOn {
  addOnId: string;
  name: string;
  quantity: number;
  unitPrice: number;
  priceSplit: CartComboItemPriceSplit;
}

export interface CartComboItem {
  comboTemplateId: string;
  comboTemplateName: string;
  comboTemplateType: CartComboItemType;
  events: CartComboItemEvent[];
  tickets: CartComboItemTicket[];
  addOns: CartComboItemAddOn[];
}

export type CartSaleChannel = "online"
  | "pointOfSale"
  | "reseller"
  | "insideSalesIndividual"
  | "kiosk";

export interface Cart {
  id: string;
  membershipCategoryId?: string;
  items?: CartItem[];
  comboItems?: CartComboItem[];
  forms?: Record<string, unknown>[];
  couponCodes?: string[];
  customerId?: string;
  membershipIds?: string[];
  saleChannel: CartSaleChannel;
  verifyEntitlements?: boolean;
}

export interface getCartDetailArgs extends getAuthorizedAPIDataSharedArgs {
  id: string;
}

export default async function getCartData({
  apiURL,
  authorizationToken,
  id,
  signal,
}: getCartDetailArgs): APIResponse<Cart> {
  if(!id) {
    return null;
  }

  const endPoint = `api/cart/${id}`;

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    endPoint,
    signal,
  });
}