import classNames from "classnames";

import LoaderContainer from "../LoaderContainer";
import { ModalRenderFunction } from "../Modal/Modal";
import TextContent from "../TextContent";
import { AppConfiguration, AppTicketCategory } from "../../fetchers/getAppConfiguration";
import { UseFormikSchemaContext } from "../../hooks/useFormikSchemaContext";
import { UseVoucherData } from "../../hooks/useVoucherData";
import Input from "../Input";
import getLocalizedText from "../../formatters/getLocalizedText";
import getLocalizedDate from "../../formatters/getLocalizedDate";
import { Voucher } from "../../fetchers/getVoucherData";

export interface getRenderArgs extends
  Pick<UseFormikSchemaContext, "setFieldValue" | "values">, 
  Pick<UseVoucherData, "getVoucherData" | "isVoucherDataLoading" | "isVoucherDataValidating" | "voucherData" | "voucherDataError"> {
  appConfiguration: AppConfiguration | null | undefined;
  onCloseButtonClick?: () => Promise<void>;
  onVoucher?: (voucherCode: string, valid: boolean, voucherInfo: Voucher | null) => void;
  ticketCategories?: AppTicketCategory[];
}

const getRender: (args: getRenderArgs) => ModalRenderFunction = ({
  appConfiguration,
  getVoucherData,
  onCloseButtonClick,
  onVoucher,
  setFieldValue,
  ticketCategories,
  values,
  voucherData,
  isVoucherDataLoading,
  isVoucherDataValidating,
}) => {
  const removeVoucherCode = async (voucherCodeIndex: number) => {
    const newVoucherCodes = [...values.voucherCodes];

    if(voucherCodeIndex === newVoucherCodes.length - 1) {
      newVoucherCodes[voucherCodeIndex] = "";
    } else {
      newVoucherCodes.splice(voucherCodeIndex, 1);
    }
    
    await setFieldValue("voucherCodes", newVoucherCodes);
  };

  const verifyVoucherCode = async (voucherCode: string) => {
    if(voucherCode) {
      const newData = await getVoucherData({ voucherCode });

      if(newData && newData.id) {
        await setFieldValue("voucherCodes", [...values.voucherCodes, ""]);
      }

      if(onVoucher) {
        onVoucher(voucherCode, !!newData?.is_valid, newData);
      }
    }
  };

  const dateFormatter = new Intl.DateTimeFormat(appConfiguration?.locale || "en-US", {
    dateStyle: "long",
    timeZone: appConfiguration?.source_time_zone,
  });

  const voucherCodeSummaries = values
    .voucherCodes
    .map(voucherCode => voucherCode || "")
    .map((voucherCode, voucherCodeIndex, mappedVoucherCodes) => {
      const data = voucherData[voucherCode];
      let descriptions: string[] = [];

      if(voucherCode?.trim()) {
        if(data) {
          const voucherTemplateIDSignature = data
            ?.template_ids
            .map(templateID => templateID || "")
            .filter(templateID => templateID.trim())
            .sort()
            .filter((templateID, templateIDIndex, templateIDs) => !templateIDIndex || templateID !== templateIDs[templateIDIndex - 1])
            .join(",");

          const ticketCategory = voucherTemplateIDSignature && ticketCategories?.find(ticketCategory => {
            const ticketCategoryTemplateIDSignature = ticketCategory
              .template_ids
              .map(templateID => templateID || "")
              .filter(templateID => templateID.trim())
              .sort()
              .filter((templateID, templateIDIndex, templateIDs) => !templateIDIndex || templateID !== templateIDs[templateIDIndex - 1])
              .join(",");

            if(voucherTemplateIDSignature === ticketCategoryTemplateIDSignature) {
              return true;
            } else {
              return false;
            }
          }) || null;

          if(ticketCategory?.label) {
            descriptions.push(ticketCategory.label);
          } else {
            descriptions.push(data.description || "");
          }
          
          if(data.is_redeemed) {
            let redemptionDescription = getLocalizedText(appConfiguration, "redeemed", "Redeemed");

            if(data.redemption_date) {
              const date = getLocalizedDate(data.redemption_date, appConfiguration?.source_time_zone);
              const formattedDate = dateFormatter.format(date);

              redemptionDescription += " " + formattedDate;
            }

            descriptions.push(redemptionDescription);
          } else if(data.expiry_date) {
            const prefix = data.is_expired
              ? getLocalizedText(appConfiguration, "expired", "Expired")
              : getLocalizedText(appConfiguration, "expires", "Expires");
            
            const date = getLocalizedDate(data.expiry_date, appConfiguration?.source_time_zone);
            const formattedDate = dateFormatter.format(date);

            descriptions.push(`${prefix} ${formattedDate}`);
          }

          descriptions = descriptions.filter(description => description);
        }

        if(mappedVoucherCodes.indexOf(voucherCode) !== voucherCodeIndex) {
          descriptions.push(getLocalizedText(appConfiguration, "alreadyInUse", "Already in use"));
        }
      }

      return {
        data,
        descriptions,
        voucherCode,
      }
    });

  const filteredVoucherCodes = values
    .voucherCodes
    .map(voucherCode => voucherCode || "")
    .filter(voucherCode => voucherCode.trim());

  const submitDisabled = !!(
    filteredVoucherCodes.length === 0
    || !voucherData
    || ~filteredVoucherCodes.findIndex(voucherCode => !voucherData[voucherCode])
    || ~filteredVoucherCodes.findIndex(voucherCode => !voucherData[voucherCode]!.is_valid)
  );

  const render: ModalRenderFunction = ({ close }) => (
    <>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="voucherModalHeader"
        defaultValue="Redeem vouchers for tickets"
        tag="h3"
      />
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="voucherModalContent"
        defaultValue="<p>Enter voucher codes and then select your tickets to redeem.</p>"
      />
      <ol className="voucherModal__list">
        {voucherCodeSummaries.map(({ data, descriptions, voucherCode }, voucherCodeIndex) => (
          <li
            className={classNames({
              "voucherModal__list__item": true,
              "voucherModal__list__item--checked": data !== undefined,
              "voucherModal__list__item--expired": data?.is_expired,
              "voucherModal__list__item--redeemed": data?.is_redeemed,
              "voucherModal__list__item--valid": data?.is_valid,
              "voucherModal__list__item--invalid": data && !data.is_valid,
            })}
            key={voucherCodeIndex}
          >
            {data !== undefined
              ? <button
                  className={classNames({
                    "voucherModal__list__item__main__button": true,
                    "voucherModal__list__item__main__button--remove": true,
                    "voucherModal__list__item__main__button--enabled": true,
                  })}
                  onClick={() => removeVoucherCode(voucherCodeIndex)}
                  title={getLocalizedText(appConfiguration, "removeVoucherCode", "Remove voucher code.")}
                  type="button"
                >&minus;</button>
              : <button
                  className={classNames({
                    "voucherModal__list__item__main__button": true,
                    "voucherModal__list__item__main__button--verify": true,
                    "voucherModal__list__item__main__button--enabled": true,
                  })}
                  onClick={() => verifyVoucherCode(voucherCode)}
                  title={getLocalizedText(appConfiguration, "verifyVoucherCode", "Verify voucher code.")}
                  type="button"
                >+</button>
            }
            <div className="voucherModal__list__item__main">
              <Input
                label={getLocalizedText(appConfiguration, "voucherCode", "Voucher Code")}
                name={`voucherCodes[${voucherCodeIndex}]`}
                showLabel={false}
                type="text"
              />
              <LoaderContainer
                error={false}
                isLoading={!descriptions.join(" | ") && isVoucherDataLoading}
                isValidating={!descriptions.join(" | ") && isVoucherDataValidating}
                render={() => (
                  <p className="voucherModal__list__item__descriptions">{descriptions.join(" | ")}</p>
                )}
              />
            </div>
          </li>
        ))}
      </ol>
      <button
        className="submitButton voucherModal__closeButton"
        disabled={submitDisabled || undefined}
        onClick={async () => {
          if(onCloseButtonClick) {
            await onCloseButtonClick();
          }

          close();
        }}
        type="button"
      >{getLocalizedText(appConfiguration, "selectTickets", "Select Tickets")}</button>
    </>
  );

  return render;
};

export default getRender;