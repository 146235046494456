import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";

export type ToggleContainerProps = PropsWithChildren<{
  className?: string;
  render?: () => ReactNode;
  renderWhenHidden?: boolean;
  visible: boolean;
}>

export default function ToggleContainer({
  children,
  className,
  render,
  renderWhenHidden = false,
  visible,
}: ToggleContainerProps) {
  return (
    <div
      aria-hidden={!visible}
      className={classNames({
        "toggleContainer": true,
        "toggleContainer--hidden": !visible,
        "toggleContainer--visible": visible,
        [className + ""]: className,
      })}
    >
      {
        (visible || renderWhenHidden) && 
          (render
            ? render()
            : children
          )
      }
    </div>
  );
}