import { useCallback, useState } from "react";

import { getAuthorizedAPIDataSharedArgs } from "../fetchers/getAuthorizedAPIData";
import getMemberData, { getMemberDataMainArgs, MemberData } from "../fetchers/getMemberData";

export interface useMemberDataArgs extends getAuthorizedAPIDataSharedArgs {
  
}

export type UseMemberData = ReturnType<typeof useMemberData>;

export default function useMemberData({
  apiURL,
  authorizationToken,
}: useMemberDataArgs) {
  const [data, setData] = useState<MemberData | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const getCurrentMemberData = useCallback(async ({
    lastName,
    memberId,
  }: getMemberDataMainArgs) => {
    if(!apiURL || !authorizationToken) {
      return null;
    }

    setData(null);
    setError(null);
    setIsLoading(true);
    setIsValidating(true);

    let newMemberData: MemberData | null = null;
    
    try {
      const memberData = await getMemberData({
        apiURL,
        authorizationToken,
        lastName,
        memberId,
      });

      newMemberData = memberData;

      setData(memberData);
    } catch(error) {
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
      setIsValidating(false);
    }

    return newMemberData;
  }, [apiURL, authorizationToken])

  return {
    memberData: data,
    memberDataError: error,
    getMemberData: getCurrentMemberData,
    isMemberDataLoading: isLoading,
    isMemberDataValidation: isValidating,
  };
}