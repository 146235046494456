import Input from "../../components/Input";
import TextContent from "../../components/TextContent";
import { CartDonationDonorRecognitionType } from "../../fetchers/getCartData"

export interface DonationTypeOptionProps {
  defaultLabel: string;
  donorRecognitionType: CartDonationDonorRecognitionType;
}

export default function DonorRecognitionOption({
  defaultLabel,
  donorRecognitionType,
}: DonationTypeOptionProps) {
  const inputID = `donor-recognition-type-${donorRecognitionType}`;

  return (
    <li className="donorRecognitionOption">
      <Input
        id={inputID}
        name="donorRecognitionType"
        type="radio"
        value={donorRecognitionType}
      />
      <label
        htmlFor={inputID}
      >
        <TextContent
          contentKey={`donorRecognitionType-${donorRecognitionType}`}
          defaultValue={defaultLabel}
          tag="span"
        />
      </label>
    </li>
  )
}