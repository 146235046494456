import getAuthorizedAPIData, { getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface deleteCartArgs extends getAuthorizedAPIDataSharedArgs {
  id: string;
}

export default async function deleteCart({
  apiURL,
  authorizationToken,
  id,
}: deleteCartArgs) {
  if(!id) {
    return null;
  }

  const endPoint = `api/cart/${id}`;
  const method = "DELETE";

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    endPoint,
    method,
  });
}