import classNames from "classnames";

import MemberJoinButton from "./MemberJoinButton";
import StepsList from "./StepsList";
import Title from "./Title";

import { StepComponentProps } from "../Step";
import Steps from "../Steps";

import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import ScheduleAheadButton from "./ScheduleAheadButton";

export default function Header(props: StepComponentProps) {
  const {
    showDateSelectButton,
    showMemberJoinButton,
    showSteps = true,
  } = props;

  const { values } = useFormikSchemaContext();
  const { stepNumber } = values;
  
  return (
    <>
      <header className={classNames([
        "header",
        `header--${Steps[stepNumber]}`,
      ])}>
        {props.beforeHeader}
        <Title {...props} />
        {showDateSelectButton && <ScheduleAheadButton {...props} />}
        {showMemberJoinButton && <MemberJoinButton {...props} />}
        {showSteps && <StepsList {...props} />}
        {props.afterHeader}
      </header>
    </>
  );
}