import { AppConfiguration } from "../fetchers/getAppConfiguration";
import getLocalizedText from "../formatters/getLocalizedText";

export interface TextContextProps {
  appConfiguration?: AppConfiguration | null;
  className?: string;
  contentKey: string;
  defaultValue?: string;
  tag?: "div" | "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "legend";
}

export default function TextContent({
  appConfiguration,
  className,
  contentKey,
  defaultValue = "",
  tag = "div",
}: TextContextProps) {
  const value = getLocalizedText(appConfiguration, contentKey, defaultValue);

  if(!value) {
    return "";
  }

  const baseClassName = `textContent textContent--${contentKey}`;

  const attributes = {
    className: className ? baseClassName + " " + className : baseClassName,
    dangerouslySetInnerHTML: {
      __html: value
    }
  };

  if(tag === "p") {
    return <p {...attributes} />;
  } else if(tag === "span") {
    return <span {...attributes} />;
  } else if(tag === "h1") {
    return <h1 {...attributes} />;
  } else if(tag === "h2") {
    return <h2 {...attributes} />;
  } else if(tag === "h3") {
    return <h3 {...attributes} />;
  } else if(tag === "h4") {
    return <h4 {...attributes} />;
  } else if(tag === "h5") {
    return <h5 {...attributes} />;
  } else if(tag ==="legend") {
    return <legend {...attributes} />;
  } else {
    return <div {...attributes} />;
  }
}