import { useEffect } from "react";

import { AppConfiguration } from "../fetchers/getAppConfiguration";
import { UseConciergeData } from "../hooks/useConciergeData";

import Input from "./Input";
import LoaderContainer from "./LoaderContainer";
import Modal from "./Modal";
import TextContent from "./TextContent";
import useFormikSchemaContext from "../hooks/useFormikSchemaContext";
import getLocalizedText from "../formatters/getLocalizedText";
import classNames from "classnames";

export interface ConciergeModalProps extends UseConciergeData {
  appConfiguration: AppConfiguration | null | undefined;
  disableCloseOnOutsideClick?: boolean;
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
}

export default function ConciergeModal({
  appConfiguration,
  conciergeCode,
  conciergeData,
  conciergeDataError,
  disableCloseOnOutsideClick = true,
  isConciergeDataLoading,
  isConciergeDataValidating,
  isOpen,
  setConciergeCode,
  setIsOpen,
  setUseConcierge,
}: ConciergeModalProps) {
  const { errors, values, setFieldError, setFieldValue } = useFormikSchemaContext();

  useEffect(() => {
    let conciergeCodeTimeout: NodeJS.Timeout | null = null;
    
    if(conciergeCode !== values.conciergeCode) {
      conciergeCodeTimeout = setTimeout(() => {
        conciergeCodeTimeout = null;
        setConciergeCode(values.conciergeCode?.replace(/[^A-Za-z0-9]+/g, "").toUpperCase());
      }, 500);
    }
    
    return () => {
      if(conciergeCodeTimeout) {
        clearTimeout(conciergeCodeTimeout);
      }
    }
  }, [
    conciergeCode, 
    setConciergeCode,
    values.conciergeCode
  ]);

  useEffect(() => {
    if(conciergeData) {
      if(conciergeData.valid) {
        if(errors.conciergeCode) {
          setFieldError("conciergeCode", undefined);
        }
      } else {
        if(!errors.conciergeCode) {
          setFieldError("conciergeCode", "Invalid concierge code.");
        }
      }
    } else {
      setFieldError("conciergeCode", undefined);
    }
  }, [
    conciergeData,
    errors.conciergeCode, 
    setFieldError
  ]);

  useEffect(() => {
    if(conciergeData?.valid === false && !isOpen) {
      setIsOpen(true);
    }
  }, [conciergeData?.valid, isOpen, setIsOpen]);

  return (
    <Modal
      className="conciergeModal"
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="conciergeModalHeader"
        defaultValue="Enter Concierge Code"
        tag="h3"
      />
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="conciergeModalDescription"
        defaultValue="Enter your concierge code to associate this purchase with your concierge account."
      />
      <Input name={"conciergeCode"} placeholder="CODE"/>
      <LoaderContainer
        appConfiguration={appConfiguration}
        error={conciergeDataError}
        isLoading={isConciergeDataLoading}
        isValidating={isConciergeDataValidating}
      >
        {conciergeData
          ? (
            conciergeData.valid
              ? (
                <dl className="conciergeStatus__list">
                  <TextContent
                    appConfiguration={appConfiguration}
                    contentKey="code"
                    defaultValue="Code"
                    tag="dt"
                  />
                  <dd>
                    <span>{conciergeData.code}</span>
                    &nbsp;
                    <span>{conciergeData.label ? '(' + conciergeData.label + ')' : ''}</span>
                  </dd>
                  {conciergeData.agentName && (
                    <>
                      <TextContent
                        appConfiguration={appConfiguration}
                        contentKey="agent"
                        defaultValue="Agent"
                        tag="dt"
                      />
                      <dd>{conciergeData.agentName}</dd>
                    </>
                  )}
                  {conciergeData.groupName && (
                    <>
                      <TextContent
                        appConfiguration={appConfiguration}
                        contentKey="group"
                        defaultValue="Group"
                        tag="dt"
                      />
                      <dd>{conciergeData.groupName}</dd>
                    </>
                  )}
                </dl>
              )
              : ""
          )
          : ""
        }
      </LoaderContainer>
      <button
        className={classNames({
          "conciergeModal__continueButton": true,
          "conciergeModal__continueButton--isLoading": isConciergeDataLoading,
          "conciergeModal__continueButton--isValidating": isConciergeDataValidating,
          "conciergeModal__continueButton--valid": conciergeData?.valid,
          "conciergeModal__continueButton--invalid": conciergeData?.valid === false,
        })}
        disabled={!conciergeData?.valid}
        onClick={() => {
          setIsOpen(false);
        }}
        type="button"
      >{getLocalizedText(appConfiguration, 'continue', 'Continue')}</button>
      <button
        className={classNames({
          "conciergeModal__cancelButton": true,
          "conciergeModal__cancelButton--isLoading": isConciergeDataLoading,
          "conciergeModal__cancelButton--isValidating": isConciergeDataValidating,
          "conciergeModal__cancelButton--valid": conciergeData?.valid,
          "conciergeModal__cancelButton--invalid": conciergeData?.valid === false,
        })}
        onClick={async () => {
          await setFieldValue("conciergeCode", "");
          setFieldError("conciergeCode", "");
          setIsOpen(false);
          setUseConcierge(false);
        }}
        type="button"
      >{getLocalizedText(appConfiguration, 'cancel', 'Cancel')}</button>
    </Modal>
  )
}