import { LoginType } from ".";
import LoginOption from "./LoginOption";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import getLocalizedText from "../../formatters/getLocalizedText";

export interface GuestLoginOptionProps {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function NonResidentLoginOption(props: GuestLoginOptionProps) {
  const { appConfiguration } = props;

  if(!appConfiguration?.allow_guest_access) {
    return "";
  }

  return (
    <LoginOption
      appConfiguration={appConfiguration}
      defaultTitle={getLocalizedText(
        appConfiguration,
        appConfiguration?.allow_resident_login ? "nonResidentsLabel" : "guestsLabel",
        appConfiguration?.allow_resident_login ? "Non-Residents" : "Guests"
      )}
      isResident={false}
      loginType={LoginType.Guest}    
    />
  );
}