import classNames from "classnames";
import { PropsWithChildren } from "react";

import { LoginType } from ".";

import Input from "../../components/Input";
import TextContent from "../../components/TextContent";
import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import getLocalizedText from "../../formatters/getLocalizedText";
import SubmitButton from "../../components/SubmitButton";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export type LoginOptionProps = PropsWithChildren<{
  appConfiguration: AppConfiguration | null | undefined,
  defaultTitle: string;
  isResident?: boolean | null;
  loginType?: LoginType | null;
  showSubmitButton?: boolean;
  submitButtonDefaultText?: string;
}>;

export default function LoginOption({
  appConfiguration,
  children,
  defaultTitle,
  isResident,
  loginType,
  showSubmitButton = true,
  submitButtonDefaultText = "Select Tickets",
}: LoginOptionProps) {
  const { setFieldValue, validateForm, values } = useFormikSchemaContext();
  const slug = `${loginType ? LoginType[loginType] : defaultTitle.replace(/[^A-Za-z0-9-]+/gi, "")}Login`;

  return (
    <li className={classNames([
      "loginOption",
      `loginOption--${slug}`,
    ])}>
      <Input
        label={getLocalizedText(appConfiguration, `${slug}Title`, defaultTitle)}
        name="loginType"
        type="radio"
        value={loginType || LoginType.Additional}
      />
      <TextContent
        appConfiguration={appConfiguration}
        contentKey={`before${slug}`}
      />
      {children}
      <TextContent
        appConfiguration={appConfiguration}
        contentKey={`after${slug}`}
      />
      {showSubmitButton && <SubmitButton
        ignoreInvalid={values.loginType !== loginType}
        loadingLabel={getLocalizedText(appConfiguration, "defaultLoadingLabel", "Loading...")}
        onClick={async () => {
          let doValidation = values.loginType !== loginType;

          await setFieldValue("loginType", loginType);

          if(typeof isResident === "boolean" && values.isResident !== isResident) {
            await setFieldValue("isResident", isResident);
            doValidation = true;
          }

          if(doValidation) {
            await validateForm();
          }
        }}
      >{getLocalizedText(appConfiguration, `${slug}Submit`, submitButtonDefaultText)}</SubmitButton>}
    </li>
  )
}