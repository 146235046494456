import { StepComponentProps } from "../Step";

import TextContent from "../../components/TextContent";

export default function MemberJoinButton({
  appConfiguration
}: StepComponentProps) {
  if(!appConfiguration?.member_join_link) {
    return "";
  }

  return (
    <a
      className="memberJoinButton headerButton"
      href={appConfiguration.member_join_link}
    >
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="becomeAMember"
        defaultValue="Become a member"
      />
    </a>
  )
}