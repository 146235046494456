import { useCallback, useMemo, useState } from "react";
import useSWRImmutable from "swr/immutable";

import { AppComboTemplateEntrySummary, AppConfiguration, AppTicketCategory } from "../fetchers/getAppConfiguration";
import getTicketData, { getTicketDataArgs, Ticket } from "../fetchers/getTicketData";
import updateSold from "../fetchers/updateSold";
import getLocalizedText from "../formatters/getLocalizedText";
import formatSQLDate from "../formatters/formatSQLDate";

export type useTicketDataArgs = Omit<getTicketDataArgs, "date"> & {
  appConfiguration: AppConfiguration | null | undefined;
}

export interface TicketEvent {
  comboTemplateID: string | null;
  eventID: string;
  eventTemplateID: string;
  ticketSetIndex: number;
}

export interface TicketSet {
  comboTemplateID: string | null;
  description: string;
  image: string | null;
  label: string;
  quantity: number;
  summaries: AppComboTemplateEntrySummary[] | null;
  tickets: Ticket[];
}

export type UseTicketData = ReturnType<typeof useTicketData>;

export default function useTicketData({
  appConfiguration,
  ...props
}: useTicketDataArgs) {
  const [ticketCategoryID, setTicketCategoryID] = useState<number | null>(null);
  const [ticketDate, setTicketDate] = useState<string | null>(null);
  const [ticketEvents, setTicketEvents] = useState<TicketEvent[]>([]);

  // All necessary ticket category information will be contained in computedTicketCategories, even if custom 
  // ticket category information is not configured on the server.
  const ticketCategories = useMemo(() => {
    const computedTicketCategories: AppTicketCategory[] = [];
    const getTemplateSignature = (templateIDs: string[]) => JSON.stringify(templateIDs.sort());
    
    if(appConfiguration?.template_ids.length) {
      const appTemplateSignature = getTemplateSignature(appConfiguration.template_ids)
      const defaultTicketCategory = appConfiguration?.ticket_categories.find(({ template_ids }) => getTemplateSignature(template_ids) === appTemplateSignature);

      if(defaultTicketCategory) {
        defaultTicketCategory.id = computedTicketCategories.length + 1;
        computedTicketCategories.push(defaultTicketCategory);
      } else {
        computedTicketCategories.push({
          id: computedTicketCategories.length + 1,
          app_configuration_id: appConfiguration.id,
          description: getLocalizedText(appConfiguration, "defaultTicketCategoryDescription", ""),
          template_ids: appConfiguration.template_ids,
          label: getLocalizedText(appConfiguration, "defaultTicketCategoryLabel", "General Admission"),
          image: getLocalizedText(appConfiguration, "defaultTicketCategoryImage", "") || null,
          priority: 1,
          created_at: formatSQLDate(new Date()),
          updated_at: formatSQLDate(new Date()),
        });
      }
    }

    if(appConfiguration?.combo_templates.length) {
      appConfiguration.combo_templates.forEach(comboTemplate => {
        const comboTemplateSignature = getTemplateSignature([comboTemplate.template]);
        const comboTemplateCategory = appConfiguration.ticket_categories.find(({ template_ids }) => getTemplateSignature(template_ids) === comboTemplateSignature);

        if(comboTemplateCategory) {
          comboTemplateCategory.id = computedTicketCategories.length + 1;
          computedTicketCategories.push(comboTemplateCategory);
        } else {
          computedTicketCategories.push({
            id: computedTicketCategories.length + 1,
            app_configuration_id: appConfiguration.id,
            description: getLocalizedText(appConfiguration, "defaultComboCategoryDescription", ""),
            template_ids: appConfiguration.template_ids,
            label: comboTemplate.name || getLocalizedText(appConfiguration, "defaultComboCategoryLabel", "Combo"),
            image: getLocalizedText(appConfiguration, "defaultComboCategoryImage", "") || null,
            priority: 0,
            created_at: formatSQLDate(new Date()),
            updated_at: formatSQLDate(new Date()),
          });
        }
      });
    }

    appConfiguration?.ticket_categories.forEach(ticketCategory => {
      if(!computedTicketCategories.find((computedTicketCategory) => getTemplateSignature(ticketCategory.template_ids) === getTemplateSignature(computedTicketCategory.template_ids))) {
        ticketCategory.id = computedTicketCategories.length + 1;

        computedTicketCategories.push(ticketCategory);
      }
    });

    return computedTicketCategories.sort((a, b) => ((b.priority || 0) - (a.priority || 0)) || (b.id - a.id));
  }, [appConfiguration]);

  const ticketCategory = useMemo(
    () => ticketCategories.find(({ id }) => id === ticketCategoryID), 
    [
      ticketCategories,
      ticketCategoryID,
    ]
  );

  const { data: ticketData, error, isLoading, isValidating, mutate } = useSWRImmutable({
    ...props,
    date: ticketDate,
    cacheKey: "ticketData",
  }, getTicketData);

  const ticketSets = useMemo(() => {
    const looseTemplateIDs: string[] = [];
    const computedTicketSets: TicketSet[] = [];
    
    ticketCategory?.template_ids.forEach(templateID => {
      const comboTemplate = appConfiguration?.combo_templates.find(({ template }) => template === templateID);

      if(comboTemplate) {
        comboTemplate.entries.forEach(entry => {
          computedTicketSets.push({
            comboTemplateID: comboTemplate.template,
            description: entry.description || "",
            image: entry.image,
            label: entry.label || "",
            quantity: entry.quantity,
            summaries: entry.summaries || null,
            tickets: ticketData
              ?.filter(ticket => entry.summaries
                ?.map(({ template_id }) => template_id)
                .includes(ticket.template_id)
              ).sort((a, b) => new Date(a.start_time).getTime() 
                - new Date(b.start_time).getTime()
              ) || [],
          });
        });
      } else {
        looseTemplateIDs.push(templateID);
      }
    });

    if(looseTemplateIDs.length) {
      computedTicketSets.push({
        comboTemplateID: null,
        description: ticketCategory?.description || "",
        image: ticketCategory?.image || "",
        label: ticketCategory?.label || "",
        quantity: 0,
        summaries: appConfiguration
          ?.combo_templates
          .flatMap(({ entries }) => entries)
          .flatMap(({ summaries }) => summaries || [])
          .filter(({ template_id }) => looseTemplateIDs.includes(template_id)) 
            || null,
        tickets: ticketData
          ?.filter(ticket => ticketCategory
            ?.template_ids
            .includes(ticket.template_id)
          ).sort((a, b) => new Date(a.start_time).getTime() 
            - new Date(b.start_time).getTime()
          ) || [],
      });
    }

    return computedTicketSets;
  }, [
    appConfiguration?.combo_templates,
    ticketCategory?.description,
    ticketCategory?.image,
    ticketCategory?.label,
    ticketCategory?.template_ids,
    ticketData
  ]);

  const setTicketCategoryIDToDefault = useCallback(() => {
    setTicketCategoryID(ticketCategories[0]?.id || null);
  }, [ticketCategories]);

  const boundUpdateSold = useCallback(async (ticketID: string, sold: number) => {
    const updatedTicket = await updateSold({
      apiURL: props.apiURL,
      authorizationToken: props.authorizationToken,
      ticketID,
      sold,
    });

    if(updatedTicket) {
      const updatedTicketID = updatedTicket?.id;

      await mutate(ticketData?.map(ticket => {
        if(ticket.id === updatedTicketID) {
          return updatedTicket;
        } else {
          return ticket;
        }
      }));
    }
  }, [
    mutate,
    props.apiURL,
    props.authorizationToken,
    ticketData
  ]);

  return {
    isTicketDataLoading: isLoading,
    isTicketDataValidating: isValidating,
    setTicketCategoryID,
    setTicketCategoryIDToDefault,
    setTicketDate,
    setTicketEvents,
    ticketCategories,
    ticketCategory,
    ticketCategoryID,
    ticketData,
    ticketDataError: error,
    ticketDate,
    ticketEvents,
    ticketSets,
    updateSold: boundUpdateSold,
  }
}