export interface CarouselBreakpoint {
  items: number;
  max?: number;
  min?: number;
}

export default function getChildrenToShow(breakpoints: CarouselBreakpoint[]) {
  const windowWidth = window.innerWidth;

  for(const breakpoint of breakpoints) {
    if(breakpoint.min && windowWidth < breakpoint.min) {
      continue;
    }

    if(breakpoint.max && windowWidth > breakpoint.max) {
      continue;
    }

    return breakpoint.items;
  }

  throw new Error(`No breakpoint found for width ${windowWidth}`);
};
