import getRender, { getRenderArgs } from "./getRender";
import useVoucherCodes from "./useVoucherCodes";

import { ButtonModal, ButtonModalProps } from "../Modal";
import getLocalizedText from "../../formatters/getLocalizedText";

export type VoucherButtonModalProps = Omit<ButtonModalProps, "render"> 
  & Partial<Pick<ButtonModalProps, "label">>
  & getRenderArgs;

export default function VoucherButtonModal({
  appConfiguration,
  getVoucherData,
  isVoucherDataLoading,
  isVoucherDataValidating,
  label = "",
  onCloseButtonClick,
  setFieldValue,
  ticketCategories,
  values,
  voucherData,
  voucherDataError,
  ...props
}: VoucherButtonModalProps) {
  useVoucherCodes({ setFieldValue, values, voucherData });
  
  if(!label) {
    label = getLocalizedText(appConfiguration, "enterVouchers", "Enter Vouchers");
  }

  return (
    <ButtonModal
      {...props}
      label={label}
      render={getRender({
        appConfiguration,
        getVoucherData,
        isVoucherDataLoading,
        isVoucherDataValidating,
        onCloseButtonClick,
        ticketCategories,
        voucherData,
        voucherDataError,
        setFieldValue,
        values,
      })}
    />
  );
}