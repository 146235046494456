import { Cart, CartComboItemAddOn, CartComboItemEvent, CartComboItemTicket, CartItem, CartMembershipInfo, CartSaleChannel } from "./getCartData";
import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export type CreateCartItem = Partial<CartItem>
  & Required<Pick<CartItem, "quantity" | "itemType">>;

export type CreateCartDonationItem = CreateCartItem 
  & Required<Pick<CreateCartItem, "inventoryId" | "donationDetail">>
  & {
    itemType: "Inventory";
  }

export type CreateCartEventItem = CreateCartItem 
  & Required<Pick<CreateCartItem, "eventId" | "ticketingTypeId">>
  & {
    itemType: "Event";
  }

export type CreateCartMembershipItem = CreateCartItem
  & {
    membershipInfo: CartMembershipInfo,
    itemType: "MembershipPurchase";
  }

export type CreateAnyCartItem = CreateCartItem
  | CreateCartDonationItem
  | CreateCartEventItem
  | CreateCartMembershipItem;

export type CreateCartComboItemEvent = Pick<CartComboItemEvent,
  "eventTemplateId"
  | "eventId"
>;

export type CreateCartComboItemTicket = Pick<CartComboItemTicket, 
  "eventTemplateId"
  | "ticketTypeId"
  | "quantity"
>;

export type CreateCartComboItemAddOn = Pick<CartComboItemAddOn,
  "addOnId"
  | "quantity"
>;

export interface CreateCartComboItem {
  comboTemplateId: string;
  events?: CreateCartComboItemEvent[];
  tickets?: CreateCartComboItemTicket[];
  addOns?: CreateCartComboItemAddOn[];
}

export interface createCartMainArgs {
  comboItems?: CreateCartComboItem[];
  couponCodes?: string[];
  deleteCart?: boolean;
  items?: CreateAnyCartItem[];
  membershipIds?: string[];
  membershipCategoryId?: string;
  organization_id?: number;
  retrieveFullCart?: boolean;
  saleChannel: CartSaleChannel;
  verifyEntitlements?: boolean;
}

export type createCartArgs = createCartMainArgs & getAuthorizedAPIDataSharedArgs;

export default async function createCart({
  apiURL,
  authorizationToken,
  ...data
}: createCartArgs): APIResponse<Cart> {
  const endPoint = "api/cart";
  const method = "POST";

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
    method,
  });
}