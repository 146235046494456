import { goToPreviousStep } from "..";
import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

import { StepComponentProps } from "../Step";

export default function CheckoutError({
  appConfiguration,
  checkoutError,
  onStep,
}: StepComponentProps) {
  const { values, setFieldValue } = useFormikSchemaContext();

  if(!checkoutError) {
    return "";
  }

  let errorMessage = "";
  let goToBilling = false;

  if(typeof checkoutError === "string") {
    errorMessage = checkoutError;
  } else if(typeof checkoutError === "object" && "message" in checkoutError) {
    errorMessage = (checkoutError as unknown as Record<"message", string>).message;
    goToBilling = true;
  } else if(typeof checkoutError === "object" && "errorMessage" in checkoutError) {
    errorMessage = (checkoutError as unknown as Record<"errorMessage", string>).errorMessage;
    goToBilling = true;
  }

  if(!errorMessage) {
    errorMessage = getLocalizedText(appConfiguration, "defaultOrderError", "There was a problem processing your order.");
  }

  return (
    <div className="checkoutError">
      <p className="checkoutError__message">{errorMessage}</p>
      {goToBilling && (
        <p className="checkoutError__goToBilling">
          <a
            href="#"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();

              await goToPreviousStep(
                values.stepNumber,
                values.stepsEnabled,
                setFieldValue,
                onStep
              );
            }}
          >{getLocalizedText(appConfiguration, "goToBilling", "Go to billing")}</a>.
        </p>
      )}
    </div>
  );
}