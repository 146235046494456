import classNames from "classnames";
import { ErrorMessage, useField } from "formik";
import { PropsWithChildren } from "react";

import { Schema } from "../schema";

export interface FieldWrapperCoreProps {
  id?: string;
  label?: string;
  name: keyof Schema | string;
  showError?: boolean;
  showLabel?: boolean;
  type?: string;
}

export type FieldWrapperProps = PropsWithChildren<FieldWrapperCoreProps>;

export default function FieldWrapper({
  children,
  id,
  label,
  name,
  showError = true,
  showLabel = true,
  type,
}: FieldWrapperProps) {
  const { error, touched, value } = useField(name)[1];

  if(showLabel && label && !id) {
    id = name;
    
    if(type && /--(input|radio)$/.test(type)) {
      id += "-" + value.replace(/[^A-Za-z0-9-]+/g, "");
    }
  }
  
  return (
    <div className={classNames({
      "field__wrapper": true,
      "field__wrapper--error": error,
      "field__wrapper--touched": touched,
      [`field__wrapper--${name}`]: true,
      [`field__wrapper--${type}`]: type,
    })}>
      {showLabel && label && <label className="field__label" htmlFor={id}>{label}</label>}
      {children}
      {showError && <ErrorMessage
        component="span"
        className="field__error"
        name={name}
      />}
    </div>
  );
}