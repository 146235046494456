import classNames from "classnames";

import StepsList from "./StepsList";
import Title from "./Title";

import { StepComponentProps } from "../Step";
import Steps from "../Steps";

import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import ScheduleAheadButton from "./ScheduleAheadButton";
import ConciergeNotice from "../../components/ConciergeNotice";

export default function Header(props: StepComponentProps) {
  const {
    showDateSelectButton,
    showSteps = true,
  } = props;

  const { values } = useFormikSchemaContext();
  const { stepNumber } = values;
  
  return (
    <>
      <header className={classNames([
        "header",
        `header--${Steps[stepNumber]}`,
      ])}>
        {props.beforeHeader}
        <Title {...props} />
        {showDateSelectButton && <ScheduleAheadButton {...props} />}
        {showSteps && <StepsList {...props} />}
        <ConciergeNotice {...props} />
        {props.afterHeader}
      </header>
    </>
  );
}