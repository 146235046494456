import { useCallback, useState } from "react";

import postCORSCheckout, { acmeCORSSharedArgs, postCORSCheckoutMainArgs, Order } from "../fetchers/postCORSCheckout";

export interface useCORSCheckoutArgs extends Omit<acmeCORSSharedArgs, "recaptchaToken"> {

}

export interface PostCORSCheckoutArgs extends postCORSCheckoutMainArgs {
  recaptchaToken?: string | null;
}

export type PostCORSCheckoutFunction = (checkout: PostCORSCheckoutArgs) => Promise<Order | null>;

export type UseCORSCheckout = ReturnType<typeof useCORSCheckout>;

export default function useCORSCheckout({
  apiURL,
  authorizationToken,
  corsURL,
  publicKey,
  tenantID,
}: useCORSCheckoutArgs) {
  const [data, setData] = useState<Order | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const boundPostCORSCheckout: PostCORSCheckoutFunction = useCallback(async (checkout) => {
    let order: Order | null = null;

    try {
      setIsLoading(true);
      setIsValidating(true);
      setError(null);

      order = await postCORSCheckout({
        apiURL,
        authorizationToken,
        corsURL,
        publicKey,
        tenantID,
        ...checkout,
      });

      setData(order);
    } catch(error) {
      setError(error);
    } finally {
      setIsLoading(false);
      setIsValidating(false);
    }

    return order;
  }, [
    apiURL,
    authorizationToken,
    corsURL,
    publicKey,
    tenantID
  ]);

  return {
    checkoutOrder: data,
    checkoutError: error,
    isCheckoutLoading: isLoading,
    isCheckoutValidating: isValidating,
    postCORSCheckout: boundPostCORSCheckout,
  };
}