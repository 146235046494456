import Calendar from "react-calendar";

import { StepComponentProps } from "../Step";

import { ButtonModal } from "../../components/Modal";
import getLocalizedText from "../../formatters/getLocalizedText";

export default function ScheduleAheadButton({
  appConfiguration,
  readFrom,
  ticketDate,
}: StepComponentProps) {
  return (
    <ButtonModal
      label={getLocalizedText(appConfiguration, "scheduleAhead", "Schedule Ahead")}
      render={({ close }) => (
        <>
          <Calendar
            onChange={(selectedDate) => {
              if(selectedDate && selectedDate instanceof Date) {
                close();
                readFrom(selectedDate, 7, true);
              }
            }}
            locale={appConfiguration?.locale}
            minDate={appConfiguration?.start_date ? new Date(appConfiguration.start_date) : new Date()}
            value={ticketDate ? new Date(ticketDate) : undefined}
          />
          <button
            onClick={close}
          >Close</button>
        </>
      )}
    />
  );
}