import { useEffect, useState } from "react";

import Section from "./Section";

import { StepComponentProps } from "../Step";
import LoaderContainer from "../../components/LoaderContainer";
import TextContent from "../../components/TextContent";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getLocalizedText from "../../formatters/getLocalizedText";
import ToggleContainer from "../../components/ToggleContainer";

export default function Info({
  appConfiguration,
  getMemberLevel,
  memberLevelDataError,
  memberLevelDataIsLoading,
  memberLevelDataIsValidating,
}: StepComponentProps) {
  const { values, setFieldValue } = useFormikSchemaContext();
  const [showDescription, setShowDescription] = useState(false);

  const activeMemberLevel = getMemberLevel(values.membershipLevelSlug);

  useEffect(() => {
    if(values.membershipLevelID !== activeMemberLevel?.id) {
      setFieldValue("membershipLevelID", activeMemberLevel?.id || "");
    }
  }, [
    activeMemberLevel?.id,
    setFieldValue,
    values.membershipLevelID
  ]);

  return (
    <LoaderContainer
      appConfiguration={appConfiguration}
      error={memberLevelDataError}
      isLoading={memberLevelDataIsLoading}
      isValidating={memberLevelDataIsValidating}
      render={() => activeMemberLevel
        ? (
          <Section
            appConfiguration={appConfiguration}
            defaultHeaderLabel={activeMemberLevel.name}
            price={activeMemberLevel.offerings[0]?.priceLists[0]?.prices[0]?.price}
            slug="info"
          >
            {activeMemberLevel.shortDescription && <div
              className="membershipReview__info__description"
              dangerouslySetInnerHTML={{ __html: activeMemberLevel.shortDescription}} 
            />}
            <ul className="membershipReview__info__list">
              {activeMemberLevel.benefits.map(({ description }, benefitIndex) => (
                <li
                  className="membershipReview__info__list__item"
                  key={benefitIndex}
                >{description}</li>
              ))}
            </ul>
            {activeMemberLevel.description && (
              <div className="membershipReview__info__description">
                <button
                  className="membershipReview__info__description__toggle"
                  onClick={() => setShowDescription(!showDescription)}
                  type="button"
                >{getLocalizedText(appConfiguration, "moreInformation", "More Information")}</button>
                <ToggleContainer
                  className="membershipReview__info__description__content"
                  renderWhenHidden={true}
                  visible={showDescription}
                >
                  <div dangerouslySetInnerHTML={{ __html: activeMemberLevel.description }} />
                </ToggleContainer>
              </div>
            )}
          </Section>
        )
        : (
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="noMembershipLevelMessage"
            defaultValue="Membership level not found."
          />
        )
      }
    />
  )
}