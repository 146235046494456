import AddOnsList from "./AddOnsList";
import NoAddOns from "./NoAddons";

import StepComponent, { Step, StepComponentProps } from "../Step";
import Steps from "../Steps";
import AddOnsSummary from "../../components/AddOnsSummary";
import getPriceFormatter from "../../formatters/getPriceFormatter";

const Component = (props: StepComponentProps) => {
  const { appConfiguration } = props;
  const donationTypes = ["donation", "fixedDonation"];
  const addons = props?.appConfiguration?.addons.filter(({ type }) => !donationTypes.includes(type))
  
  const format = getPriceFormatter(appConfiguration?.locale, true);

  return (
    <>
      <StepComponent {...props}>
        {addons?.length
          ? <>
              <AddOnsList
                addons={addons}
                format={format}
              />
              <AddOnsSummary {...props} />
            </>
          : <NoAddOns {...props} />
        }
      </StepComponent>
    </>
  );
}

const AddOns: Step = {
  Component,
  defaultLabel: "Add-ons",
  isMembershipStep: false,
  isTicketStep: true,
  labelKey: "addOns",
  liveCart: true,
  stepNumber: Steps.Addons,
};

export default AddOns;
