import { useEffect, useState } from "react";

import checkResidentLocation from "./checkResidentLocation";
import AdditionalLoginOptions from "./AdditionalLoginOptions";
import ConciergeModal from "../../components/ConciergeModal";
import GuestLoginOption from "./GuestLoginOption";
import MemberLoginOption from "./MemberLoginOption";
import ResidentLoginOption from "./ResidentLoginOption";
import VoucherOption from "./VoucherOption";

import { goToNextStep } from "..";
import Steps from "../Steps";
import StepComponent, { Step, StepComponentProps, StepSubmitHandler } from "../Step";

import TextContent from "../../components/TextContent";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getLocalizedText from "../../formatters/getLocalizedText";
import { getZIPCodeInfo } from "./getZIPCodeInfo";
import ConciergeNotice from "../../components/ConciergeNotice";

export enum LoginType {
  Additional = "Additional",
  Guest = "Guest",
  Member = "Member",
  Resident = "Resident",
}

export const loginTypes = [
  LoginType.Additional,
  LoginType.Guest,
  LoginType.Member,
  LoginType.Resident,
];

const onSubmit: StepSubmitHandler = async ({
  appConfiguration,
  countryData,
  getMemberData,
  getVoucherData,
  helpers,
  onResidentZipCode,
  onStep,
  setMembershipID,
  stateData,
  values,
}) => {
  let loginValid = false;
  
  const voucherCodes = values
    .voucherCodes
    ?.map(voucherCode => voucherCode ? voucherCode : "")
    ?.filter(voucherCode => voucherCode.trim())
    ?.sort()
    ?.filter((voucherCode, voucherCodeIndex, voucherCodes) => !voucherCodeIndex || voucherCode !== voucherCodes[voucherCodeIndex - 1])

  if(voucherCodes.length) {
    helpers.setFieldValue("voucherCodes", voucherCodes);
    
    const voucherCodeResults = await Promise.all(voucherCodes.map((voucherCode) => getVoucherData({ voucherCode })))
    let hasInvalidCodes = false;

    voucherCodeResults.forEach((result, index) => {
      let error: string | undefined = undefined;

      if(!result) {
        error = getLocalizedText(appConfiguration, "invalidVoucherError", "Voucher code is not valid.");
      } else if(result.is_expired) {
        error = getLocalizedText(appConfiguration, "expiredVoucherError", "Voucher code has expired.");
      } else if(result.is_redeemed) {
        error = getLocalizedText(appConfiguration, "redeemedVoucherError", "Voucher code has already been redeemed.");
      }
      
      if(error) {
        hasInvalidCodes = true;
      }

      helpers.setFieldError(`voucherCodes.${index}`, error);
    });

    if(hasInvalidCodes) {
      loginValid = false;
    } else {
      loginValid = true;
    }
  } else if(values.loginType === LoginType.Guest) {
    loginValid = true;
  } else if(values.loginType === LoginType.Member) {
    try {
      const memberData = await getMemberData({
        lastName: values.membershipLastName,
        memberId: values.membershipNumber,
      });

      if(memberData?.primaryCardHolder) {
        loginValid = true;
        setMembershipID(memberData.primaryCardHolder.categoryId);
        
        const isResident = !!(memberData.primaryCardHolder.zipCode && checkResidentLocation(appConfiguration, memberData.primaryCardHolder.zipCode));

        if(onResidentZipCode && memberData.primaryCardHolder.zipCode) {
          onResidentZipCode(memberData.primaryCardHolder.zipCode, isResident);
        }

        if(values.isResident !== isResident) {
          await helpers.setFieldValue("isResident", isResident);
        }
      } else {
        helpers.setFieldError("membershipNumber", "Membership number or last name is incorrect.");
      }
    } catch(error) {
      helpers.setFieldError("membershipNumber", "There was a problem checking the membership number. Please refresh the page and try again.");
    }
  } else if(values.loginType === LoginType.Resident) {
    const residentZIPCode = values.residentZIPCode;
    const checkResult = checkResidentLocation(appConfiguration, residentZIPCode);

    if(onResidentZipCode) {
      onResidentZipCode(residentZIPCode, checkResult);
    }

    if(checkResult) {
      helpers.setFieldError("residentZIPCode", undefined);
      await helpers.setFieldValue("zip", residentZIPCode);

      const locationInfo = getZIPCodeInfo(residentZIPCode);
      
      if(locationInfo) {
        const country = countryData?.find(country => country.alpha_2_code === locationInfo.country)?.name || locationInfo.country;
        const state = stateData?.find(state => state.abbreviation === locationInfo.state)?.name || locationInfo.state;

        await helpers.setFieldValue("city", locationInfo.city);
        await helpers.setFieldValue("state", state);
        await helpers.setFieldValue("country", country);
      }

      loginValid = true;
    } else {
      helpers.setFieldError("residentZIPCode", "Not a resident ZIP code.");
    }
  }
  
  if(loginValid) {
    await helpers.setFieldValue("tickets", []);
    await helpers.setFieldValue("ticketsConfirmed", "");
    await goToNextStep(values.stepNumber, values.stepsEnabled, helpers.setFieldValue, onStep);
  }
}

const Component = (props: StepComponentProps) => {
  const {
    appConfiguration,
    promptConcierge,
    setSummaryCategory,
  } = props;

  const [conciergeModalIsOpen, setConciergeModalIsOpen] = useState(promptConcierge);
  
  const {
    values,
  } = useFormikSchemaContext();

  useEffect(() => {
    if(values.loginType === LoginType.Member) {
      setSummaryCategory(appConfiguration?.member_summary_category || appConfiguration?.summary_category);
    } else if(values.loginType === LoginType.Resident) {
      setSummaryCategory(appConfiguration?.resident_summary_category || appConfiguration?.summary_category);
    } else {
      setSummaryCategory(appConfiguration?.summary_category);
    }
  }, [
    appConfiguration?.member_summary_category,
    appConfiguration?.resident_summary_category,
    appConfiguration?.summary_category,
    setSummaryCategory,
    values.loginType
  ]);

  let submitButtonLabel: string;

  switch(values.loginType) {
    case LoginType.Additional: submitButtonLabel = getLocalizedText(appConfiguration, "getAdditionalInformation", "Get Additional Information"); break;
    case LoginType.Guest: submitButtonLabel = getLocalizedText(appConfiguration, "proceedAsGuest", "Proceed as Guest"); break;
    case LoginType.Member: submitButtonLabel = getLocalizedText(appConfiguration, "signIn", "Sign In"); break;
    case LoginType.Resident: submitButtonLabel = getLocalizedText(appConfiguration, "verifyZIPCode", "Verify ZIP Code"); break;
    default: throw("Invalid login type");
  }

  return (
    <StepComponent
      {...props}
      defaultHeaderLabel="Select an Option"
      headerLabelKey="selectAnOption"
      submitButtonLabel={submitButtonLabel}
      showFooter={false}
      showHeader={false}
      showSteps={false}
    >
      <ConciergeNotice {...props} />
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="beforeLoginOptions"
      />
      <div className="login__options">
        <ul className="login__options__list">
          <GuestLoginOption appConfiguration={appConfiguration} />
          <ResidentLoginOption appConfiguration={appConfiguration} />
          <AdditionalLoginOptions appConfiguration={appConfiguration} />
          <MemberLoginOption appConfiguration={appConfiguration} />
          <VoucherOption {...props} />
          <ConciergeModal {...props} isOpen={conciergeModalIsOpen} setIsOpen={setConciergeModalIsOpen} />
        </ul>
      </div>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="afterLoginOptions"
      />
    </StepComponent>
  );
}

const Login: Step = {
  Component,
  defaultLabel: "Login",
  isMembershipStep: false,
  isTicketStep: true,
  labelKey: "login",
  onSubmit,
  stepNumber: Steps.Login,
}

export default Login;
