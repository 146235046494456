import CheckoutError from "./CheckoutError";
import PromoCode from "./PromoCode";
import Recaptcha from "./Recaptcha";

import StepComponent, { Step, StepComponentProps, StepSubmitHandler } from "../Step";
import Steps from "../Steps";

import AddOnsSummary from "../../components/AddOnsSummary";
import TicketConfirmation from "../../components/TicketConfirmation";
import { goToNextStep } from "..";
import { PostCORSCheckoutArgs } from "../../hooks/useCORSCheckout";

import { getVoucherCodeDistribution } from "../../hooks/useScheduleCartUpdate";

const onSubmit: StepSubmitHandler = async ({
  appConfiguration,
  checkoutOrder,
  cartData,
  helpers,
  isCheckoutLoading,
  isCheckoutValidating,
  postCORSCheckout,
  redeemVoucher,
  ticketData,
  ticketDate,
  updateSold,
  voucherData,
  values,
}) => {
  if(!checkoutOrder?.id && !isCheckoutLoading && !isCheckoutValidating) {
    let checkoutArgs: PostCORSCheckoutArgs = {
      address1: values.streetAddress1,
      address2: values.streetAddress2,
      billingEmail: values.email,
      email: values.email,
      city: values.city,
      contactFirstName: values.firstName,
      contactLastName: values.lastName,
      contactFullName: [values.firstName || "", values.lastName || ""].filter(v => v).join(" "),
      country: values.country,
      phoneNumber: values.phoneNumber,
      recaptchaToken: appConfiguration?.recaptcha_site_key_v3 && values.recaptchaV3Token,
      shoppingCartId: cartData?.id,
      state: values.state,
      zipCode: values.zip,
    };

    if(values.paymentRequired) {
      checkoutArgs = {
        ...checkoutArgs,
        billingAddress1: values.streetAddress1,
        billingAddress2: values.streetAddress2,
        billingCity: values.city,
        billingCountry: values.country,
        billingFirstName: values.firstName,
        billingLastName: values.lastName,
        billingPhoneNumber: values.phoneNumber,
        billingState: values.state,
        billingZipCode: values.zip,
        cvc: values.securityCode,
        expDate: values.expirationMonth + values.expirationYear.toString().substring(2),
        manualEntryCardNumber: values.cardNumber,
      }
    }

    const order = await postCORSCheckout(checkoutArgs);

    if(order?.id) {
      const updatePromises: Promise<unknown>[] = [];

      if(cartData?.items?.length) {
        for(const item of cartData.items) {
          const ticket = ticketData?.find(ticket => ticket.event_id === item.eventId);

          if(ticket) {
            updatePromises.push(updateSold(ticket.id.toString(), item.quantity));
          }
        }
      }

      if(cartData?.comboItems?.length) {
        for(const comboItem of cartData.comboItems) {
          for(const comboTicket of comboItem.tickets) {
            const comboEvent = comboItem.events.find(comboEvent => comboEvent.eventTemplateId === comboTicket.eventTemplateId);
            const ticket = ticketData?.find(ticket => ticket.event_id === comboEvent?.eventId);

            if(ticket) {
              updatePromises.push(updateSold(ticket.id.toString(), comboTicket.quantity));
            }
          }
        }
      }

      if(values.voucherCodes.length) {
        const voucherCodeDistribution = getVoucherCodeDistribution({
          ticketData,
          values,
          voucherData,
        });

        const usedVoucherCodes = Object
          .values(voucherCodeDistribution)
          .flat()
          .map(vouchers => vouchers.voucherCode);
        
        if(usedVoucherCodes.length) {
          usedVoucherCodes.forEach(voucherCode => updatePromises.push(redeemVoucher({
            orderNumber: order.id,
            visitDate: ticketDate || new Date(),
            voucherCode,
          })));
        }
      }

      await Promise.all(updatePromises);
      await goToNextStep(values.stepNumber, values.stepsEnabled, helpers.setFieldValue, helpers.setFieldTouched);
    }
  }
};

const Component = (props: StepComponentProps) => {
  return (
    <StepComponent
      {...props}
      submitButtonLabel="Place Order"
    >
      <TicketConfirmation {...props} showTotal={false} />
      <AddOnsSummary {...props} showContent={false} showIfBlank={false} />
      <PromoCode {...props} />
      <Recaptcha {...props} />
      <CheckoutError {...props} />
    </StepComponent>
  )
};

const Review: Step = {
  Component,
  defaultLabel: "Review",
  isMembershipStep: true,
  isTicketStep: true,
  labelKey: "review",
  liveCart: true,
  onSubmit,
  stepNumber: Steps.Review,
};

export default Review;
