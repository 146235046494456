//import { ACMEFraudShield } from "@acmepayments/fraud-shield";

export type ContainerSelector = () => HTMLElement;

export interface ContainerParam {
    selector: ContainerSelector;
}

export interface WorkflowFlowParams {
    amount: string;
    externalId?: string;
    billingAddress1?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingState?: string;
    billingCountryCode?: string;
    billingFirstName: string;
    billingLastName: string;
    billingPostalCode: string;
    billingPhone?: string;
    cardExpMonth: string;
    cardExpYear: string;
    cardNumber: string;
    email: string;
    container?: ContainerParam;
}

interface CheckFraudShieldConfig {
    mid: string;
    publishableKey: string;
    baseUrl?: string;
    environmentName?: "sandbox";
}

export type ACMEFraudShieldCreateArgs = CheckFraudShieldConfig;
export type ACMEFraudShieldCreate = (args: ACMEFraudShieldCreateArgs) => ACMEFraudShield;

export interface ACMEFraudShield {
    create(args: ACMEFraudShieldCreateArgs): ACMEFraudShield;
    handle3DSSession: (params: WorkflowFlowParams) => Promise<ACMEFraudShieldResult>;
}

export interface ACMEFraudShieldResult {
    acmeSessionId: string;
    status: string;
    providerRefId: string;
    paymentProcessor: number;
    saleChannel: string;
    firstName: string;
    lastName: string;
    address1: string;
    zipCode: string;
    authChallengeCallbackRecordedOn: number;
    cavv: string;
    eci: string;
    threeDSProviderTransactionId: string;
    createdOn: number;
    updatedOn: number;
}

export type CheckFraudShieldArgs = CheckFraudShieldConfig & WorkflowFlowParams;

export interface ACME {
    ACMEFraudShield: ACMEFraudShield;
}
interface ACMEWindow extends Window {
    ACME?: ACME;
}

function loadFraudShieldScript(useSandBox: boolean) {
    return new Promise<void>((resolve, reject) => {
        const script = document.createElement("script");

        script.src = useSandBox
            ? "https://sand10-cdn.acmeticketing.net/js/@acmepayments/fraud-shield@1.1.0/index.umd.js"
            : "https://cdn.acmeticketing.com/js/@acmepayments/fraud-shield@1.1.0/index.umd.js";

        script.onload = () => resolve();
        script.onerror = () => reject(new Error("Could not load fraud shield script."));

        document.head.appendChild(script);
    });
}

async function getFraudShieldInstance(useSandBox: boolean) {
    let ACME = (window as ACMEWindow).ACME;

    if(!ACME) {
        await loadFraudShieldScript(useSandBox);
        ACME = (window as ACMEWindow).ACME;
    }

    return ACME!.ACMEFraudShield;
}

export interface checkFraudShieldResult {
    error: unknown;
    result: ACMEFraudShieldResult | null;
    success: boolean;
}

export default async function checkFraudShield({
    mid,
    publishableKey,
    baseUrl,
    environmentName,
    ...workflow
}: CheckFraudShieldArgs) {
    const acmeFraudShield = (await getFraudShieldInstance(environmentName === "sandbox")).create({
        mid,
        publishableKey,
        baseUrl,
        environmentName,
    });

    try {
        const result = await acmeFraudShield.handle3DSSession(workflow) as ACMEFraudShieldResult;
        
        return {
            error: null,
            result,
            success: true,
        } as checkFraudShieldResult;
    } catch(error) {
        console.error(error);

        return {
            error,
            result: null,
            success: false,
        } as checkFraudShieldResult;
    }
}