import getAuthorizedAPIData, { getAuthorizedAPIDataSharedArgs, APIResponse } from "./getAuthorizedAPIData";
import formatSQLDate from "../formatters/formatSQLDate";

export interface getDailyTicketDataArgs extends getAuthorizedAPIDataSharedArgs {
  date: Date;
  days: number;
  membershipID?: string | null;
  summaryCategory?: string | null;
  templateIDs: string[];
}

export interface DailyTicketDataItem {
  min_discounted_price: number;
  min_price: number;
  min_seats_available: number;
  min_seats_capacity: number;
  min_seats_sold: number;
  max_discounted_price: number;
  max_price: number;
  max_seats_available: number;
  max_seats_capacity: number;
  max_seats_sold: number;
  ticket_date: string;
  total_seats_available: number;
  total_seats_capacity: number;
  total_seats_sold: number;
}

export type DailyTicketData = DailyTicketDataItem[];

export default async function getDailyTicketData({
  apiURL,
  authorizationToken,
  date,
  days,
  membershipID,
  summaryCategory,
  templateIDs,
}: getDailyTicketDataArgs): APIResponse<DailyTicketData> {
  const endPoint = "api/tickets/daily";

  const data = {
    date: formatSQLDate(date),
    days: days.toString(),
    membership_id: membershipID,
    summary_category: summaryCategory,
    template_ids: templateIDs.join(","),
  };

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
  });
}