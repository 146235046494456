import { Form as FormikForm } from "formik";
import { useEffect, useRef } from "react";

import { SharedStepProps } from "../steps/Step";
import { getStep, getFirstActiveStep } from "../steps";
import useFormikSchemaContext from "../hooks/useFormikSchemaContext";
import useScheduleCartUpdate from "../hooks/useScheduleCartUpdate";

export interface FormProps extends SharedStepProps {

}

export default function Form(props: FormProps) {
  const formRef = useRef<HTMLFormElement | null>(null);

  const hookedScheduleCartUpdate = useScheduleCartUpdate(props);
  const { values } = useFormikSchemaContext();
  const step = getStep(values.stepNumber);

  useEffect(() => {
    let top = 0;

    if(values.stepNumber !== getFirstActiveStep(values.stepsEnabled)?.stepNumber) {
      if(formRef.current) {
        top = formRef.current.offsetTop;
      }

      const header = document.querySelector<HTMLElement>(".header");

      if(header) {
        top -= header.offsetHeight;
        top = Math.max(top, 0);
      }
    }

    window.scroll({
      behavior: "instant",
      top,
    });
  }, [values.stepNumber, values.stepsEnabled]);

  if(!step) {
    return "";
  }

  return (
    <FormikForm ref={formRef}>
      <step.Component {...props} {...hookedScheduleCartUpdate} />
    </FormikForm>
  );
}