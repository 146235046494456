import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface MembershipRestriction {
  applies_to_id: string;
  applies_to_name: string;
  id: number;
  membership_category_id: string;
  quantity: number;
  template_id: string;
  time_frame_period: "string";
  time_frame_value: "string";
}

export interface getMembershipRestrictionDataMainArgs {
  membershipCategoryID: string;
  templateIDs: string[];
}

export type getMembershipRestrictionDataArgs = getAuthorizedAPIDataSharedArgs & getMembershipRestrictionDataMainArgs;

export default async function getMembershipRestricionData({
  apiURL,
  authorizationToken,
  membershipCategoryID,
  templateIDs,
}: getMembershipRestrictionDataArgs): APIResponse<MembershipRestriction[]> {
  if(!membershipCategoryID || !templateIDs?.length) {
    return null;
  }

  const endPoint = "api/membership-restrictions";

  const data = {
    membership_category_id: membershipCategoryID,
    template_ids: templateIDs.join(","),
  };

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
  });
}