import classNames from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import LoadingIndicator from "../components/LoadingIndicator";
import useFormikSchemaContext from "../hooks/useFormikSchemaContext";

type MouseEventArg = React.MouseEvent<HTMLButtonElement, MouseEvent>;
type SyncMouseHandler = (e: MouseEventArg) => void;
type AsyncMouseHandler = (e: MouseEventArg) => Promise<void>;
export interface SubmitButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  ignoreInvalid?: boolean;
  loadingLabel: string;
  onClick?: SyncMouseHandler | AsyncMouseHandler;
}

export default function SubmitButton({
  children,
  className,
  disabled,
  ignoreInvalid = false,
  loadingLabel,
  onClick,
  type = "submit",
  ...buttonProps
}: SubmitButtonProps) {
  const { isSubmitting, isValid, submitForm } = useFormikSchemaContext();

  const isDisabled = (disabled || isSubmitting || (!isValid && !ignoreInvalid));

  return (
    <button
      {...buttonProps}
      className={classNames({
        "submitButton": true,
        "submitButton--submitting": isSubmitting,
        "submitButton--valid": isValid,
        "submitButton--invalid": !isValid,
        "submitButton--disabled": isDisabled,
        [className + '']: className,
      })}
      disabled={isDisabled}
      onClick={onClick
        ? async (e) => {
          e.preventDefault();
          await onClick(e);
          await submitForm();
        }
        : undefined
      }
      type={type}
    >
      {children}
      <LoadingIndicator
        isLoading={isSubmitting}
        label={loadingLabel}
      />
    </button>
  );
}