import { useCallback, useState } from "react";
import useSWRImmutable from "swr/immutable";

import getConciergeData, { getConciergeDataArgs } from "../fetchers/getConciergeData";
import postConciergeOrder from "../fetchers/postConciergeOrder";

export interface useConciergeDataArgs extends getConciergeDataArgs {

}

export type UseConciergeData = ReturnType<typeof useConciergeData>;

export default function useConciergeData({
  apiURL,
  authorizationToken,
  code: initialCode,
}: useConciergeDataArgs) {
  const [conciergeCode, setConciergeCode] = useState(initialCode);
  const [useConcierge, setUseConcierge] = useState(initialCode || initialCode === "");

  const { data, error, isLoading, isValidating } = useSWRImmutable({
    apiURL,
    authorizationToken,
    cacheKey: `conciergeData-${conciergeCode}`,
    code: conciergeCode,
  }, getConciergeData);

  const boundPostConciergeOrder = useCallback(
    (orderID: number) => postConciergeOrder({
      apiURL,
      authorizationToken,
      codeID: data?.codeID,
      orderID,
    }),
    [apiURL, authorizationToken, data?.codeID]
  );

  return {
    conciergeCode,
    conciergeData: data,
    conciergeDataError: error,
    isConciergeDataLoading: isLoading,
    isConciergeDataValidating: isValidating,
    postConciergeOrder: boundPostConciergeOrder,
    promptConcierge: initialCode === "",
    setConciergeCode,
    setUseConcierge,
    useConcierge,
  }
}