import { createCartArgs } from "./createCart";
import getAuthorizedAPIData, { APIResponse } from "./getAuthorizedAPIData";
import { Cart } from "./getCartData";

export type updateCartArgs = createCartArgs 
  & Partial<Omit<createCartArgs, "apiURL" | "authorizationToken">>
  & {
    id: string;
  }

export default async function updateCart({
  apiURL,
  authorizationToken,
  ...data
}: updateCartArgs): APIResponse<Cart> {
  const endPoint = "api/cart";
  const method = "PUT";
  
  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    data,
    endPoint,
    method,
  });
}