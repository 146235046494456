import CheckoutError from "./CheckoutError";
import PromoCode from "./PromoCode";
import Recaptcha from "./Recaptcha";

import StepComponent, { Step, StepComponentProps, StepSubmitHandler } from "../Step";
import Steps from "../Steps";

import AddOnsSummary from "../../components/AddOnsSummary";
import TicketConfirmation from "../../components/TicketConfirmation";
import { goToNextStep } from "..";
import { PostCORSCheckoutArgs } from "../../hooks/useCORSCheckout";
import { getVoucherCodeDistribution } from "../../hooks/useScheduleCartUpdate";

const onSubmit: StepSubmitHandler = async ({
  appConfiguration,
  cartData,
  helpers,
  meta,
  onCheckout,
  onStep,
  postCORSCheckout,
  redeemVoucher,
  ticketData,
  ticketDate,
  updateSold,
  values,
  voucherData,
}) => {
  let checkoutArgs: PostCORSCheckoutArgs = {
    address1: values.streetAddress1,
    address2: values.streetAddress2,
    billingEmail: values.email,
    email: values.email,
    city: values.city,
    contactFirstName: values.firstName,
    contactLastName: values.lastName,
    contactFullName: [values.firstName || "", values.lastName || ""].filter(v => v).join(" "),
    country: values.country,
    phoneNumber: values.phoneNumber,
    recaptchaToken: appConfiguration?.recaptcha_site_key_v3 && values.recaptchaV3Token,
    shoppingCartId: cartData?.id,
    state: values.state,
    zipCode: values.zip,
    uuid: values.uuid,
  };

  if(values.paymentRequired) {
    checkoutArgs = {
      ...checkoutArgs,
      billingAddress1: values.streetAddress1,
      billingAddress2: values.streetAddress2,
      billingCity: values.city,
      billingCountry: values.country,
      billingFirstName: values.firstName,
      billingLastName: values.lastName,
      billingPhoneNumber: values.phoneNumber,
      billingState: values.state,
      billingZipCode: values.zip,
      cvc: values.securityCode,
      expDate: values.expirationMonth + values.expirationYear.toString().substring(2),
      manualEntryCardNumber: values.cardNumber,
    }
  }

  const order = await postCORSCheckout(checkoutArgs);

  if(order && "id" in order && order.id) {
    try {
      console.log("Ticket sale quantity update");

      if(cartData?.items?.length) {
        for(const item of cartData.items) {
          const ticket = ticketData?.find(ticket => ticket.event_id === item.eventId);

          if(ticket) {
            try {
              await updateSold(ticket.id.toString(), item.quantity);
            } catch(error) {
              console.error("udpateSold", error);
            }
          }
        }
      }

      console.log("Combo sale quantity update");

      if(cartData?.comboItems?.length) {
        for(const comboItem of cartData.comboItems) {
          for(const comboTicket of comboItem.tickets) {
            const comboEvent = comboItem.events.find(comboEvent => comboEvent.eventTemplateId === comboTicket.eventTemplateId);
            const ticket = ticketData?.find(ticket => ticket.event_id === comboEvent?.eventId);
  
            if(ticket) {
              try {
                await updateSold(ticket.id.toString(), comboTicket.quantity);
              } catch(error) {
                console.error("udpateSold", error);
              }
            }
          }
        }
      }

      if(order?.id && values.voucherCodes.length) {
        const usedVoucherCodes = Object
          .values(getVoucherCodeDistribution({
            ticketData,
            values,
            voucherData
          }))
          .flat()
          .map(vouchers => vouchers.voucherCode);
          
        if(usedVoucherCodes.length) {
          console.log("Voucher redemption update");
          
          for(const usedVoucherCode of usedVoucherCodes) {
            try {
              await redeemVoucher({
                orderNumber: order.orderNumber,
                visitDate: ticketDate || new Date(),
                voucherCode: usedVoucherCode,
              });
            } catch(error) {
              console.error("redeemVoucher", error);
            }
          }
        }
      }
    } catch(error) {
      console.error("Error uploading order data", error);
    }

    if(onCheckout) {
      try {
        onCheckout(order, checkoutArgs);
      } catch (error) {
        console.error("onCheckout", error, order, checkoutArgs);
      }
    }

    await goToNextStep(values.stepNumber, values.stepsEnabled, helpers.setFieldValue, helpers.setFieldTouched, meta, onStep);
  } else {
    if(onCheckout) {
      try {
        onCheckout(null, checkoutArgs);
      } catch (error) {
        console.error("onCheckout", error, order, checkoutArgs);
      }
    }

    console.error("Missing order ID", order);
  }
};

const Component = (props: StepComponentProps) => {
  return (
    <StepComponent
      {...props}
      submitButtonLabel="Place Order"
    >
      <TicketConfirmation {...props} showTotal={false} />
      <AddOnsSummary {...props} showContent={false} showIfBlank={false} />
      <PromoCode {...props} />
      <Recaptcha {...props} />
      <CheckoutError {...props} />
    </StepComponent>
  )
};

const Review: Step = {
  Component,
  defaultLabel: "Review",
  isMembershipStep: true,
  isTicketStep: true,
  labelKey: "review",
  liveCart: true,
  onSubmit,
  stepNumber: Steps.Review,
};

export default Review;
