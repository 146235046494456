import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface AVSState {
  abbreviation: string;
  name: string;
  iso_3166: string;
  country: string;
}

export interface getAVSStateDataMainArgs {

}

export type getAVSStateDataArgs = getAuthorizedAPIDataSharedArgs & getAVSStateDataMainArgs;

export type AVSStateData = AVSState[];

export default async function getAVSStateData({
  apiURL,
  authorizationToken,
}: getAuthorizedAPIDataSharedArgs): APIResponse<AVSStateData> {
  const endPoint = "api/avs/states";

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    endPoint,
  });
}