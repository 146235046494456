import { Cart } from "../fetchers/getCartData";

export default function getCartTotal(data: Cart | null | undefined) {
  if(!data) {
    return {
      discountedTotal: null,
      hasDiscount: null,
      requiredTotal: null,
      total: null,
    };
  }

  let total = 0;
  let discountedTotal = 0;

  const parseValue = (value: string | number | null | undefined) => {
    switch(typeof(value)) {
      case "string": return parseFloat(value); break;
      case "number": return value; break;
      case "object":
      case "undefined":
      default:
        return 0;
        break;
    }
  }

  if(data.items?.length) {
    for(const item of data.items) {
      total += parseValue(item.amount);
      discountedTotal += parseValue(item.amount);
    }
  }

  if(data.comboItems?.length) {
    for(const comboItem of data.comboItems) {
      const usesDiscount = comboItem.comboTemplateType === "discount";
      const comboItemAddOns = comboItem.addOns;

      if(comboItemAddOns?.length) {
        for(const comboItemAddOn of comboItemAddOns) {
          if(comboItemAddOn.priceSplit === "each") {
            total += comboItemAddOn.quantity * parseValue(comboItemAddOn.unitPrice);
            discountedTotal += comboItemAddOn.quantity * parseValue(comboItemAddOn.unitPrice);
          } else {
            total += parseValue(comboItemAddOn.unitPrice);
            discountedTotal += parseValue(comboItemAddOn.unitPrice);
          }
        }
      }
      
      const comboItemTickets = comboItem.tickets;

      if(comboItemTickets.length) {
        for(const comboItemTicket of comboItemTickets) {
          total += parseValue(comboItemTicket.subtotal);
          discountedTotal += parseValue(usesDiscount ? comboItemTicket.discountedSubtotal : comboItemTicket.subtotal);
        }
      }
    }
  }

  return {
    discountedTotal,
    hasDiscount: total !== discountedTotal,
    requiredTotal: Math.min(discountedTotal, total),
    total,
  };
};