import Info from "./Info";
//import Target from "./Target";

import StepComponent, { Step, StepComponentProps } from "../Step";
import Steps from "../Steps";

const Component = (props: StepComponentProps) => {
  return (
    <StepComponent
      {...props}
      defaultHeaderLabel="Review Your Selection"
      headerLabelKey="membershipReviewHeader"
      showSteps={false}
      showMemberJoinButton={false}
    >
      {/*<Target {...props} />*/}
      <Info {...props} />
    </StepComponent>
  )
};

const MembershipReview: Step = {
  Component,
  defaultLabel: "Membership",
  excludeFromHeader: true,
  isMembershipStep: true,
  isTicketStep: false,
  labelKey: "membership",
  liveCart: true,
  stepNumber: Steps.MembershipReview,
};

export default MembershipReview;