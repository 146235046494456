import classNames from "classnames";

import { StepComponentProps } from "../Step";

import Input from "../../components/Input";
import LoaderContainer from "../../components/LoaderContainer";
import TextContent from "../../components/TextContent";
import ToggleContainer from "../../components/ToggleContainer";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getPriceFormatter from "../../formatters/getPriceFormatter";
import getLocalizedText from "../../formatters/getLocalizedText";

export default function PromoCode(props: StepComponentProps) {
  const { appConfiguration, cartData, cartDataError, isCartDataLoading, isCartDataValidating } = props;
  const { values } = useFormikSchemaContext();

  if(!appConfiguration?.allow_promo_codes) {
    return "";
  }

  const isChecking = !!(values.promoCode && (isCartDataLoading || isCartDataValidating));
  const couponCodeApplied = !!cartData?.couponCodes?.length;

  const discount = cartData?.items?.reduce((discount, item) => {
    const retailUnitPrice = typeof(item.retailUnitPrice) === "string"
      ? parseFloat(item.retailUnitPrice)
      : item.retailUnitPrice;

    const quantity = item.quantity;

    if(!retailUnitPrice || !quantity) {
      return discount;
    }

    const amount = (typeof(item.amount) === "string" ? parseFloat(item.amount) : item.amount) || 0;

    return discount + ((retailUnitPrice * quantity) - amount);
  }, 0);

  const format = getPriceFormatter(appConfiguration?.locale);

  return (
    <>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="promoCodeHeader"
        defaultValue="Promo Code"
        tag="h3"
      />
      <Input
        name="hasPromoCode"
        label={getLocalizedText(appConfiguration, "hasPromoCodeLabel", "I have a promo code")}
        type="checkbox"
        value="1"
      />
      <ToggleContainer visible={!!values.hasPromoCode.length}>
        <Input
          name="promoCode"
          label={getLocalizedText(appConfiguration, "promoCodeLabel", "Promo Code")}
        />
        <LoaderContainer
          appConfiguration={appConfiguration}
          error={cartDataError}
          isLoading={isChecking}
          isValidating={isChecking}
          loadingMessage={getLocalizedText(appConfiguration, "applyingCouponCodeMessage", "Applying coupon code...")}
        >
          {couponCodeApplied && <p className={classNames({
            "promoCode__appliedMessage": true,
            "promoCode__appliedMessage--hasDiscount": discount,
          })}>
            {discount
              ? (
                <>
                  <TextContent
                    appConfiguration={appConfiguration}
                    contentKey="discountAppliedMessage"
                    defaultValue="Discount Applied"
                    tag="span"
                  />
                  <span className="promoCode__appliedMessage__amount">{format(discount)}</span>
                </>
              )
              : (
                <TextContent
                  appConfiguration={appConfiguration}
                  contentKey="noDiscountMessage"
                  defaultValue="No discount applied for this promo code."
                  tag="span"
                />
              )
            }
          </p>}
        </LoaderContainer>
      </ToggleContainer>
    </>
  )
}