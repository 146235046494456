import classNames from "classnames";
import { Field, useField, FormikHandlers } from "formik";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";

import FieldWrapper from "./FieldWrapper";
import { Schema } from "../schema";

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  as?: string;
  deselectRadioOnClick?: boolean;
  label?: string;
  name: keyof Schema | string;
  onChange?: FormikHandlers['handleChange'];
  showError?: boolean;
  showLabel?: boolean;
}

export default function Input({
  as = "input",
  className,
  deselectRadioOnClick = false,
  id,
  label,
  name,
  onChange,
  placeholder,
  showLabel = true,
  showError = true,
  type,
  ...fieldProps
}: InputProps) {
  const isCheck = type === "checkbox";
  const isRadio = type === "radio";
  const isCheckOrRadio = isCheck || isRadio;
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fieldInputProps, _fieldMetaProps, fieldHelpers] = useField(name);
  
  if(showLabel && label && !id) {
    id = name;
    
    if(isCheckOrRadio) {
      id += "-" + (fieldProps.value?.toString().replace(/[^A-Za-z0-9-]+/g, "") || "check");
    }
  }

  return (
    <FieldWrapper
      id={id}
      label={label}
      name={name}
      showError={showError}
      showLabel={showLabel}
      type={["input", type].filter((t) => !!t).join("--")}
    >
      <Field
        as={as}
        className={classNames({
          "field__input": true,
          [`field__input--${type}`]: type,
          [className + '']: className,
        })}
        id={id}
        name={name}
        onClick={(isRadio && deselectRadioOnClick)
          ? () => {
            if(fieldInputProps.value === fieldProps.value) {
              fieldHelpers.setValue("");
            }
          }
          : undefined
        }
        onChange={onChange
          ? (e: string | React.ChangeEvent<unknown>) => {
            onChange(e);
            fieldInputProps.onChange(e);
          }
          : fieldInputProps.onChange
        }
        placeholder={isCheckOrRadio ? undefined : (placeholder ?? label)}
        type={type}
        {...fieldProps}
      />
    </FieldWrapper>
  )
}