import { StepComponentProps } from "../Step";

import Errors from "../../components/Errors";
import TextContent from "../../components/TextContent";

export default function CheckoutError({
  appConfiguration,
  checkoutError
}: StepComponentProps) {
  if(!checkoutError) {
    return "";
  }

  return (
    <div className="checkoutError">
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="checkoutErrorMessage"
        defaultValue="There was a problem placing the order. Please check your billing information and try again."
        tag="p"
      />
      <Errors
        fields={[
          "recaptchaV2Token",
          "recaptchaV2Valid",
        ]}
      />
    </div>
  );
}