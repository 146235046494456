import { getStep } from "..";
import TextContent from "../../components/TextContent";

import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import { StepComponentProps } from "../Step";

export default function Title({
  appConfiguration,
  defaultHeaderLabel,
  headerLabelKey,
}: StepComponentProps) {
  const { values } = useFormikSchemaContext();
  const { stepNumber } = values;
  const step = getStep(stepNumber);

  const titleKey = headerLabelKey || step.labelKey;
  const defaultTitle = defaultHeaderLabel || step.defaultLabel;

  return (
    <TextContent
      appConfiguration={appConfiguration}
      className="headerTitle"
      defaultValue={defaultTitle}
      contentKey={titleKey}
      tag="h2"
    />
  );
}