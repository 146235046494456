export type PriceFormatter = (price: string | number | null | undefined) => string;

export default function getPriceFormatter(locale: string | null | undefined, replaceZero: boolean = false) {
  const formatter = new Intl.NumberFormat(locale || "en-US", {
    currency: "USD",
    style: "currency",
  });

  const format: PriceFormatter = (price: string | number | null | undefined) => {
    if(!price) {
      if(replaceZero) {
        return "FREE";
      } else {
        price = 0;
      }
    }

    if(typeof(price) === "string") {
      price = parseFloat(price);
    }

    return formatter.format(price);
  };

  return format;
}